import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { CustomTable, DeleteModal } from "../../components";
import {
  getAllItems,
  deleteItem,
  getItemTypes,
  createItem,
  editItem,
  exportShopItemsCsv
} from "../../store/actions";
import ButtonControls from "../layout/buttonControls";
import { ItemForm, ItemEdit } from "./shopItems";
import { toast } from "react-toastify";
import "./shop.scss";
import history from '../../history';
import BuyTicketForm from "./buyTicketForm";

class Shop extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: "ITEM NAME",
          render: data => {
            return <span
              className="view-shop"
              onClick={() => history.push(`/admin/shop/${data.id}`)}
            >{_.get(data, "title", "-")}
            </span>
          }
        },
        {
          title: "ITEM TYPE",
          render: data => {
            const type = _.find(this.props.itemTypes, { id: data.itemTypeId });
            return <div>{type ? type.itemType : "-"}</div>;
          }
        },
        {
          title: "STATUS",
          render: data => {
            return <div>{_.get(data, "accessType", "-")}</div>;
          }
        },
        {
          title: "OPTIONS",
          render: data => {
            return (
              <div className="options-row">
                <ItemEdit
                  item={data}
                  getAll={{ page: this.state.page, type: this.state.type }}
                  {...this.props}
                />
                <DeleteModal
                  trigger={<div className="unpublish">Delete Item</div>}
                  item={data}
                  {...this.props}
                  type="shopEvent"
                />
                <div
                  className="share"
                  onClick={() => {
                    this.copyToClipBoard(data.id);
                  }}
                >
                  Copy Share Link
                </div>
              </div>
            );
          }
        }
      ],
      page: 1,
      type: 0,
      modal: false,
      ticketModal: false
    };
  }

  async componentDidMount() {
    try {
      window.scrollTo(0, 0);
      const { type } = this.state;
      await this.props.getItemTypes();
      this.init(type);
    } catch (error) {
      console.log("TCL: Shop -> componentDidMount -> error", error);
    }
  }

  copyToClipBoard(id) {
    navigator.clipboard.writeText(
      `${window.location.origin}/events/payment/${id}`
    );
    toast.info("Link copied to clipboard");
  }

  async init(type) {
    try {
      const { getAllItems } = this.props;
      await getAllItems({ type });
    } catch (error) {
      console.log(">: Shop -> init -> error", error);
    }
  }

  async handlePaginationChange(page) {
    try {
      const { getAllItems } = this.props;
      await getAllItems({ page });
      this.setState({
        page
      });
    } catch (error) {
      console.log(">:shop-> handlePaginationChange -> error", error);
    }
  }

  dropDownChange(event, data) {
    this.setState({
      type: data.value
    });
    this.init(data.value);
  }

  toggle(type, open) {
    this.setState({
      [type]: open
    });
  }

  // async onExportButtonClick() {
  //   try {
  //     const { exportShopItemsCsv, allItems } = this.props;
  //     if (!_.isEmpty(allItems)) {
  //       await exportShopItemsCsv({ date: moment().format("YYYY-MM-DD") });
  //       window.open(this.props.shopExportCsv.Location);
  //     } else {
  //       toast.error("No items for export");
  //     }
  //   } catch (error) {
  //     console.log("TCL: Shop -> onExportButtonClick -> error", error);
  //   }
  // }

  render() {
    const {
      itemTypes,
      getAllItemsLoading,
      allItems,
      shopExportCsvLoading
    } = this.props;

    const itemTypesList = [{ text: "All Items", value: 0 }];
    _.forEach(itemTypes, item => {
      itemTypesList.push({ text: item.itemType, value: item.id });
    });

    return (
      <ButtonControls
        loading={getAllItemsLoading}
        controlObj={{
          btnArr: [
            // {
            //   icon: "file text",
            //   name: "EXPORT CSV",
            //   className: "export-button",
            //   onClick: () => this.onExportButtonClick(),
            //   loading: shopExportCsvLoading
            // },
            {
              name: "BUY TICKET",
              className: "first-button",
              onOpen: () => this.toggle("ticketModal", true),
              open: this.state.ticketModal,
              size: "small",
              modalContent: (
                <BuyTicketForm
                  {...this.props}
                  trigger={open => this.toggle("ticketModal", open)}
                />
              )
            },
            {
              icon: "add circle",
              name: "ADD NEW ITEM",
              className: "second-button",
              onOpen: () => this.toggle("modal", true),
              onClose: () => this.toggle("modal", false),
              open: this.state.modal,
              modalContent: (
                <ItemForm
                  isNew={true}
                  toggle={open => this.toggle("modal", open)}
                  getAll={{ page: this.state.page, type: this.state.type }}
                  {...this.props}
                />
              )
            }
          ],
          filter: [
            {
              defaultValue: 0,
              items: itemTypesList,
              dropDownChange: (event, data) => this.dropDownChange(event, data)
            }
          ]
        }}
      >
        <CustomTable
          header
          columns={this.state.columns}
          data={allItems}
          pagination
          noOfPages={this.props.pages}
          page={this.state.page}
          handlePaginationChange={activePage =>
            this.handlePaginationChange(activePage)
          }
        />
      </ButtonControls>
    );
  }
}

const mapStateToProps = state => {
  const { items } = state;
  return {
    ...items
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllItems,
      deleteItem,
      getItemTypes,
      createItem,
      editItem,
      exportShopItemsCsv
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
