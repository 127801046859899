import React from "react";
import "./members.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getMember, updateMember } from "../../../store/actions";
import ButtonControl from "../../layout/buttonControls";
import history from "../../../history";
import { Table } from "semantic-ui-react";
import "./members.scss";
import moment from "moment";
import { MemberEdit } from "./memberForm";
import MembershipPayment from './membershipPayment';
import _ from 'lodash';
import MemberAttendance  from './memberAttendance';

class MemberProfile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      memberId: this.props.match.params.memberId,
    };
  }

  async componentDidMount() {
    try {
      window.scrollTo(0,0);
      const { getMember } = this.props;
      const { memberId } = this.state;
      await getMember(memberId);
    } catch (error) {
      console.log("TCL: MemberProfile -> componentDidMount -> error", error);
    }
  }

  goBack() {
    history.push("/admin/manageMembers");
    
  }

  render() {
    const { getMemberLoading, member } = this.props;
    return (
      <ButtonControl
        backArrow={"Back to All Members"}
        goBack={() => this.goBack()}
        loading={getMemberLoading}
      >
        <Table
          basic="very"
          stackable
          structured={false}
          padded
          textAlign="left"
          className="main-table"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="5">MEMBER DETAILS</Table.HeaderCell>
              <Table.HeaderCell textAlign="right" className="profile-edit-member">
                <MemberEdit
                  text="Edit Details"
                  type="edit"
                  editUser={member}
                  {...this.props}
                  admin
                  page={this.state.page}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <div className="div-title">NAME</div>
                <div className="profile-div-content">
                  {(member.firstName ? member.firstName : null) + ' ' + (member.lastName ? member.lastName : null)}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="div-title">DATE OF BIRTH</div>
                <div className="profile-div-content">
                  {member.DOB ? moment(member.DOB).format("DD MMMM YYYY") : '-'}
                </div>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <div className="div-title">SPOUSE/PARTNER NAME</div>
                <div className="profile-div-content">
                  {member.spouse ? member.spouse : '-'}
                </div>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <div className="div-title">STREET NUMBER/NAME</div>
                <div className="profile-div-content">
                  {member.streetName ? member.streetName : '-'}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="div-title">SUBURB</div>
                <div className="profile-div-content">
                  {member.suburb ? member.suburb : '-'}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="div-title">STATE</div>
                <div className="profile-div-content">
                  {member.state ? member.state : '-'}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="div-title">POSTCODE</div>
                <div className="profile-div-content">
                  {member.postCode ? member.postCode : '-'}
                </div>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <div className="div-title">EMAIL</div>
                <div className="profile-div-content">
                  {member.email ? member.email : '-'}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="div-title">PHONE NUMBER</div>
                <div className="profile-div-content">
                  {member.phoneNumber ? member.phoneNumber : '-'}
                </div>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <div className="div-title">MEMBERSHIP TYPE</div>
                <div className="profile-div-content">
                  {!_.isEmpty(member.MembershipType) ? member.MembershipType.title : '-'}
                </div>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <div className="div-title">COMMENT</div>
                <div className="profile-div-content">
                  {member.comment ? member.comment : '-'}
                </div>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <MembershipPayment {...this.props} profile />
        <MemberAttendance {...this.props} profile/>
      </ButtonControl>
    );
  }
}
const mapStateToProps = state => {
  const { members } = state;
  return {
    ...members
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMember,
      updateMember
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberProfile);
