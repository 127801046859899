import React from "react";
import { Table, Pagination,Image} from "semantic-ui-react";
import _ from "lodash";
import "./customTable.scss";

export class CustomTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      header,
      columns,
      data,
      customClass,
      pagination,
      page,
      handlePaginationChange,
      noOfPages
    } = this.props;

    return (
      <Table
        basic="very"
        stackable
        structured={false}
        padded
        className={customClass ? customClass : "main-table"}
        textAlign="left"
      >
        {header ? (
          <Table.Header fullWidth>
            <Table.Row>
              {_.map(columns, (column, i) => (
                <Table.HeaderCell
                  key={i}
                  textAlign={column.textAlign ? column.textAlign : "left"}
                  onClick={column.onClick}
                >
                  <b className={column.className ? column.className : null}>
                    {column.title ? column.title : ""}
                  </b>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
        ) : null}
        <Table.Body>
          {//set data here
          _.isEmpty(data) ? (
            <Table.Row>
              <Table.Cell colSpan={columns.length}>
                <div className="no_data">
                  <Image
                    size = "tiny"
                    src={require("../../assets/empty-folder.png")}
                  ></Image>
                </div>
                <div className="no_data"><p>There is no data</p></div>
              </Table.Cell>
            </Table.Row>
          ) : (
            _.map(data, (item, i) => {
              if (_.isEmpty(item)) return null;
              return (
                <Table.Row key={i}>
                  {_.map(columns, (column, c) => (
                    <Table.Cell
                      key={c}
                      verticalAlign={
                        column.verticalAlign ? column.verticalAlign : "top"
                      }
                      textAlign={column.textAlign ? column.textAlign : "left"}
                      width={column.width ? column.width : null}
                    >
                      {column.render(item)}
                    </Table.Cell>
                  ))}
                </Table.Row>
              );
            })
          )}
        </Table.Body>
        {pagination ? (
          <Table.Footer>
            <Table.Row textAlign="center">
              <Table.HeaderCell colSpan={columns ? columns.length : 1}>
                <Pagination
                  size="tiny"
                  activePage={page ? page : 1}
                  onPageChange={(e, { activePage }) =>
                    handlePaginationChange
                      ? handlePaginationChange(activePage)
                      : false
                  }
                  totalPages={noOfPages ? noOfPages : 1}
                  pointing
                  secondary
                  firstItem={null}
                  lastItem={null}
                  siblingRange={3}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        ) : null}
      </Table>
    );
  }
}

