// export const
export let DROP_IN_AUTH_KEY = '';
export let BASE_URL = process.env.REACT_APP_BASE_URL || 'https://rcobawa-api.betalaunch.io/api';
export let REACT_APP_HIDE_LANDING_REDIRECT = process.env.REACT_APP_HIDE_LANDING_REDIRECT || null;
export let REACT_APP_ANDROID_URL = process.env.REACT_APP_ANDROID_URL || 'https://rcobawa-stage-bucket.s3.ap-southeast-2.amazonaws.com/rcobawa-7aab9d4b585940c58fc87a09a582b2ee-signed.apk';
export const INFO_EMAIL = process.env.REACT_APP_INFO_EMAIL || 'rcobawa@gmail.com';

// Google Analytics
export const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID || 'G-TQH0CQYPMV'

export const AWSConfig = {
  bucketName: process.env.REACT_APP_AWS_S3_BUCKET_PREFIX || 'rcobaa-app-images',
  dirName: 'album',
  region: process.env.REACT_APP_AWS_region ||'ap-southeast-2',
  accessKeyId:  process.env.REACT_APP_AWS_accessKeyId || 'AKIA2CXPYU5TS6SQUA4K',
  secretAccessKey:  process.env.REACT_APP_AWS_secretAccessKey || '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'
};

if (process.env.REACT_APP_ENV_VALUE === 'production') {
  BASE_URL = 'https://api.rcobaa.org.au/api';
  DROP_IN_AUTH_KEY = 'production_6mppsjb6_hrvcbsvbychdn9t6';
} else if (process.env.REACT_APP_ENV_VALUE === 'prod-rcobawa') {
  DROP_IN_AUTH_KEY = 'production_6mppsjb6_hrvcbsvbychdn9t6';
} else if (process.env.REACT_APP_ENV_VALUE === 'stage-rcobawa') {
  DROP_IN_AUTH_KEY = 'sandbox_w3y2xskh_cy3snqhfwrhcp5fn';
} else {
  DROP_IN_AUTH_KEY = 'sandbox_w3y2xskh_cy3snqhfwrhcp5fn';
  REACT_APP_HIDE_LANDING_REDIRECT = 'http://localhost:3000'
}

// api integration
export const LIMIT = 25;
export const OFFSET = 0;

export const DELETED_MSG = 'Deleted Successfully... ';
export const CREATED_MSG = 'Created Successfully... ';
export const UPDATED_MSG = 'Updated Successfully... ';
export const ERROR_MSG = 'Oops, Something went wrong!';

export const REQUEST_SENT_MSG = 'Password Reset Request Sent Successfully... ';
export const UPLOADED_CSV = 'Uploaded CSV Successfully...';
export const MEMBERSHIP_DESC =
  'Membership in the Association enables you to help your alma mater to the extent that the Association from time to time makes contributions to various projects to assist the school. In addition, the Association organizes a number of social events during the year that foster a strong fellowship amongst old Royalists living in Australia. Your membership also entitles you to the association’s quarterly newsletter Floreat, which can either be emailed or posted to you.';



export const ITEM_TYPES = [
  { text: 'All Items', value: 0 },
  { text: 'Event', value: 1 },
  { text: 'Donation', value: 2 },
  { text: 'Merchandise', value: 3 }
];
