import React from "react";
import { Footer } from "../../components";
import { Tab } from "semantic-ui-react";
import News from "./news";
import Gallery from "./gallery";
import Magazines from "./magazines";
import Header from "../homePage/header";
import history from "../../history";

class Media extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.match.params.mediaType
    };
  }

  onTabChange(event, data) {
    history.push(`/media/${data.activeIndex}`);
    this.setState({
      activeIndex: data.activeIndex
    });
  }

  render() {
    const { activeIndex } = this.state;
    return (
      <div>
        <Header path="media" />
        <Tab
          activeIndex={activeIndex}
          id="media"
          className="media-tabs"
          menu={{ secondary: true, pointing: true }}
          onTabChange={(event, data) => this.onTabChange(event, data)}
          panes={[
            {
              menuItem: "NEWS",
              render: () => (
                <Tab.Pane>
                  <News />
                </Tab.Pane>
              )
            },
            {
              menuItem: "GALLERY",
              render: () => (
                <Tab.Pane>
                  <Gallery />
                </Tab.Pane>
              )
            },
            {
              menuItem: "MAGAZINES",
              render: () => (
                <Tab.Pane>
                  <Magazines />
                </Tab.Pane>
              )
            }
          ]}
        />
        <Footer />
      </div>
    );
  }
}
export default Media;
