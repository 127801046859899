import React from 'react';
import { BackgroundPage,Footer } from '../../components';
import  { MemberForm } from '../manageMembers/members/memberForm';
import {
  getMembershipTypes,
  register
} from '../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Register extends React.PureComponent {
  render() {
    return (
      <>
      <BackgroundPage
        header={'JOIN RCOBAWA'}
        desc={
          'Please fill up the membership form below. Once the details are verified, we will send you an email with a temporary login.'
        }
      >
        <MemberForm {...this.props}/>
       
      </BackgroundPage>
       <Footer/>
       </>
    );
  }
}
const mapStateToProps = state => {
  const { members, auth } = state;
  return {
   
    ...members,
    ...auth
   
   
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMembershipTypes,
      register
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)( Register);

