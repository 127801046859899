import React from 'react';
import { BackgroundPage } from './backgroundPage';
import { Footer } from '../homePage/footer';
import { isMobile } from 'react-device-detect';

export const TermsAndConditions = props => {
  return (
    <>
      <BackgroundPage
        header={'TERMS & CONDITIONS'}
        desc={
          <div style={{textAlign: 'justify', margin: isMobile ? '20px' : '50px'}}>
            <p>
              PERMISSION TO USE WWW.RCOBAWA.ORG.AU ("WEB SITE") IS CONDITIONAL
              UPON YOU AGREEING TO THE TERMS OF USE SET OUT BELOW ("TERMS OF
              USE"). YOUR USE OF THE WEB SITE IS DEEMED TO BE YOUR ACCEPTANCE OF
              THESE TERMS OF USE. IF YOU DO NOT ACCEPT THESE TERMS OF USE, YOU
              MUST REFRAIN FROM USING THE WEB SITE.
            </p>
            <h1>Terminology </h1>
            <p>
              In these Terms of Use, references to “RCOBAWA’ ,'we', 'us' and
              'our' mean the ROYAL COLLEGE OLD BOYS IN AUSTRALIA ASSOCIATION.
              ‘Article providers’ means authors, publishers, presenters,
              photographers and videographers who send materials to
              WWW.RCOBAWA.ORG.AU
            </p>
            <h1>Amendments to Terms of Use</h1>
            <p>
              We reserve the right to amend these Terms of Use from time to
              time. Amendments to these Terms of Use will be effective
              immediately upon notification on this Web Site. Your continued use
              of this Web Site following such notification will represent your
              agreement to be bound by these Terms of Use as amended.
            </p>
            <h1>Disclaimer</h1>
            <p>
              We do not accept responsibility for any action, claim, demand,
              loss (including consequential loss) and/or cost (including legal
              cost) however caused (including through negligence and/or wilful
              misconduct) which you may directly and/or indirectly suffer and/or
              incur in connection with your use of and/or reliance on the
              contents of the Website.
            </p>
            <h1>Specific warnings</h1>
            <p>
              Whilst we have no reason to believe that any information contained
              on the Web Site is inaccurate, we do not warrant the accuracy,
              adequacy and/or completeness of that information,{' '}
              <b>nor do we undertake to keep the Web Site updated.</b>
            </p>
            <p>
              Intellectual property rights in the Web Site are owned and/or
              licensed by us. Governing law
            </p>
            <p>
              These Terms of Use will be governed by and construed according to
              the laws of the State of Victoria Australia and the Commonwealth
              of Australia. You irrevocably submit to the exclusive jurisdiction
              of the courts of the State of Victoria Australia and the
              Commonwealth of Australia.
            </p>
            <h1>General</h1>
            <p>
              We accept no liability for any failure to comply with these Terms
              of Use where that failure is due to circumstances beyond our
              control. If we waive any rights available to us under these Terms
              of Use on one occasion, this does not mean that those rights will
              automatically be waived on any other occasion. If any of these
              Terms of Use are held to be invalid, unenforceable and/or illegal
              for any reason, the remaining Terms of Use will nevertheless
              continue in full force.
            </p>
            <h1>Privacy collection statement</h1>
            <p>
              We collect this information for the purpose of:
              <ol>
                <li>registering you as a member of RCOBAWA;</li>
                <li>
                  keeping YOU INFORMED ABOUT FORTHCOMING ACTIVITIES AND EVENTS.
                </li>
                <li>
                  inform us if you do not wish us to use or store your personal
                  information;
                </li>
                <li>
                  analysing our services and MEMBERSHIP needs with a view to
                  developing new and/or improved services;
                </li>
              </ol>
              If the personal information you provide to us is incomplete and/or
              inaccurate, we may be unable to process your registration and/or
              be unable to provide you with the services you are seeking.
            </p>
            <h1>Payment processing</h1>
            <p>
              We do not receive or store any credit card information. All credit
              card information is processed securely by the Merchant Facility.
            </p>
            <p>
              <i>“Electronic Transaction”</i> means an electronic transaction conducted
              through the Majestri Platform, including a payment made through
              any of the following methods:
              <ul>
                <li>Credit Card;</li>
                <li>Debit Card;</li>
                <li>Prepaid payment card; or </li>
                <li>BPay payment.</li>
              </ul>
            </p>
          </div>
        }
      />
      <Footer />
    </>
  );
};
