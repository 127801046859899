import React from 'react';
import './members.scss';
import { Grid } from 'semantic-ui-react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addMemberComment } from '../../../store/actions';

class Comment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      comment: null
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleValidSubmit(event, values) {
    const { addMemberComment, userId, modal } = this.props;
    values.userId = userId;
    addMemberComment(values);
    modal(false);
  }
  render() {
    const { comment } = this.state;
    const { addCommentLoading } = this.props;

    return (
      <Grid className="comment-grid">
        <Grid.Row className="modal-header-div">Add Member Comment</Grid.Row>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          model={comment}
          className="register-form"
        >
          <AvField name="comment" type="textarea" label="Comment" rows="6" />
          <Button loading={addCommentLoading}>SAVE COMMENT</Button>
        </AvForm>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  const { members } = state;
  return {
    addCommentLoading: members.addCommentLoading
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ addMemberComment }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Comment);
