import React from 'react';
import { CustomTable, DeleteModal, UploadCSV } from '../../../components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAllAttendees,
  deleteAttendee,
  exportAttendeeCsv,
  uploadAttendeeCsv
} from '../../../store/actions';
import ButtonControls from '../../layout/buttonControls';
import history from '../../../history';
import { AddAttendeeForm, AddAttendeeEdit } from './addAttendeeForm';
import { Checkbox } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import _ from 'lodash';

class ManageAttendance extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: (
            <Checkbox
              onChange={(event, data) =>
                this.checkboxChange(event, data, 'Name')
              }
              label="ATTENDEE NAME"
            />
          ),
          render: data => {
            return (
              <div>
                {!_.isEmpty(data.User)
                  ? data.User.firstName + ' ' + data.User.lastName
                  : (data.guestFirstName ? data.guestFirstName : '-') +
                    ' ' +
                    (data.guestLastName ? data.guestLastName : '-')}
              </div>
            );
          }
        },
        {
          title: (
            <Checkbox
              onChange={(event, data) =>
                this.checkboxChange(event, data, 'Email')
              }
              label="EMAIL ADDRESS"
            />
          ),
          render: data => {
            return (
              <div>
                {!_.isEmpty(data.User)
                  ? data.User.Email
                    ? data.User.Email
                    : '-'
                  : data.guestEmail
                  ? data.guestEmail
                  : '-'}
              </div>
            );
          }
        },
        {
          title: (
            <Checkbox
              onChange={(event, data) =>
                this.checkboxChange(event, data, 'Mobile')
              }
              label="MOBILE NUMBER"
            />
          ),
          render: data => {
            return (
              <div>
                {!_.isEmpty(data.User)
                  ? data.User.phoneNumber
                    ? data.User.phoneNumber
                    : '-'
                  : data.guestMobileNumber
                  ? data.guestMobileNumber
                  : '-'}
              </div>
            );
          }
        },
        {
          title: (
            <Checkbox
              onChange={(event, data) =>
                this.checkboxChange(event, data, 'Ticket')
              }
              label="TICKET ID"
            />
          ),
          render: data => {
            return <div>{data.ticketNo ? data.ticketNo : '-'}</div>;
          }
        },
        {
          title: (
            <Checkbox
              onChange={(event, data) =>
                this.checkboxChange(event, data, 'Status')
              }
              label="MEMBER STATUS "
            />
          ),
          render: data => {
            return <div>{data.User ? 'Member' : 'Not a Member'}</div>;
          }
        },
        {
          title: '   ',
          render: data => {
            return (
              <div className="options-row">
                <AddAttendeeEdit item={data} {...this.props} />
                <div className="add-delete">
                  <DeleteModal
                    trigger={<div className="delete">Remove</div>}
                    item={data}
                    {...this.props}
                    type="deleteAttendee"
                  />
                </div>
              </div>
            );
          }
        }
      ],
      data: [],
      memberPayments: {},
      openAddModal: false,
      allAttendees: [],
      exportEnums: [],
      page: 1
    };
  }

  async componentDidMount() {
    try {
      window.scrollTo(0, 0);
      const { getAllAttendees, match } = this.props;
      const { page } = this.state;
      await getAllAttendees({ itemId: match.params.eventId, offset: page });
      this.setState({
        allAttendees: this.props.allAttendees,
        getAllAttendeesLoading: this.props.getAllAttendeesLoading
      });
    } catch (error) {
      console.log('TCL: ManageAttendance -> componentDidMount -> error', error);
    }
  }

  goBack() {
    history.push('/admin/events');
  }

  onOpen(type, open) {
    this.setState({
      [type]: open
    });
  }

  async checkboxChange(event, data, type) {
    try {
      let { exportEnums } = this.state;
      if (data.checked) {
        exportEnums.push(type);
      } else {
        const resultEnums = exportEnums.filter(data => data !== type);
        await this.setState({
          exportEnums: resultEnums
        });
      }
    } catch (error) {
      console.log('TCL: ManageAttendance -> checkboxChange -> error', error);
    }
  }

  async onExportButtonClick() {
    try {
      const { exportEnums } = this.state;
      if (!_.isEmpty(exportEnums)) {
        await this.props.exportAttendeeCsv({
          itemId: this.props.match.params.eventId,
          attributes: exportEnums
        });
        window.open(this.props.exportedFile);
      } else {
        toast.warn('Please select a column to export!');
      }
    } catch (error) {
      console.log(
        'TCL: ManageAttendance -> onExportButtonClick -> error',
        error
      );
    }
  }

  handlePaginationChange(page) {
    const { getAllAttendees, match } = this.props;
    getAllAttendees({ itemId: match.params.eventId, offset: page });
    this.setState({
      allAttendees: this.props.allAttendees,
      getAllAttendeesLoading: this.props.getAllAttendeesLoading,
      page
    });
  }

  render() {
    const { page } = this.state;
    const { attendancePages } = this.props;
    return (
      <div>
        <ButtonControls
          backArrow={'Back to All Events'}
          goBack={() => this.goBack()}
          loading={this.props.getAllAttendeesLoading}
          controlObj={{
            btnArr: [
              {
                icon: 'file text',
                name: 'EXPORT CSV',
                className: 'export-button',
                onClick: () => this.onExportButtonClick()
              },
              {
                icon: 'upload',
                name: 'UPLOAD CSV',
                className: 'first-button',
                size: 'small',
                onOpen: () => this.onOpen('openUploadModal', true),
                onClose: () => this.onOpen('openUploadModal', false),
                open: this.state.openUploadModal,
                modalContent: (
                  <UploadCSV
                    trigger={open => this.onOpen('openUploadModal', open)}
                    {...this.props}
                  />
                )
              },
              {
                icon: 'add circle',
                name: 'ADD ATTENDEE',
                className: 'second-button',
                onOpen: () => this.onOpen('openAddModal', true),
                onClose: () => this.onOpen('openAddModal', false),
                open: this.state.openAddModal,
                size: 'tiny',
                modalContent: (
                  <AddAttendeeForm
                    trigger={open => this.onOpen('openAddModal', open)}
                    {...this.props}
                  />
                )
              }
            ]
          }}
        >
          <CustomTable
            header
            columns={this.state.columns}
            data={this.props.allAttendees}
            pagination
            handlePaginationChange={activePage =>
              this.handlePaginationChange(activePage)
            }
            noOfPages={attendancePages}
            page={page}
          />
        </ButtonControls>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { attendance } = state;
  return {
    ...attendance
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllAttendees,
      deleteAttendee,
      exportAttendeeCsv,
      uploadAttendeeCsv
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAttendance);
