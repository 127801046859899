import React from 'react';
import './members.scss';
import { CustomTable } from '../../../components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMemberPayment, paymentReceipt } from '../../../store/actions';
import ButtonControls from '../../layout/buttonControls';
import history from '../../../history';
import moment from 'moment';
import _ from 'lodash';
import { Segment } from 'semantic-ui-react';

class MembershipPayment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'PAYMENT TYPE',
          render: data => {
            return (
              <div>
                {!_.isEmpty(data.MembershipPayments) ? (
                  data.MembershipPayments[0].MembershipType.title
                ) : !_.isEmpty(data.ItemPayments) ? (
                  !_.isEmpty(data.ItemPayments[0].Item) ? (
                    data.ItemPayments[0].Item.title ? (
                      <div>
                        {data.ItemPayments[0].Item.title.toUpperCase()}{' '}
                        <i className="tag">(event)</i>
                      </div>
                    ) : (
                      '-'
                    )
                  ) : (
                    '-'
                  )
                ) : (
                  '-'
                )}
              </div>
            );
          }
        },
        {
          title: 'PAYMENT MODE',
          render: data => {
            return <div>{data.paymentMode ? data.paymentMode : '-'}</div>;
          }
        },
        {
          title: 'PAYMENT DATE',
          render: data => {
            return (
              <div>
                {data.createdAt
                  ? moment(data.createdAt).format('DD MMMM YYYY')
                  : '-'}
              </div>
            );
          }
        },
        {
          title: 'REFERENCE # ',
          render: data => {
            return <div>{data.reference ? data.reference : '-'}</div>;
          }
        },
        props.profile
          ? {
              title: (
                <div>
                  <span>View All Payments</span>
                </div>
              ),
              onClick: () => {
                history.push(
                  `/admin/manageMembers/payment/${props.match.params.memberId}`
                );
              },
              className: 'profile-edit-member',
              render: () => {
                return <div> </div>;
              },
              textAlign: 'right'
            }
          : {
              title: 'OPTIONS',
              render: data => {
                return (
                  <div className="options-row">
                    <div
                      className="view"
                      onClick={() => this.sendReceipt(data)}
                    >
                      Email Payment Receipt
                    </div>
                  </div>
                );
              }
            }
      ],
      data: [],
      memberPayments: {},
      page: 1
    };
  }

  async componentDidMount() {
    try {
      window.scrollTo(0, 0);
      const { getMemberPayment, match, profile } = this.props;
      let reqObj = {};
      if (profile) {
        reqObj.userId = match.params.memberId;
        reqObj.limit = 5;
      } else {
        reqObj.userId = match.params.userId;
      }
      await getMemberPayment(reqObj);
      this.setState({
        memberPayments: this.props.memberPayments
      });
    } catch (error) {
      console.log(
        'TCL: MembershipPayment -> componentDidMount -> error',
        error
      );
    }
  }

  async handlePaginationChange(activePage) {
    try {
      const { getMemberPayment, match, profile } = this.props;
      let reqObj = { offset: activePage };
      if (profile) {
        reqObj.userId = match.params.memberId;
        reqObj.limit = 5;
      } else {
        reqObj.userId = match.params.userId;
      }
      await getMemberPayment(reqObj);
      this.setState({
        memberPayments: this.props.memberPayments,
        page: activePage
      });
    } catch (error) {
      console.log('>:Members-> handlePaginationChange -> error', error);
    }
  }

  sendReceipt(data) {
    try {
      const { paymentReceipt, match, profile } = this.props;
      let userId;
      if (profile) {
        userId = match.params.memberId;
      } else {
        userId = match.params.userId;
      }
      paymentReceipt({
        userId: userId,
        paymentId: !_.isEmpty(data.ItemPayments)
          ? data.ItemPayments[0].id
          : data.MembershipPayments[0].id
      });
    } catch (error) {
      console.log('TCL: MembershipPayment -> sendReceipt -> error', error);
    }
  }

  goBack() {
    history.push('/admin/manageMembers');
  }
  onOpen(type, open) {
    this.setState({
      [type]: open
    });
  }

  render() {
    const {
      profile,
      paymentPages,
      getMemberPaymetLoading,
      getMemberLoading
    } = this.props;
    const { memberPayments } = this.state;
    return (
      <Segment loading={getMemberLoading ? false : getMemberPaymetLoading}>
        {profile ? (
          <CustomTable
            header
            columns={this.state.columns}
            data={memberPayments}
            pagination
            noOfPages={paymentPages}
            page={this.state.page}
            handlePaginationChange={activePage =>
              this.handlePaginationChange(activePage)
            }
          />
        ) : (
          <ButtonControls
            backArrow={'Back to Member Profile'}
            goBack={() => this.goBack()}
          >
            <CustomTable
              header
              columns={this.state.columns}
              data={memberPayments}
              pagination
              noOfPages={paymentPages}
              page={this.state.page}
              handlePaginationChange={activePage =>
                this.handlePaginationChange(activePage)
              }
            />
          </ButtonControls>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  const { members } = state;
  return {
    ...members
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMemberPayment,
      paymentReceipt
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MembershipPayment);
