import React from 'react';
import './backgroundPage.scss';
import '../../components/homePage/header.scss';
import { Grid, Segment, Image, Header as SemanticHeader } from 'semantic-ui-react';
import history from '../../history';
import  Header  from '../../containers/homePage/header';

export const BackgroundPage = props => {
  const { header, desc, children } = props;
  return (
    <Grid className="register-content">
      <Header backgroundPage/>
      <Segment vertical textAlign={"center"} className="register-segment">
        <div className="content-logo">
          <Image
            size={"large"}
            src={require("../../assets/rcobawaLogo.png")}
            onClick={() => history.push("/")}
          />
        </div>
        <SemanticHeader>{header}</SemanticHeader>
        <p>{desc}</p>
        {children}
      </Segment>
    </Grid>
  );
};