/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./contact.scss";
import { Grid, Header, Icon, Container } from "semantic-ui-react";
import { INFO_EMAIL } from "../../constant";

export const Contact = props => {
  const { children } = props;
  return (
    <Grid padded stackable columns={2} className="contact-grid" id="contact">
      <Grid.Column className="details-col">
        <Header>CONTACT US</Header>
        <Container>
          <Icon size="big" name="mail" />
          <p>
            P. O. Box 796, Mt Waverley
            <br /> Victoria - 3149, Australia
          </p>
        </Container>
        <Container>
          <Icon size="big" name="map" />
          <p>{INFO_EMAIL}</p>
        </Container>
        <Container>
          <Icon size="big" name="linkedin" />
          <p>
            <a href="#">Linkedin</a>
          </p>
        </Container>
        <Container>
          <Icon size="big" name="facebook" />
          <p>
            <a href="https://www.facebook.com/RCOBAWA/">Facebook</a>
          </p>
        </Container>
      </Grid.Column>
      <Grid.Column>{children}</Grid.Column>
    </Grid>
  );
};
