import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";
import { showError } from "../../utils";
import { LIMIT, DELETED_MSG, CREATED_MSG, UPDATED_MSG } from "../../constant";

const GET_MEMBERS_LOADING = "GET_MEMBERS_LOADING";
const ALL_MEMBERS = "ALL_MEMBERS";
const GET_GROUPS_LOADING = "GET_GROUPS_LOADING";
const ALL_GROUPS = "ALL_GROUPS";
const PUT_GROUPS_LOADING = "PUT_GROUPS_LOADING";
const GET_MEMBER_LOADING = "GET_MEMBER_LOADING";
const MEMBER = "MEMBER";
const DELETE_MEMBER_LOADING = "DELETE_MEMBER_LOADING";
const COMMENT_MEMBER_LOADING = "COMMENT_MEMBER_LOADING";
const MEMBERSHIP_LOADING = "MEMBERSHIP_LOADING";
const MEMBERSHIP = "MEMBERSHIP";
const MEMBERSHIP_SET_LOADING = "MEMBERSHIP_LOADING";
const GET_MEMBER_PAYMENTS = "GET_MEMBER_PAYMENTS";
const MEMBER_PAYMENTS = "MEMBER_PAYMENTS";
const PAYMENT_LOADING = "PAYMENT_LOADING";
const PAYMENT = " PAYMENT";
const ALL_MEMBERS_NO_LIMIT = "ALL_MEMBERS_NO_LIMIT";
const CSV_UPLOAD_LOADING = "CSV_UPLOAD_LOADING";
const CSV_UPLOAD_ERROR = "CSV_UPLOAD_ERROR";
const EXPORT_MEMBER_CSV_LOADING = "EXPORT_MEMBER_CSV_LOADING";
const EXPORT_MEMBER_CSV = "EXPORT_MEMBER_CSV";
const EXPORT_GROUP_CSV_LOADING = "EXPORT_GROUP_CSV_LOADING";
const EXPORT_GROUP_CSV = "EXPORT_GROUP_CSV";
const GET_USER_LODING="GET_USER_LODING"
const USER="USER"

const initialState = {
  membershipLoading: false,
  membership: [],
  getMembersLoading: false,
  allMembers: [],
  getGroupsLoading: false,
  allGroups: [],
  putMembersLoading: false,
  getMemberLoading: false,
  member: {},
  deleteMemberLoading: false,
  addCommentLoading: false,
  getMemberPaymetLoading: false,
  memberPayment: [],
  payment: {},
  allMembersNoLimit: [],
  csvUploadLoading: false,
  csvUploadError: {},
  exportMemberCsv: {},
  exportMemberCsvLoading: false,
  exportGroupCsv: {},
  exportGroupCsvLoading: false,
  getUserLoading: false,
  user:{},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_MEMBERS_LOADING:
      return {
        ...state,
        getMembersLoading: action.payload
      };
    case ALL_MEMBERS:
      return {
        ...state,
        allMembers: action.payload.docs,
        pages: action.payload.pages,
        count: action.payload.total
      };
    case GET_GROUPS_LOADING:
      return {
        ...state,
        getGroupsLoading: action.payload
      };
    case ALL_GROUPS:
      return {
        ...state,
        allGroups: action.payload
      };
    case PUT_GROUPS_LOADING:
      return {
        ...state,
        putMembersLoading: action.payload
      };
    case GET_MEMBER_LOADING:
      return {
        ...state,
        getMemberLoading: action.payload
      };
    case MEMBER:
      return {
        ...state,
        member: action.payload
      };
    case DELETE_MEMBER_LOADING:
      return {
        ...state,
        deleteMemberLoading: action.payload
      };
    case COMMENT_MEMBER_LOADING:
      return {
        ...state,
        addCommentLoading: action.payload
      };
    case MEMBERSHIP_LOADING:
      return {
        ...state,
        membershipLoading: action.payload
      };
    case MEMBERSHIP:
      return {
        ...state,
        membership: action.payload
      };
    case MEMBERSHIP_SET_LOADING:
      return {
        ...state,
        membershipLoading: {
          [action.payload.type]: action.payload.status
        }
      };
    case GET_MEMBER_PAYMENTS:
      return {
        ...state,
        getMemberPaymetLoading: action.payload
      };
    case MEMBER_PAYMENTS:
      return {
        ...state,
        memberPayments: action.payload.docs,
        paymentPages: action.payload.pages,
        paymentsTotal: action.payload.total
      };
    case PAYMENT_LOADING:
      return {
        ...state,
        paymentLoading: action.payload
      };
    case PAYMENT:
      return {
        ...state,
        payment: action.payload
      };
    case ALL_MEMBERS_NO_LIMIT:
      return {
        ...state,
        allMembersNoLimit: action.payload
      };
    case CSV_UPLOAD_LOADING:
      return {
        ...state,
        csvUploadLoading: action.payload
      };
    case CSV_UPLOAD_ERROR:
      return {
        ...state,
        csvUploadError: action.payload
      };
    case EXPORT_MEMBER_CSV_LOADING:
      return {
        ...state,
        exportMemberCsvLoading: action.payload
      };
    case EXPORT_MEMBER_CSV:
      return {
        ...state,
        exportMemberCsv: action.payload
      };
    case EXPORT_GROUP_CSV:
      return {
        ...state,
        exportGroupCsv: action.payload
      };
    case EXPORT_GROUP_CSV_LOADING:
      return {
        ...state,
        exportGroupCsvLoading: action.payload
      };
      case GET_USER_LODING:
        return {
          ...state,
          getUserLoading: action.payload
        };
        case USER:
          return {
            ...state,
            user: action.payload
          };
    default:
      return state;
  }
}

export function getAllMembers(page, filter, membershipType, memberName) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          limit: LIMIT,
          filter: filter ? filter : null,
          membershipType: membershipType ? membershipType : null,
          memberName: memberName ? memberName : null
        };
        if (page) {
          params.offset = page;
        } else {
          params.offset = 1;
        }
        dispatch({ type: GET_MEMBERS_LOADING, payload: true });
        const response = await axios.get("/users", {
          params: params
        });
        dispatch({ type: ALL_MEMBERS, payload: response.data });
        dispatch({ type: GET_MEMBERS_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: GET_MEMBERS_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function getAllGroups() {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const limit = 25;
        const offset = 1;
        dispatch({ type: GET_GROUPS_LOADING, payload: true });
        const response = await axios.get("/groups", {
          params: { limit, offset }
        });
        dispatch({ type: ALL_GROUPS, payload: response.data });
        dispatch({ type: GET_GROUPS_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: GET_GROUPS_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function updateMember(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: PUT_GROUPS_LOADING, payload: true });

        let formData = {
          userId: data.userId,
          firstName: data.firstName,
          lastName: data.lastName,
          membershipType: data.membershipType,
          DOB: data.DOB,
          email: data.email,
          streetName: data.streetName,
          suburb: data.suburb,
          state: data.state,
          postCode: data.postCode,
          phoneNumber: data.phoneNumber,
          batch: data.batch,
          deceasedStatus: data.deceasedStatus
        };

        if (data.spouse !== null) {
          formData.spouse = data.spouse;
        }
        await axios.put("/users", formData);
        dispatch({ type: PUT_GROUPS_LOADING, payload: false });
        toast.success(UPDATED_MSG);
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: PUT_GROUPS_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function getMember(userId) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: GET_MEMBER_LOADING, payload: true });
        const params = { userId: userId };
        const response = await axios.get("/users/getUser", { params });
        dispatch({ type: MEMBER, payload: response.data });
        dispatch({ type: GET_MEMBER_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: GET_MEMBER_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function deleteMember(userId) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: DELETE_MEMBER_LOADING, payload: true });
        const params = { userId: userId };
        await axios.delete("/users", { params });
        dispatch({ type: DELETE_MEMBER_LOADING, payload: false });
        toast.success(DELETED_MSG);
        dispatch(getAllMembers());
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: DELETE_MEMBER_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function addMemberComment(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: COMMENT_MEMBER_LOADING, payload: true });
        await axios.post("/users/addComment", data);
        dispatch({ type: COMMENT_MEMBER_LOADING, payload: false });
        toast.success(CREATED_MSG);
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: COMMENT_MEMBER_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function getMembershipTypes() {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: MEMBERSHIP_LOADING, payload: true });
      try {
        const response = await axios.get("/users/membershipTypes");
        const dataSet = _.map(response.data, data => {
          return {
            id: data.id,
            amount: data.amount,
            value: `$${data.amount}`,
            description: data.condition,
            title: data.title
          };
        });
        dispatch({ type: MEMBERSHIP, payload: dataSet });
        dispatch({ type: MEMBERSHIP_LOADING, payload: false });
        resolve();
      } catch (error) {
        dispatch({ type: MEMBERSHIP_LOADING, payload: false });
        showError(error);
        reject();
      }
    });
  };
}

export function createMemberGroup(type, form) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: MEMBERSHIP_SET_LOADING,
        payload: { type, status: true }
      });
      await axios.post(`/groups`, form);
      toast.success(CREATED_MSG);
      dispatch({
        type: MEMBERSHIP_SET_LOADING,
        payload: { type, status: false }
      });
      dispatch(getAllGroups());
    } catch (error) {
      showError(error);
      dispatch({
        type: MEMBERSHIP_SET_LOADING,
        payload: { type, status: false }
      });
    }
  };
}

export function addMemberToGroup(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post("/groups/members", data);
        toast.success("Member added successfully");
        dispatch(getAllGroups());
        resolve();
      } catch (error) {
        showError(error);
        reject();
      }
    });
  };
}

export function deleteGroup(groupId) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.delete(`/groups?groupId=${groupId}`);
        toast.success(DELETED_MSG);
        dispatch(getAllGroups());
        resolve();
      } catch (error) {
        showError(error);
        reject();
      }
    });
  };
}

export function deleteMemberFromGroup(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.delete(
          `/groups/members?groupId=${data.groupId}&userId=${data.userId}`
        );
        toast.success("Member removed successfully...");
        dispatch(getAllGroups());
        resolve();
      } catch (error) {
        showError(error);
        reject();
      }
    });
  };
}

export function membershipPayment(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: PAYMENT_LOADING, payload: true });
        const response = await axios.post("/payment/membership", data);
        dispatch({ type: PAYMENT, payload: response.data });
        dispatch({ type: PAYMENT_LOADING, payload: false });
        toast.success("Payment successful");
        resolve();
      } catch (error) {
        dispatch({ type: PAYMENT_LOADING, payload: false });
        showError(error);
        reject();
      }
    });
  };
}

export function updatePayment(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: PAYMENT_LOADING, payload: true });
        const response = await axios.post("/users/updatePayments", data);
        dispatch({ type: PAYMENT, payload: response.data });
        toast.success(response.data.message);
        dispatch({ type: PAYMENT_LOADING, payload: false });
        toast.success("Payment successful");
        resolve();
      } catch (error) {
        dispatch({ type: PAYMENT_LOADING, payload: false });
        showError(error);
        reject();
      }
    });
  };
}

export function sendPaymentReminder(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post("/users/paymentReminder", data);
        toast.success(response.data);
        resolve();
      } catch (error) {
        showError(error);
        reject();
      }
    });
  };
}

export function getMemberPayment(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          offset: data.offset ? data.offset : 1,
          limit: data.limit ? data.limit : LIMIT
        };
        dispatch({ type: GET_MEMBER_PAYMENTS, payload: true });
        const response = await axios.get(
          `/users/payments?userId=${data.userId}&limit=${params.limit}&offset=${params.offset}`
        );
        dispatch({ type: MEMBER_PAYMENTS, payload: response.data });
        dispatch({ type: GET_MEMBER_PAYMENTS, payload: false });
        resolve();
      } catch (error) {
        dispatch({ type: GET_MEMBER_PAYMENTS, payload: false });

        showError(error);
        reject();
      }
    });
  };
}

export function getAllMembersWithoutLimit() {
  return async dispatch => {
    const response = await axios.get("/users/groups");
    dispatch({ type: ALL_MEMBERS_NO_LIMIT, payload: response.data });
  };
}

export function uploadMemberCsv(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: CSV_UPLOAD_LOADING, payload: true });
        const response = await axios.post("/users/import", data);
        dispatch({ type: CSV_UPLOAD_LOADING, payload: false });
        toast.success(response.data);
        dispatch(getAllMembers())
        resolve();
      } catch (error) {
        dispatch({ type: CSV_UPLOAD_ERROR, payload: error.response.data.data });
        dispatch({ type: CSV_UPLOAD_LOADING, payload: false });
        showError(error);
        reject();
      }
    });
  };
}

export function exportMembers() {
  return async dispatch => {
    try {
      dispatch({ type: EXPORT_MEMBER_CSV_LOADING, payload: true });
      const response = await axios.get("/users/export");
      dispatch({ type: EXPORT_MEMBER_CSV, payload: response.data });
      dispatch({ type: EXPORT_MEMBER_CSV_LOADING, payload: false });
    } catch (error) {
      showError(error);
      dispatch({ type: EXPORT_MEMBER_CSV_LOADING, payload: false });
    }
  };
}

export function exportGroups() {
  return async dispatch => {
    try {
      dispatch({ type: EXPORT_GROUP_CSV_LOADING, payload: true });
      const response = await axios.get("/users/export-group");
      console.log("TCL: exportGroups -> response", response)
      dispatch({ type: EXPORT_GROUP_CSV, payload: response.data });
      dispatch({ type: EXPORT_GROUP_CSV_LOADING, payload: false });
    } catch (error) {
      showError(error);
      dispatch({ type: EXPORT_GROUP_CSV_LOADING, payload: false });
    }
  };
}

export function getUserById(userId) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: GET_USER_LODING, payload: true });
        const params = { userId: userId };
        const response = await axios.get("users/getUserById", { params });
        console.log('response', response);
        dispatch({ type: USER, payload: response.data });
        dispatch({ type: GET_USER_LODING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: GET_USER_LODING, payload: false });
        reject();
      }
    });
  };
}
