import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllPosts } from "../../store/actions";
import "./content.scss";
import { Segment, Image } from "semantic-ui-react";
import { CustomTable } from "../../components";
import history from "../../history";
import moment from "moment";
import $ from "jquery";


class News extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          render: data => {
            return (
              <div 
              >
                {data.image ? (
                  <Image
                    className="news-img"
                    src={`${data.image}`}
                    onClick={() => history.push(`/news/newsArticle/${data.id}`)}
                  />
                ) : (
                  "-"
                )}
              </div>
            );
          }
        },
        {
          render: data => {
            return (
              <div className="news-div">
                <div
                  className="news-title"
                  onClick={() => history.push(`/news/newsArticle/${data.id}`)}
                >
                  {data.title ? data.title : "-"}
                </div>
                <div className="news-date">
                  {data.createdAt
                    ? moment(data.createdAt).format("DD MMMM YYYY")
                    : "-"}
                </div>
                <div
                  className="news-content"
                >
                  {this.renderNewsContent(data)}
                </div>
              </div>
            );
          }
        }
      ],
      news: [],
      page: 1
    };
  }

  async componentDidMount() {
    try {
      const { getAllPosts } = this.props;
      await getAllPosts();
      this.setState({
        news: this.props.posts
      });
    } catch (error) {
      console.log("TCL: News -> componentDidMount -> error", error)
    }
    
  }

  renderNewsContent(news) {
    if (news.content) {
      if ($(news.content).text().length > 200) {
        return $(news.content)
          .text()
          .slice(0, 200);
      } else {
        return $(news.content).text();
      }
    } else {
      return <i>No content</i>;
    }
  }

  async handlePaginationChange(activePage) {
    try {
      const { getAllPosts } = this.props;
      await getAllPosts(activePage);
      await this.setState({
        page: activePage,
        news: this.props.posts
      });
    } catch (error) {
      console.log(">:Members-> handlePaginationChange -> error", error);
    }
  }
  render() {
    const { columns, news } = this.state;
    return (
      <Segment>
        <CustomTable
          columns={columns}
          data={news}
          pagination
          noOfPages={this.props.postPages}
          page={this.state.page}
          handlePaginationChange={activePage =>
            this.handlePaginationChange(activePage)
          }
        />
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  const { content } = state;
  return {
    posts: content.posts,
    postPages: content.postPages,
    postCount: content.postCount
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllPosts
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(News);
