import React from "react";
import "./contactForm.scss";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button } from "reactstrap";
import { Footer, Contact } from "../../components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addInquiry } from "../../store/actions";
import Header from "../../containers/homePage/header";

class ContactForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.state = {};
  }

  // TODO: Always use try catch when you creating a async function
  async handleValidSubmit(event, values) {
    this.setState({ values });
    await this.props.addInquiry(values);
  }

  render() {
    return (
      <>
        <Header path="contact" />
        <Contact>
          <div className="contact-form">
            <h1>SUBMIT YOUR INQUIRY</h1>
            <AvForm onValidSubmit={this.handleValidSubmit}>
              <AvField
                name="name"
                label="Name"
                errorMessage="Name is required"
                validate={{ required: { value: true } }}
              />
              <AvField
                name="email"
                label="Email Address"
                errorMessage="Email Address is required"
                validate={{ required: { value: true } }}
                type="email"
              />
              <AvField
                name="mobileNumber"
                label="Phone Number"
                errorMessage="Phone Number is required"
                validate={{
                  required: { value: true },
                  pattern: {value: '^[0-9]*$', errorMessage: 'There are invalid characters'},
                  minLength: {value: 12, errorMessage: 'Minimum 12 digits'},
                  maxLength: {value: 12, errorMessage: 'Maximum 12 digits'}
                }}
                type="number"
              />
              <AvField
                name="inquiry"
                label="Message"
                errorMessage="Message is required"
                validate={{ required: { value: true } }}
                type="textarea"
              />
              <Button>SUBMIT INQUIRY</Button>
            </AvForm>
          </div>
        </Contact>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => {
  const { inquiry } = state;
  return {
    ...inquiry
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      addInquiry
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactForm);
