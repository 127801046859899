import React from 'react';
import './attendance.scss';
import { Button, Search, Modal, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAllMembers,
  getAlltickets,
  addAttendee,
  updateAttendee,
  getMember
} from '../../../store/actions';

class AddAttendeeForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ticketLoading: false,
      ticketValue: props.edit
        ? props.item.ticketNo
          ? props.item.ticketNo
          : null
        : '',
      ticketResults: [],
      firstNameLoading: false,
      firstNameValue: props.edit
        ? props.item.User
          ? props.item.User.firstName
          : props.item.guestFirstName
          ? props.item.guestFirstName
          : null
        : '',
      firstNameResults: [],
      lastNameLoading: false,
      lastNameValue: props.edit
        ? props.item.User
          ? props.item.User.lastName
          : props.item.guestLastName
          ? props.item.guestLastName
          : null
        : '',
      lastNameResults: [],
      phoneNumberLoading: false,
      phoneNumberValue: props.edit
        ? props.item.User
          ? props.item.User.phoneNumber
          : props.item.guestMobileNumber
          ? props.item.guestMobileNumber
          : null
        : '',
      phoneNumberResults: [],
      emailValue: props.edit
        ? props.item.User
          ? props.item.User.Email
          : props.item.guestEmail
          ? props.item.guestEmail
          : null
        : '',
      emailLoading: false,
      emailResults: []
    };
  }

  async componentDidMount() {
    try {
      window.scrollTo(0, 0);
      const { getAllMembers, getAlltickets, match } = this.props;
      await getAllMembers();
      await getAlltickets({ itemId: match.params.eventId });
      this.setState({
        firstNameLoading: this.props.getMembersLoading,
        firstNames: this.props.allMembers.map((item, i) => {
          return { key: item.id, title: item.firstName, price: item.lastName };
        }),
        lastNames: this.props.allMembers.map((item, j) => {
          return { key: j, title: item.lastName };
        }),
        phoneNumbers: this.props.allMembers.map((item, s) => {
          return { key: s, title: item.phoneNumber };
        }),
        emails: this.props.allMembers.map((item, e) => {
          return { key: e, title: item.email };
        }),
        tickets: this.props.tickets.map(item => {
          return { key: item.ticketNo, title: item.ticketNo };
        })
      });
    } catch (error) {
      console.log('>: Members -> componentDidMount -> error', error);
    }
  }

  handleResultSelect = async (e, { result }, type) => {
    try {
      this.setState({ [type]: result.title });
      const { getMember } = this.props;
      if (type === 'firstNameValue') {
        await getMember(result.key);
        const { member } = await this.props;
        await this.setState({
          lastNameValue: member.lastName,
          phoneNumberValue: member.phoneNumber,
          emailValue: member.email,
          lastNameValueErr: null,
          phoneValueErr: null,
          emailValueErr: null
        });
      }
    } catch (error) {
      console.log('TCL: AddAttendeeForm -> handleResultSelect -> error', error);
    }
  };

  handleSearchChange = async (e, { value }, type) => {
    try {
      const { getAllMembers } = this.props;
      switch (type) {
        case 'ticketValue':
          const { tickets } = this.state;
          this.setState({ ticketLoading: true, ticketValue: value });

          setTimeout(() => {
            if (this.state.ticketValue.length < 1)
              return this.setState({
                ticketLoading: false,
                ticketValue: '',
                ticketResults: []
              });

            const re = new RegExp(_.escapeRegExp(this.state.ticketValue), 'i');
            const isMatch = result => re.test(result.title);

            this.setState({
              ticketLoading: false,
              ticketResults: _.filter(tickets, isMatch)
            });
          }, 300);
          break;
        case 'firstNameValue':
          this.setState({ firstNameLoading: true, firstNameValue: value });
          await getAllMembers(1, null, null, value);
          setTimeout(() => {
            if (this.state.firstNameValue.length < 1)
              return this.setState({
                firstNameLoading: false,
                firstNameValue: '',
                firstNameResults: []
              });

            const re = new RegExp(
              _.escapeRegExp(this.state.firstNameValue),
              'i'
            );
            const isMatch = result => re.test(result.title);
            const firstNames = this.props.allMembers.map((item, i) => {
              return {
                key: item.id,
                title: item.firstName,
                price: item.lastName
              };
            });
            this.setState({
              firstNameLoading: false,
              firstNameResults: _.filter(firstNames, isMatch)
            });
          }, 300);
          break;
        case 'lastNameValue':
          const { lastNames } = this.state;
          this.setState({ lastNameLoading: true, lastNameValue: value });

          setTimeout(() => {
            if (this.state.lastNameValue.length < 1)
              return this.setState({
                lastNameLoading: false,
                lastNameValue: '',
                lastNameResults: []
              });

            const re = new RegExp(
              _.escapeRegExp(this.state.lastNameValue),
              'i'
            );
            const isMatch = result => re.test(result.title);

            this.setState({
              lastNameLoading: false,
              lastNameResults: _.filter(lastNames, isMatch)
            });
          }, 300);
          break;
        case 'phoneNumberValue':
          const { phoneNumbers } = this.state;
          this.setState({ phoneNumberLoading: true, phoneNumberValue: value });

          setTimeout(() => {
            if (this.state.phoneNumberValue.length < 1)
              return this.setState({
                phoneNumberLoading: false,
                phoneNumberValue: '',
                phoneNumberResults: []
              });

            const re = new RegExp(
              _.escapeRegExp(this.state.phoneNumberValue),
              'i'
            );
            const isMatch = result => re.test(result.title);

            this.setState({
              phoneNumberLoading: false,
              phoneNumberResults: _.filter(phoneNumbers, isMatch)
            });
          }, 300);
          break;
        case 'emailValue':
          const { emails } = this.state;
          this.setState({ emailLoading: true, emailValue: value });

          setTimeout(() => {
            if (this.state.emailValue.length < 1)
              return this.setState({
                emailLoading: false,
                emailValue: '',
                emailResults: []
              });

            const re = new RegExp(_.escapeRegExp(this.state.emailValue), 'i');
            const isMatch = result => re.test(result.title);

            this.setState({
              emailLoading: false,
              emailResults: _.filter(emails, isMatch)
            });
          }, 300);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log('TCL: handleSearchChange -> error', error);
    }
  };

  submitAttendee() {
    const {
      ticketValue,
      firstNameValue,
      lastNameValue,
      phoneNumberValue,
      emailValue
    } = this.state;

    const {
      addAttendee,
      trigger,
      match,
      edit,
      updateAttendee,
      toggle,
      allMembers
    } = this.props;

    const existingMember = _.find(allMembers, o => {
      return o.email === emailValue;
    });
    if (
      ticketValue &&
      firstNameValue &&
      lastNameValue &&
      phoneNumberValue &&
      emailValue
    ) {
      let params;
      if (existingMember) {
        params = {
          ticketNo: ticketValue,
          guestFirstName: firstNameValue,
          guestLastName: lastNameValue,
          guestMobileNumber: phoneNumberValue,
          guestEmail: emailValue,
          eventId: match.params.eventId,
          userId: existingMember.id
        };
      } else {
        params = {
          ticketNo: ticketValue,
          guestFirstName: firstNameValue,
          guestLastName: lastNameValue,
          guestMobileNumber: phoneNumberValue,
          guestEmail: emailValue,
          eventId: match.params.eventId
        };
      }
      if (edit) {
        updateAttendee(params);
        toggle();
      } else {
        addAttendee(params);
        trigger(false);
      }
    } else {
      if (!ticketValue) {
        this.setState({
          ticketValueErr: 'Ticket value is required'
        });
      }
      if (!firstNameValue) {
        this.setState({
          firstNameValueErr: 'First name is required'
        });
      }
      if (!lastNameValue) {
        this.setState({
          lastNameValueErr: 'Last name is required'
        });
      }
      if (!phoneNumberValue) {
        this.setState({
          phoneValueErr: 'Mobile number is required'
        });
      }
      if (!emailValue) {
        this.setState({
          emailValueErr: 'Email is required'
        });
      }
    }
  }

  onBlurField(e, type) {
    if (!e.target.value) {
      switch (type) {
        case 'firstNameValue':
          this.setState({
            firstNameValueErr: 'Please select first name'
          });
          break;
        case 'lastNameValue':
          this.setState({
            lastNameValueErr: 'Please select last name'
          });
          break;
        case 'phoneNumberValue':
          this.setState({
            phoneValueErr: 'Please select phone number'
          });
          break;
        case 'emailValue':
          this.setState({
            emailValueErr: 'Please select email address'
          });
          break;
        case 'ticketValue':
          this.setState({
            ticketValueErr: 'Please select ticket number'
          });
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case 'firstNameValue':
          this.setState({
            firstNameValueErr: null
          });
          break;
        case 'lastNameValue':
          this.setState({
            lastNameValueErr: null
          });
          break;
        case 'phoneNumberValue':
          this.setState({
            phoneValueErr: null
          });
          break;
        case 'emailValue':
          this.setState({
            emailValueErr: null
          });
          break;
        case 'ticketValue':
          this.setState({
            ticketValueErr: null
          });
          break;
        default:
          break;
      }
    }
  }

  render() {
    const {
      ticketLoading,
      ticketValue,
      ticketResults,
      firstNameLoading,
      firstNameResults,
      firstNameValue,
      lastNameLoading,
      lastNameResults,
      lastNameValue,
      phoneNumberLoading,
      phoneNumberResults,
      phoneNumberValue,
      emailLoading,
      emailResults,
      emailValue,
      firstNameValueErr,
      lastNameValueErr,
      phoneValueErr,
      emailValueErr,
      ticketValueErr
    } = this.state;
    const { edit, trigger } = this.props;

    return (
      <div className="attendee-input-form">
        {edit ? null : (
          <Icon
            className="close-icon"
            name="close"
            onClick={() => trigger(false)}
          />
        )}
        <div className="modal-header-div">
          {edit ? 'Update Attendee' : 'Add Attendee'}
        </div>
        <label>First Name</label>
        <Search
          onBlur={e => this.onBlurField(e, 'firstNameValue')}
          className="search-input"
          loading={firstNameLoading}
          onResultSelect={(e, value) =>
            this.handleResultSelect(e, value, 'firstNameValue')
          }
          onSearchChange={_.debounce(
            (e, value) => this.handleSearchChange(e, value, 'firstNameValue'),
            500,
            {
              leading: true
            }
          )}
          onFocus={_.debounce(
            (e, value) => this.handleSearchChange(e, value, 'firstNameValue'),
            500,
            {
              leading: true
            }
          )}
          results={firstNameResults}
          value={firstNameValue}
        />
        {firstNameValueErr ? (
          <div className="err-msg-div">{firstNameValueErr}</div>
        ) : null}
        <label>Last Name</label>
        <Search
          onBlur={e => this.onBlurField(e, 'lastNameValue')}
          className="search-input"
          loading={lastNameLoading}
          onResultSelect={(e, value) =>
            this.handleResultSelect(e, value, 'lastNameValue')
          }
          onSearchChange={_.debounce(
            (e, value) => this.handleSearchChange(e, value, 'lastNameValue'),
            500,
            {
              leading: true
            }
          )}
          results={lastNameResults}
          value={lastNameValue}
          open={false}
          icon = {false}
        />
        {lastNameValueErr ? (
          <div className="err-msg-div">{lastNameValueErr}</div>
        ) : null}
        <label>Mobile Number</label>
        <Search
          onBlur={e => this.onBlurField(e, 'phoneNumberValue')}
          className="search-input"
          loading={phoneNumberLoading}
          onResultSelect={(e, value) =>
            this.handleResultSelect(e, value, 'phoneNumberValue')
          }
          onSearchChange={_.debounce(
            (e, value) => this.handleSearchChange(e, value, 'phoneNumberValue'),
            500,
            {
              leading: true
            }
          )}
          results={phoneNumberResults}
          value={phoneNumberValue}
          open={false}
          icon = {false}
        />
        {phoneValueErr ? (
          <div className="err-msg-div">{phoneValueErr}</div>
        ) : null}
        <label>Email Address</label>
        <Search
          onBlur={e => this.onBlurField(e, 'emailValue')}
          className="search-input"
          loading={emailLoading}
          onResultSelect={(e, value) =>
            this.handleResultSelect(e, value, 'emailValue')
          }
          onSearchChange={_.debounce(
            (e, value) => this.handleSearchChange(e, value, 'emailValue'),
            500,
            {
              leading: true
            }
          )}
          results={emailResults}
          value={emailValue}
          open={false}
          icon = {false}
        />
        {emailValueErr ? (
          <div className="err-msg-div">{emailValueErr}</div>
        ) : null}
        <label>Ticket Number</label>
        <Search
          disabled={edit ? true : false}
          onBlur={e => this.onBlurField(e, 'ticketValue')}
          className="search-input"
          loading={ticketLoading}
          onResultSelect={(e, value) =>
            this.handleResultSelect(e, value, 'ticketValue')
          }
          onSearchChange={_.debounce(
            (e, value) => this.handleSearchChange(e, value, 'ticketValue'),
            500,
            {
              leading: true
            }
          )}
          results={ticketResults}
          value={ticketValue}
        />
        {ticketValueErr ? (
          <div className="err-msg-div">{ticketValueErr}</div>
        ) : null}

        <Button
          className="attendee-form-btn"
          onClick={() => this.submitAttendee()}
          loading={false}
        >
          {edit ? 'UPDATE ATTENDEE' : 'ADD ATTENDEE TO EVENT'}
        </Button>
      </div>
    );
  }
}

class AddAttendeeEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    const { modal } = this.state;
    const { item } = this.props;

    return (
      <Modal
        centered
        trigger={<div className="view">Edit</div>}
        open={modal}
        onOpen={() => this.toggle()}
        onClose={() => this.toggle()}
        size="tiny"
      >
        <Modal.Content>
          <Icon
            className="close-icon"
            name="close"
            onClick={() => this.toggle()}
          />
          <AddAttendeeForm
            toggle={() => this.toggle()}
            item={item}
            edit={true}
            {...this.props}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { members, attendance } = state;
  return {
    ...members,
    ...attendance
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllMembers,
      getAlltickets,
      addAttendee,
      updateAttendee,
      getMember
    },
    dispatch
  );
};

AddAttendeeForm = connect(mapStateToProps, mapDispatchToProps)(AddAttendeeForm);

export { AddAttendeeForm, AddAttendeeEdit };
