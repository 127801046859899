import React from "react";
import "./eventPayment.scss";
import { Grid, Header, Icon, Button } from "semantic-ui-react";
import { PaymentHolder } from "../../../components";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getEventByEventId,
  eventTicketPayment,
  eventValidation
} from "../../../store/actions";
import _ from "lodash";
import DropIn from "braintree-web-drop-in-react";
import history from "../../../history";
// import { toast } from "react-toastify";
import { DROP_IN_AUTH_KEY } from "../../../constant";

class EventPayment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      totalTicketPrice: 0
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      const { getEventByEventId } = this.props;
      const user_id = localStorage.getItem("user_id");
      this.setState({
        user_id: user_id
      });
      // await eventValidation({
      //   userId: user_id,
      //   eventId: match.params.id
      // });
      // if (!this.props.eventValidationVal.accessValidation) {
      //   toast.error("Please choose an event that you have access");
      //   history.push("/events#events");
      // }
      // else {
      await getEventByEventId(this.props.match.params.id);
      const { event } = this.props;
      this.setState({
        eventName: event.title,
        ticketPrice: event.ticketPrice,
        maxTicketPerMember: event.maxTicketPerMember
      });
      // }
    } catch (error) {
      console.log("TCL: EventPayment -> componentDidMount -> error", error);
    }
  }

  async handleValidSubmit(event, values) {
    try {
      const { totalTicketPrice, nonce } = this.state;
      const { eventTicketPayment, match } = this.props;
      const userId = localStorage.getItem("user_id");
      if (userId) {
        if (totalTicketPrice === 0) {
          const params = {
            paymentMode: "CARD",
            paymentType: "EVENT",
            amount: totalTicketPrice,
            eventId: match.params.id,
            paymentMethodNonce: nonce,
            numberOfTickets: values.ticketCount,
            userId: userId,
            guestFirstName: values.firstName,
            guestLastName: values.lastName,
            guestEmail: values.email
          };
          await eventTicketPayment(params);
          history.push(
            `/event/${match.params.id}/payment/${this.props.eventPayment.itemPaymentId}`
          );
        } else {
          this.instance &&
            this.instance.requestPaymentMethod().then(data => {
              this.setState(
                {
                  nonce: data.nonce
                },
                async () => {
                  try {
                    const params = {
                      paymentMode: "CARD",
                      paymentType: "EVENT",
                      amount: totalTicketPrice,
                      eventId: match.params.id,
                      paymentMethodNonce: data.nonce,
                      numberOfTickets: values.ticketCount,
                      userId: userId,
                      guestFirstName: values.firstName,
                      guestLastName: values.lastName,
                      guestEmail: values.email
                    };
                    await eventTicketPayment(params);
                    history.push(
                      `/event/${match.params.id}/payment/${this.props.eventPayment.itemPaymentId}`
                    );
                  } catch (error) {
                    console.log(
                      "TCL: EventPayment -> handleValidSubmit -> error",
                      error
                    );
                  }
                }
              );
            });
        }
      } else {
        if (totalTicketPrice === 0) {
          const params = {
            paymentMode: "CARD",
            paymentType: "EVENT",
            amount: totalTicketPrice,
            eventId: match.params.id,
            paymentMethodNonce: nonce,
            numberOfTickets: values.ticketCount,
            guestFirstName: values.firstName,
            guestLastName: values.lastName,
            guestEmail: values.email
          };
          await eventTicketPayment(params);
          history.push(
            `/event/${match.params.id}/payment/${this.props.eventPayment.itemPaymentId}`
          );
        } else {
          this.instance &&
            this.instance.requestPaymentMethod().then(data => {
              this.setState(
                {
                  nonce: data.nonce
                },
                async () => {
                  try {
                    const params = {
                      paymentMode: "CARD",
                      paymentType: "EVENT",
                      amount: totalTicketPrice,
                      eventId: match.params.id,
                      paymentMethodNonce: data.nonce,
                      numberOfTickets: values.ticketCount,
                      guestFirstName: values.firstName,
                      guestLastName: values.lastName,
                      guestEmail: values.email
                    };
                    await eventTicketPayment(params);
                    history.push(
                      `/event/${match.params.id}/payment/${this.props.eventPayment.itemPaymentId}`
                    );
                  } catch (error) {
                    console.log(
                      "TCL: EventPayment -> handleValidSubmit -> error",
                      error
                    );
                  }
                }
              );
            });
        }
      }
    } catch (error) {
      console.log("TCL: EventPayment -> handleValidSubmit -> error", error);
    }
  }

  renderNoOfTickets() {
    const { maxTicketPerMember } = this.state;
    return _.times(maxTicketPerMember, item => {
      return <option key={item}>{item + 1}</option>;
    });
  }

  NoOfTicketChange(e) {
    const { ticketPrice } = this.state;
    this.setState({
      totalTicketPrice: e.target.value * ticketPrice
    });
  }

  renderBrainTree() {
    return (
      <DropIn
        options={{
          authorization: DROP_IN_AUTH_KEY,
          card: {
            overrides: {
              fields: {
                number: {
                  placeholder: "1111 1111 1111 1111" // Update the number field placeholder
                }
              },
              styles: {
                input: {
                  "font-size": "18px" // Change the font size for all inputs
                },
                ":focus": {
                  color: "black" // Change the focus color to red for all inputs
                }
              }
            }
          }
        }}
        onInstance={instance => (this.instance = instance)}
      />
    );
  }

  render() {
    const { eventName, totalTicketPrice } = this.state;
    const { eventPaymentLoading, event } = this.props;
    return (
      <PaymentHolder>
        <Grid padded className="event-payment">
          <Grid.Row>
            <div>
              <Header>
                {event.ticketPrice === 0 ? (
                  "Free Event"
                ) : (
                  <>
                    <Icon name="lock" />
                    SECURED PAYMENT
                  </>
                )}
              </Header>
              <h1> {eventName} </h1>
            </div>
            <div className="field-row">
              <AvForm onValidSubmit={this.handleValidSubmit}>
                <Grid padded>
                  <Grid.Row className="field-row">
                    <Grid.Column className="field-col" width={8}>
                      <AvField
                        className="ticket-count"
                        name="ticketCount"
                        label="Number of Tickets"
                        type="select"
                        onChange={e => this.NoOfTicketChange(e)}
                        errorMessage="Number of tickets is required"
                        validate={{
                          required: { value: true }
                        }}
                      >
                        <option disabled value="">
                          Select...
                        </option>
                        {this.renderNoOfTickets()}
                      </AvField>
                    </Grid.Column>
                    <Grid.Column className="field-col" width={8}>
                      {event.ticketPrice === 0 ? null : (
                        <AvField
                          disabled
                          className="tot-price"
                          name="totPrice"
                          label="Total Price"
                          value={"$" + totalTicketPrice.toString()}
                          errorMessage="Total Price is required"
                          validate={{
                            required: { value: true }
                          }}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  {event.accessType === "public" ? (
                    <Grid.Row className="field-row">
                      <Grid.Column className="field-col" width={8}>
                        <AvField
                          className="ticket-count"
                          name="firstName"
                          label="First Name"
                          errorMessage="First name is required"
                          validate={{
                            required: { value: true }
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column className="field-col" width={8}>
                      <AvField
                          className="ticket-count"
                          name="lastName"
                          label="Last Name"
                          errorMessage="Last name is required"
                          validate={{
                            required: { value: true }
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column className="field-col" width={8}>
                        <AvField
                          className="ticket-count"
                          name="email"
                          label="Email"
                          type="email"
                          validate={{
                            required: { value: true, errorMessage: "Email is required" },
                            email: true
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column className="field-col" width={8}>
                      </Grid.Column>
                    </Grid.Row>
                  ) : null}
                </Grid>
                {totalTicketPrice === 0 ? null : (
                  <>
                    <div className="heading-row">
                      <h2>Payment Details</h2>
                      <p>(We only accept card payments at the moment)</p>
                    </div>
                    <div>{this.renderBrainTree()}</div>
                  </>
                )}
                <Button loading={eventPaymentLoading}>
                  {event.ticketPrice === 0 ? "Confirm" : "MAKE PAYMENT"}
                </Button>
              </AvForm>
            </div>
          </Grid.Row>
        </Grid>
      </PaymentHolder>
    );
  }
}

const mapStateToProps = state => {
  const { items } = state;
  return {
    ...items
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getEventByEventId,
      eventTicketPayment,
      eventValidation
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPayment);
