import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CardHolder, Ticket, Footer } from '../../components';
import { MEMBERSHIP_DESC } from '../../constant';
import { getMembershipTypes } from '../../store/actions';
import Header from '../homePage/header';

class Membership extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { getMembershipTypes } = this.props;
    getMembershipTypes();
  }

  render() {
    const { membershipLoading, membership } = this.props;
    return (
      <>
        <Header path="membership" />
        <CardHolder
          id="membership"
          heading={'RCOBAWA MEMBERSHIP'}
          description={MEMBERSHIP_DESC}
          type="membership"
        >
          <Ticket
            types={membership}
            type="membership"
            loading={membershipLoading}
          />
        </CardHolder>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    members: { membershipLoading = false, membership = [] }
  } = state;
  return {
    membershipLoading,
    membership
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMembershipTypes
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Membership);
