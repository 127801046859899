import { combineReducers } from 'redux';
import auth from './modules/auth';
import members from './modules/members';
import items from './modules/items';
import content from './modules/content';
import inquiry from './modules/inquiry';
import attendance from './modules/attendance';
import report from './modules/report';

const reducers = combineReducers({
  auth,
  members,
  items,
  content,
  inquiry,
  attendance,
  report
});

export default reducers;
