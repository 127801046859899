import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';
// Sentry.init({
//  dsn: "https://9c73ca3b5b1f4bc0abdb12949dc3848f@sentry.io/1329032"
// });
// should have been called before using it here
// ideally before even rendering your react app

export default class ExampleBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <a href onClick={() => Sentry.showReportDialog()}>
          Report feedback view is loading...
        </a>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
