import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CardHolder, Ticket, Footer } from '../../components';
import { getUpcomingItems, eventValidation } from '../../store/actions';
import Header from '../homePage/header';
import _ from 'lodash';

class EventTypes extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { getUpcomingItems } = this.props;
    getUpcomingItems();
  }

  render() {
    const { upcomingItems, itemLoading } = this.props;
    const mainEvent = _.find(upcomingItems, n => {
       return n.isMain ;
    })
    // const [latestEvent] = upcomingItems;
    return (
      <>
        <Header path="events" />
        <CardHolder
          id="events"
          heading={'MAIN EVENT'}
          image={mainEvent ? mainEvent.eventImage : require('../../assets/event-img.png')}
          type="events"
        >
          <Ticket types={upcomingItems} type="events" loading={itemLoading.upcomingItems} {...this.props}/>
        </CardHolder>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => {
  const { items } = state;
  return {
    ...items
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getUpcomingItems,
      eventValidation
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventTypes);
