/* eslint-disable eqeqeq */
import React from "react";
import "./report.scss";
import _ from "lodash";
import { Icon } from "semantic-ui-react";

class ReportCard extends React.PureComponent {
  renderMetric(metric) {
    switch (metric) {
      case "pageViews":
        return "SITE VISITORS";
      case "userCount":
        return "NEW MEMBERS";
      case "totalIncome":
        return "OVERALL FINANCES";
      default:
        break;
    }
  }

  render() {
    const { count, percentage, metric } = this.props;
    return (
      <div className="report-card-div">
        <div className="report-title">{this.renderMetric(metric)}</div>
        <div className="report-val">
          {metric === "totalIncome"
            ? count === null
              ? "$0.00"
              : "$" + count + ".00"
            : count}
        </div>
        <div>
          {metric !== "totalIncome" ? (
            !_.isEmpty(percentage) ? (
              percentage.sign == "+" ? (
                <div className="increment-div">
                  <Icon name="long arrow alternate up" />{" "}
                  {percentage.amount + "%"}
                </div>
              ) : (
                <div className="decrement-div">
                  <Icon name="long arrow alternate down" />
                  {percentage.amount + "%"}
                </div>
              )
            ) : (
              "-"
            )
          ) : (
            <div className="report-range">Through sales and memberships</div>
          )}
        </div>
      </div>
    );
  }
}

export default ReportCard;
