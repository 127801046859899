import 'babel-polyfill';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import configureStore from './store';
import AppRouting from './routing';
import axios from 'axios';
import qs from 'qs';
import { BASE_URL, GA_MEASUREMENT_ID } from './constant';
import ExampleBoundary from './ErrorBoundary';
import { unregister } from './serviceWorker';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'semantic-ui-css/semantic.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initalizeApp, logOut } from './store/actions';
import { PersistGate } from 'redux-persist/integration/react';
import * as FullStory from '@fullstory/browser';

// TODO: remove unwanted modules from this application
// TODO: remove unwanted logs from this application
// TODO: check application gives an error when there's no internet connection for a while

const { store, persistor } = configureStore();

FullStory.init({ orgId: '13DQ34' });

function trackApiResponse(response, ts) {
  if (response.config && response.config.url) {
    ReactGA.timing({
      category: 'Axios',
      variable: response.config.url,
      value: ts, // in milliseconds
      label: response.status,
    });
  }
}

axios.defaults.baseURL = BASE_URL;
axios.defaults.timeout = 30000;
axios.interceptors.request.use((request) => {
  request.ts = performance.now(); // to find the performance
  if (
    request.data &&
    request.headers['Content-Type'] === 'application/x-www-form-urlencoded'
  ) {
    request.data = qs.stringify(request.data);
  }
  return request;
});

axios.interceptors.response.use(
  (response) => {
    const ts = Number(performance.now() - response.config.ts);
    trackApiResponse(response, ts);
    return response;
  },
  async (error) => {
    const ts = Number(performance.now() - error.config.ts);
    trackApiResponse(error, ts);
    const {
      response: { status },
    } = error;

    if (status === 401) {
      persistor.purge();
      store.dispatch(logOut());
    }

    return Promise.reject(error);
  }
);

class IndexApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: false,
      loading: false,
      launched: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    unregister();
  }

  async componentDidMount() {
    try {
      unregister();
      Sentry.init({
        dsn: 'https://9c73ca3b5b1f4bc0abdb12949dc3848f@sentry.io/1329032',
        beforeSend(event) {
          // Check if it is an exception, if so, show the report dialog
          if (event.exception) {
            Sentry.showReportDialog();
          }
          return event;
        },
      });
      ReactGA.initialize(GA_MEASUREMENT_ID, {
        debug: false,
        titleCase: false,
      });
      const { initalizeApp } = this.props;
      await initalizeApp();
      this.setState({ view: true });
      const auth_token = localStorage.getItem('auth_token');
      if (!auth_token) {
        persistor.purge();
      }
    } catch (error) {
      this.setState({ view: true });
    }
  }

  render() {
    const { view } = this.state;

    if (view) {
      return (
        <>
          <div>{this.props.children}</div>
          <ToastContainer />
        </>
      );
    } else {
      return null;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ initalizeApp, logOut }, dispatch);
};

const mapStateToProps = (state) => {
  return {};
};

let AppWrapper = connect(mapStateToProps, mapDispatchToProps)(IndexApp);

ReactDOM.render(
  <ExampleBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppWrapper>
          <AppRouting />
        </AppWrapper>
      </PersistGate>
    </Provider>
  </ExampleBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregister();
