import React from "react";
import "./content.scss";
import { Segment, Grid, Image } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllAlbums } from "../../store/actions";
import history from "../../history";

class Gallery extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      gallery: []
    };
  }
  async componentDidMount() {
    try {
      const { getAllAlbums } = this.props;
      await getAllAlbums();
      this.setState({
        gallery: this.props.albums
      });
    } catch (error) {
      console.log("TCL: Gallery -> componentDidMount -> error", error)
    }
  }
  
  render() {
    const { gallery } = this.state;
    const { getAlbumsLoading } = this.props;
    return (
      <Segment loading={getAlbumsLoading}>
        <Grid stackable columns={3}>
          {gallery.map((item, i) => {
            return (
              <Grid.Column key={i}>
                <Image
                  onClick={() => {
                    history.push(`/gallery/${item.id}`);
                  }}
                  className="gallery-image-cover"
                  style={{ cursor: "pointer" }}
                  src={
                    item.thumbnail
                      ? item.thumbnail
                      : require("../../assets/image-placeholder.png")
                  }
                />
                <div
                  className="album-name"
                  onClick={() => {
                    history.push(`/gallery/${item.id}`);
                  }}
                >
                  {item.title ? item.title : " "}
                </div>
              </Grid.Column>
            );
          })}
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  const { content } = state;
  return {
    albums: content.albums,
    albumPages: content.albumPages,
    albumCount: content.albumCount,
    getAlbumsLoading: content.getAlbumsLoading
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllAlbums
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Gallery);
