import React from "react";
import { Footer } from "../../components";
import "./content.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, Icon, Image, Segment } from "semantic-ui-react";
import moment from "moment";
import history from "../../history";
import { getNewsById } from "../../store/actions";
import Header from '../homePage/header'

class NewsArticle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { getNewsById, match } = this.props;
    getNewsById(parseInt(match.params.newsId, 10));
  }
  render() {
    const { post, getPostByIdLoading } = this.props;
    return (
      <div className="news-div">
        <Header path="media"/>
        <div id="newsArticle" className="news-content-article">
          <Grid padded>
            <Segment loading={getPostByIdLoading}>
              <Grid.Row>
                <div
                  className="news-title"
                  onClick={() => history.push("/media/0")}
                >
                  <Icon name="arrow left" /> {post ? post.title : "no title"}
                </div>
              </Grid.Row>
              <Grid.Row>
                <div className="date-div">
                  {moment(post ? post.createdAt : "-").format("DD MMMM YYYY")}
                </div>
              </Grid.Row>
              <Grid.Row className="img-row">
                <Image src={post ? post.image : null} />
              </Grid.Row>
              <Grid.Row
                className="content"
                dangerouslySetInnerHTML={{
                  __html: post ? post.content : "-"
                }}
              ></Grid.Row>
            </Segment>
          </Grid>
        </div>

        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { content } = state;
  return {
    ...content
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getNewsById }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsArticle);
