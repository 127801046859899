import React from 'react';
import { Grid, Header, Image } from 'semantic-ui-react';
import './about.scss';

export class About extends React.PureComponent {
  render() {
    return (
      <Grid padded className="about-div">
        <Grid.Row>
          <Grid.Column computer={10} mobile={16}>
            <Header as="h1">ABOUT ROYAL COLLEGE <br></br>
            OLD BOYS IN AUSTRALIA
            </Header>
            <p>
              Welcome to the re-vamped Web site of RCOBAWA. The primary role of
              the web site in the past has been to communicate news and
              information about the Association to its membership, to other
              Royalists around the globe as well as to other interested parties.
              <br></br>
              <br></br>
              We realise the value of social media and intend to enhance the new
              site not only to offer the traditional web media capabilities but
              to harness the power of social media, offering multifaceted
              communications not only connecting the Association to its members
              but also connecting Royalists to Royalists.
            </p>
          </Grid.Column>
          <Grid.Column computer={6} mobile={16}>
            <Image src={require('../../assets/about.jpg')} size="large" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
