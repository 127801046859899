/* eslint-disable eqeqeq */
import React from "react";
import "./report.js";
import { CustomTable, UploadCSV } from "../../components";
import { Checkbox, Grid, Button } from "semantic-ui-react";
import moment from "moment";
import {
  reconcileTransactions,
  uploadBankStatement
} from "../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

class Reconciliation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      reconciliationColumns: [
        {
          title: "",
          render: data => {
            return (
              <div>
                <Checkbox
                  onChange={(event, d) =>
                    this.checkboxChange(event, d, data, "bank")
                  }
                />
              </div>
            );
          }
        },
        {
          title: "Date",
          render: data => {
            return (
              <div>
                {data.date ? moment(data.date).format("DD MMMM YYYY") : "-"}
              </div>
            );
          }
        },
        {
          title: "Description",
          width: 7,
          render: data => {
            return <div>{data.description ? data.description : "-"}</div>;
          }
        },
        {
          title: "Amount",
          render: data => {
            return <div>{data.income ? data.income : "-"}</div>;
          }
        }
      ],
      systemColumns: [
        {
          title: "",
          render: data => {
            return (
              <div>
                <Checkbox
                  onChange={(event, d) =>
                    this.checkboxChange(event, d, data, "sys")
                  }
                />
              </div>
            );
          }
        },
        {
          title: "Date",
          render: data => {
            return (
              <div>
                {data.receivedDate
                  ? moment(data.receivedDate).format("DD MMMM YYYY")
                  : "-"}
              </div>
            );
          }
        },
        {
          title: "Payment Type",
          render: data => {
            return <div>{data.paymentType ? data.paymentType : "-"}</div>;
          }
        },
        {
          title: "Reference",
          render: data => {
            return <div>{data.reference ? data.reference : "-"}</div>;
          }
        },
        {
          title: "Amount",
          render: data => {
            return <div>{data.amount ? data.amount : "-"}</div>;
          }
        }
      ],
      reconciliationColumnsData: [],
      systemColumnsData: [],
      reconciledList: [],
      outstandingAmount: 0,
      unconfirmedBalance: 0,
      csvRecords: []
    };
  }

  reportObj(obj) {
    const { size } = this.props;
    this.setState({
      reportObj: obj,
      reconciliationColumnsData: obj.csv,
      systemColumnsData: obj.income
      // outstandingAmount: obj.outstandingAmount,
      // unconfirmedBalance: obj.unconfirmedBalance
    });
    size("large");
  }

  checkboxChange(event, d, data, type) {
    let {
      reconciledList,
      unconfirmedBalance,
      outstandingAmount,
      csvRecords
    } = this.state;
    if (type === "sys") {
      if (d.checked) {
        unconfirmedBalance = unconfirmedBalance + data.amount;
        reconciledList.push(data.id);
      } else {
        unconfirmedBalance = unconfirmedBalance - data.amount;
        reconciledList = _.remove(reconciledList, n => {
          return n !== data.id;
        });
      }
    }
    if (type === "bank") {
      if (d.checked) {
        outstandingAmount = outstandingAmount + data.income;
        csvRecords.push({
          date: data.date,
          description: data.description,
          income: data.income
        });
      } else {
        outstandingAmount = outstandingAmount - data.income;
        csvRecords = _.remove(csvRecords, obj => {
          return obj.date !== data.date;
        });
      }
    }
    this.setState({
      reconciledList: reconciledList,
      unconfirmedBalance,
      outstandingAmount,
      csvRecords
    });
  }

  async sendVerification() {
    const {
      reconcileTransactions,
      toggle,
      startDate,
      endDate,
      filter
    } = this.props;
    const { reconciledList, csvRecords } = this.state;
    const userId = localStorage.getItem("user_id");
    await reconcileTransactions({
      transactionRecords: reconciledList,
      csvRecords: csvRecords,
      userId: userId,
      startDate,
      endDate,
      filter
    });
    toggle();
  }

  render() {
    const {
      reconciliationColumnsData,
      systemColumns,
      reconciliationColumns,
      reportObj,
      systemColumnsData,
      outstandingAmount,
      unconfirmedBalance
    } = this.state;
    const { reconcileLoading, startDate, endDate } = this.props;

    return (
      <>
        {reportObj ? (
          <div className="report-modal-content">
            <div className="modal-header-div">Bank Reconciliation</div>
            <div>Bank transaction</div>
            <div className="table-div">
              <CustomTable
                header
                columns={reconciliationColumns}
                data={reconciliationColumnsData}
              />
            </div>
            <Grid className="balance-grid">
              <Grid.Row>
                <Grid.Column width={12}>
                  <div className="whole-balance-div">
                    <div className="balance-div">
                      <label>Outstanding Balance</label>
                      <p>${outstandingAmount}</p>
                    </div>
                    <div className="balance-div">
                      <label>Unconfirmed Payment </label>
                      <p>${unconfirmedBalance}</p>
                    </div>
                    <div className="balance-div">
                      <label>Difference</label>
                      <p className="diff-label">
                        $
                        {Math.abs(
                          outstandingAmount - unconfirmedBalance
                        ).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Button
                    onClick={() => this.sendVerification()}
                    loading={reconcileLoading}
                    disabled={
                      Math.abs(outstandingAmount - unconfirmedBalance).toFixed(
                        2
                      ) == 0.0
                        ? false
                        : true
                    }
                  >
                    Verify
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div>Find & select matching transactions</div>
            <div className="table-div">
              <CustomTable
                header
                columns={systemColumns}
                data={systemColumnsData}
              />
            </div>
          </div>
        ) : (
          <UploadCSV
            {...this.props}
            startDate={startDate}
            endDate={endDate}
            type="report"
            reportObj={obj => this.reportObj(obj)}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = state => {
  const { report } = state;
  return {
    ...report
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reconcileTransactions,
      uploadBankStatement
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Reconciliation);
