import React from "react";
import "./report.scss";
import { CustomTable } from "../../components";
import ButtonControls from "../layout/buttonControls";
import { getReport, uploadBankStatement, exportReportCsv } from "../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReportCard from "./reportCard";
import { Grid, Dropdown, Icon } from "semantic-ui-react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import _ from "lodash";
import Reconciliation from "./reconciliation";
import { limitString } from "../../utils";

class Report extends React.PureComponent {
  constructor(props) {
    super(props);
    this.cycleStart = moment().month('June').date(30);
    this.state = {
      columns: [
        {
          title: "PAYEE NAME",
          render: data => {
            const firstName = _.get(data, 'User.firstName', '-');
            const lastName = _.get(data, 'User.lastName', null);
            return (
              <div>
                {!_.isEmpty(data.User)
                  ? `${firstName} ${lastName ? lastName : ''}`
                  : "-"}
              </div>
            );
          }
        },
        {
          title: "AMOUNT",
          render: data => {
            return <div>{data.amount ? (data.amount > 0) ? data.amount : 'Free' : "-"}</div>;
          }
        },
        {
          title: "TYPE",
          render: data => {
            return <div>{data.paymentType ? data.paymentType : "-"}</div>;
          }
        },
        {
          title: "PAYMENT",
          render: data => {
            const text = _.get(data, 'event', _.get(data, 'membershipType', null));
            return <div>{text ? limitString(text, 20) : '-'}</div>;
          }
        },
        {
          title: "PAYMENT DATE",
          render: data => {
            return (
              <div>
                {data.receivedDate
                  ? moment(data.receivedDate).format("DD MMMM YYYY")
                  : "-"}
              </div>
            );
          }
        },
        {
          title: "REFERENCE #",
          render: data => {
            return <div>{data.reference ? data.reference : "-"}</div>;
          }
        },
        {
          title: "REVIEWED",
          render: data => {
            return (
              <div>
                {data.matched ? (
                  <Icon size="large" color="green" name="check circle" />
                ) : (
                  "-"
                )}
              </div>
            );
          }
        }
      ],
      startDate: (moment().diff(this.cycleStart) > 0)? this.cycleStart : this.cycleStart.subtract(1, 'years'),
      endDate: moment(),
      size: 'small',
      page: 1
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { getReport } = this.props;
    this.setState(
      () =>
        getReport({
          startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
          filter: 'all',
          paymentType: 'all'
        }),
      () => {
        this.setState({
          report: this.props.report,
          filter: 'all',
          paymentType: 'all'
        });
      }
    );
  }

  onSelect = dates => this.setState({ dates });

  datePicker = async (event, picker) => {
    try {
      const { getReport } = this.props;
      await getReport({
        startDate: moment(picker.startDate).format("YYYY-MM-DD"),
        endDate: moment(picker.endDate).format("YYYY-MM-DD"),
        filter: this.state.filter,
        paymentType: this.state.paymentType,
        offset: 1
      });
      this.setState({
        startDate: picker.startDate,
        endDate: picker.endDate,
        page: 1
      });
    } catch (error) {
      console.log("TCL: Report -> datePicker -> error", error);
    }
  };

  onOpen(modal, open) {
    this.setState({
      [modal]: open,
      size: 'small'
    });
  }

  changeSize(size) {
    this.setState({
      size
    })
  }

  dropDownChange(event, data) {
    const { getReport } = this.props;
    const { startDate, endDate, paymentType } = this.state
    this.setState({
      filter: data.value
    }, () => {
      getReport({
        startDate,
        endDate,
        filter: data.value,
        paymentType,
        offset: 1
      });
      this.setState({
        page: 1
      });
    });
  }

  dropDownTypeChange(event, data) {
    const { getReport } = this.props;
    const { startDate, endDate, filter } = this.state
    this.setState({
      paymentType: data.value
    }, () => {
      getReport({
        startDate,
        endDate,
        filter,
        paymentType: data.value,
        offset: 1
      });
      this.setState({
        page: 1
      });
    });
  }

  async handlePaginationChange(activePage) {
    try {
      const { getReport } = this.props;
      const { startDate, endDate, filter, paymentType } = this.state
      await getReport({
        startDate,
        endDate,
        filter,
        paymentType,
        offset: activePage
      });
      await this.setState({
        page: activePage
      });
    } catch (error) {
      console.log(">:Members-> handlePaginationChange -> error", error);
    }
  }

  async onExportButtonClick() {
    const { exportReportCsv } = this.props;
    const { startDate, endDate } = this.state
    await exportReportCsv({
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD")
    });
    window.open(this.props.reportCsv.Location)
  }

  render() {
    const { startDate, endDate, size, filter, page } = this.state;
    const { report, reportLoading } = this.props;
    return (
      <>
        {!_.isEmpty(report) ? (
          <>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onApply={(event, picker) => this.datePicker(event, picker)}
                    ranges={{
                      Today: [moment().startOf("day"), moment().endOf("day")],
                      Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days")
                      ],
                      "Last 7 Days": [moment().subtract(6, "days"), moment()],
                      "Last 30 Days": [moment().subtract(29, "days"), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month")
                      ],
                      "Last Month": [
                        moment()
                          .subtract(1, "month")
                          .startOf("month"),
                        moment()
                          .subtract(1, "month")
                          .endOf("month")
                      ],
                      "Last Quarter": [moment().subtract(3, "month"), moment()]
                    }}
                  >
                    <label>Show For:</label>{" "}
                    <Dropdown
                      text={`${startDate.format(
                        "MMMM DD, YYYY"
                      )} - ${endDate.format("MMMM DD, YYYY")}`}
                    ></Dropdown>
                  </DateRangePicker>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid columns="3">
              <Grid.Row>
                <Grid.Column>
                  <ReportCard
                    count={report[1].pageViews}
                    percentage={report[1].percentage}
                    metric={"pageViews"}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ReportCard
                    count={report[0].userCount}
                    percentage={report[0].percentage}
                    metric={"userCount"}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ReportCard
                    count={report[2].totalIncome[0].total}
                    percentage={"Through sales and memberships"}
                    metric={"totalIncome"}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <ButtonControls
              loading={reportLoading}
              controlObj={{
                btnArr: [
                  {
                    icon: "file text",
                    name: "EXPORT CSV",
                    className: "first-button",
                    onClick: () => this.onExportButtonClick()
                  },
                  {
                    icon: "upload",
                    name: "UPLOAD BANK STATEMENT",
                    className: "second-button",
                    size: size,
                    onOpen: () => this.onOpen("openUploadModal", true),
                    onClose: () => this.onOpen("openUploadModal", false),
                    open: this.state.openUploadModal,
                    modalContent: (
                      <Reconciliation
                        {...this.props}
                        startDate={startDate}
                        endDate={endDate}
                        filter={filter}
                        toggle={() => this.onOpen("openUploadModal", false)}
                        size={(val) => this.changeSize(val)}
                      />
                    )
                  }
                ],
                filter: [
                  {
                    defaultValue: 'all',
                    items: [
                      { text: "All", value: "all" },
                      { text: "Reviewed", value: "true" },
                      { text: "Unmatched", value: "false" },
                    ],
                    dropDownChange: (event, data) => this.dropDownChange(event, data)
                  },
                  {
                    defaultValue: 'all',
                    label: "Type: ",
                    items: [
                      { text: "All", value: "all" },
                      { text: "Membership", value: 'membership' },
                      { text: "Event", value: 'event' },
                      { text: "Sponsorship", value: 'sponsorship' }
                    ],
                    dropDownChange: (event, data) =>
                      this.dropDownTypeChange(event, data, "type")
                  }
                ]
              }}
            >
              <CustomTable
                header
                columns={this.state.columns}
                data={report[4].docs}
                pagination
                noOfPages={report[4].pages}
                page={page}
                handlePaginationChange={activePage =>
                  this.handlePaginationChange(activePage)
                }
              />
            </ButtonControls>
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { report } = state;
  return {
    ...report
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getReport,
      uploadBankStatement,
      exportReportCsv
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
