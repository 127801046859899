import React from "react";
import "./pageNotFound.scss";

export class PageNotFound extends React.PureComponent {
  render() {
    return (
      
            <div className="div-error">
              <h1 className="header">404</h1>
              <h3 className="looks-like-you"> LOOKS LIKE YOU'RE LOST</h3>
              <h5 className="the-page-you">
                The page you are looking for is not available!
              </h5>
              <div className="go-to">
                <p><a href="/">GO TO HOME</a></p>
              </div>
            </div>
         
    );
  }
}
