import React from 'react';
import './layout.scss';
import {
  Sidebar,
  Segment,
  Menu,
  Icon,
  Image,
  Container,
  Grid,
  Dropdown,
  Button
} from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import history from '../../history';
import { logOut, getUser, getMember, getAllAlbums, getEventByEventId } from '../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

// TODO: transfer this to constant.js and import from there
const sideBarMenu = [
  {
    id: 'members',
    name: 'Membership',
    icon: 'users',
    url: '/admin/manageMembers'
  },
  {
    id: 'shop',
    name: 'Shop',
    icon: 'shopping cart',
    url: '/admin/shop'
  },
  {
    id: 'content',
    name: 'Content',
    icon: 'images',
    url: '/admin/content/Magazines'
  },
  {
    id: 'reports',
    name: 'Ledger',
    icon: 'chart bar',
    url: '/admin/report'
  },
  {
    id: 'events',
    name: 'Attendance',
    icon: 'calendar alternate',
    url: '/admin/events'
  }
];

class Layout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      activeItem: "members",
      user: {},
      path: props.match.path
    };
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    try {
      const {
        getUser,
        getMember,
        pageTitle,
        albums,
        match,
        getEventByEventId
      } = this.props;
      let navTitle;
      await getUser();

      if (pageTitle) {
        navTitle = pageTitle;
      } else if (match.path === "/admin/manageMembers/:memberId") {
        await getMember(match.params.memberId);
        navTitle = (
          <div className="nav-title">
            {this.props.member.firstName +
              " " +
              this.props.member.lastName +
              " | "}
            <span>Member Profile</span>
          </div>
        );
      } else if (match.path === "/admin/content/album/:id") {
        const currentAlbum = _.find(
          albums,
          o => o.id === parseInt(match.params.id, 10)
        );
        navTitle = currentAlbum.title;
      } else if (match.path === "/admin/manageMembers/payment/:userId") {
        await getMember(match.params.userId);
        navTitle =
        <div className="nav-title">
        {this.props.member.firstName +
          " " +
          this.props.member.lastName +
          " | "}
        <span>View All Payments</span>
      </div>
      } else if (match.path === "/admin/manageAttendance/:eventId") {
        await getEventByEventId(match.params.eventId);
        navTitle = (
          <div className="nav-title">
            {this.props.event.title + " | "}
            <span>Manage Attendance</span>
          </div>
        );
      } else if (match.path === "/admin/shop/:shopId") {
        await getEventByEventId(match.params.shopId);
        navTitle = (
          <div className="nav-title">
            {this.props.event.title + " | "}
            <span>Ticket Purchases</span>
          </div>
        );
      }else if(match.path === '/admin/manageMembers/attendance/:memberId'){
        await getMember(match.params.memberId);
        navTitle =
        <div className="nav-title">
        {this.props.member.firstName +
          " " +
          this.props.member.lastName +
          " | "}
        <span>View All Attendance</span>
      </div>
      }
      this.setState({
        user: this.props.user,
        path: match.url,
        navTitle
      });
    } catch (error) {
      console.log(">: Layout -> componentDidMount -> error", error);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.path !== this.props.match.path) {
      this.init();
      this.setState({
        path: this.props.match.path
      });
    }
  }

  handleMenuItemClick(item) {
    this.setState({ visible: false }, () => history.push(`${item.url}`));
  }

  renderSideBar() {
    return (
      <>
        <Menu.Item>
          <div className="logo-div">
            <Image
              onClick={() => history.push("/")}
              size="small"
              src={require("../../assets/rcobawaLogo.png")}
            />
          </div>
        </Menu.Item>
        {sideBarMenu.map((item, i) => {
          return (
            <Menu.Item
              key={i}
              className="menu-item"
              as="a"
              active={this.state.path === item.url}
              onClick={() => this.handleMenuItemClick(item)}
            >
              <Icon name={item.icon} />
              {item.name}
            </Menu.Item>
          );
        })}
      </>
    );
  }

  handleShowClick = () => {
    this.setState({ visible: !this.state.visible });
  };

  handleSidebarHide = () => this.setState({ visible: false });

  renderTopNavBar() {
    const { user, navTitle } = this.state;
    const { logOut } = this.props;
    return (
      <Grid padded>
        <Grid.Column width={10}>
          <Container textAlign="left">
            <>
              {isMobile ? (
                <Button
                  onClick={() => this.handleShowClick()}
                  icon="bars"
                  size="massive"
                />
              ) : null}
              {navTitle ? navTitle : null}
            </>
          </Container>
        </Grid.Column>
        <Grid.Column width={6}>
          <Container>
            <div className="profile-div">
              {/* <Image
                src={require('../../assets/placeholder-user.png')}
                size={isMobile ? 'mini' : 'tiny'}
              /> */}
              <div className="profile-name">
                <Dropdown
                  text={
                    isMobile
                      ? null
                      : _.isEmpty(user)
                      ? "user"
                      : user.firstName + " " + user.lastName
                  }
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      text="Log out"
                      onClick={() => {
                        logOut();
                      }}
                    />
                  </Dropdown.Menu>
                </Dropdown>
                {isMobile ? null : (
                  <h6>{user.userType === 1 ? "admin" : "user"}</h6>
                )}
              </div>
            </div>
          </Container>
        </Grid.Column>
      </Grid>
    );
  }
  render() {
    const { children } = this.props;
    const { visible } = this.state;
    return (
      <Segment className="layout-segment">
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          inverted
          vertical
          visible={isMobile ? visible : true}
          width="wide"
          onHide={this.handleSidebarHide}
        >
          {this.renderSideBar()}
        </Sidebar>
        <div>
          <nav
            className={
              isMobile ? "navbar header-nav-mobile" : "navbar header-nav"
            }
          >
            {this.renderTopNavBar()}
          </nav>
          <Segment
            basic
            className={isMobile ? "layout-content-mobile" : "layout-content"}
          >
            {children}
          </Segment>
        </div>
        {/* </Sidebar.Pushable> */}
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  const { auth, members, content, items } = state;
  return {
    user: auth.user,
    ...members,
    ...content,
    ...items
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logOut,
      getUser,
      getMember,
      getAllAlbums,
      getEventByEventId
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
