import React from 'react';
import './members.scss';
import { CustomTable, DeleteModal, UploadCSV } from '../../../components';
import Tabs from '../../layout/tabs';
import { Dropdown, Modal } from 'semantic-ui-react';
import {
  getAllMembers,
  getAllGroups,
  deleteMember,
  addMemberToGroup,
  getMembershipTypes,
  updateMember,
  getMember,
  register,
  sendPaymentReminder,
  forgotPassword,
  deleteGroup,
  deleteMemberFromGroup,
  welcomeEmail,
  uploadMemberCsv,
  exportMembers,
  exportGroups
} from '../../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import _ from 'lodash';
import { MemberForm, MemberEdit } from './memberForm';
import Comment from './comment';
import ButtonControls from '../../layout/buttonControls';
import AddGroup from './addGroup';
import history from '../../../history';
import UpdatePayment from '../../payment/updatePayment';
import { toast } from 'react-toastify';

class Members extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'MEMBER NAME',
          render: data => {
            return (
              <div>
                <span
                  className="view-member"
                  onClick={() => this.viewMember(data.id)}
                >
                  {data.firstName || data.lastName
                    ? data.firstName + ' ' + data.lastName
                    : '-'}
                </span>
              </div>
            );
          }
        },
        {
          title: 'MEMBER TYPE',
          render: data => {
            return (
              <div>
                {_.get(data, 'MembershipType.title', '-')}
                {data.userType === 1 && (' (Admin)')}
              </div>
            );
          }
        },
        {
          title: 'DATE JOINED',
          render: data => {
            return (
              <div>
                {_.isEmpty(data.Payments)
                  ? '-'
                  : data.Payments[0].MembershipPayments[0]
                  ? moment(
                      data.Payments[0].MembershipPayments[0].joinDate
                    ).format('DD MMMM YYYY')
                  : '-'}
              </div>
            );
          }
        },
        {
          title: 'MEMBERSHIP EXPIRY',
          render: data => {
            return (
              <div>
                {data.userType === 1 ? '-' : _.isEmpty(data.Payments) ? '-'
                 : data.Payments[0].MembershipPayments[0] ? (
                  moment(
                    data.Payments[0].MembershipPayments[0].expireDate
                  ).format('DD MMMM YYYY')
                ) : '-'}
              </div>
            );
          }
        },
        {
          title: 'STATUS',
          render: data => {
            return <div>{data.active ? data.expired?'Expired':'Active' : 'Inactive'}</div>;
          }
        },
        {
          title: 'MANAGE OPTIONS',
          width: 3,
          render: data => {
            return (
              <div className="options-div">
                <Dropdown text="Select Action">
                  <Dropdown.Menu>
                    <MemberEdit
                      type="edit"
                      {...this.props}
                      editUser={data}
                      admin
                      page={this.state.page}
                    />
                    {data.userType !== 1 && (
                      <DeleteModal
                        trigger={<Dropdown.Item text="Remove Member" />}
                        item={data}
                        {...this.props}
                        type="removeMember"
                      />
                    )}
                    <Dropdown.Item>
                      <Dropdown text="Add to Group">
                        <Dropdown.Menu direction="left">
                          {this.renderGroupNames(data.id)}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Dropdown text="Send Email">
                        <Dropdown.Menu direction="left">
                          {!data.active && (
                            <Dropdown.Item
                              onClick={() => this.sendWelcomeEmail(data.id)}
                            >
                              Welcome email
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            onClick={() => this.sendPasswordReset(data.email)}
                          >
                            Password Reset
                          </Dropdown.Item>
                          {!_.isEmpty(data.Payments) ? (
                            data.Payments[0].MembershipPayments[0] ? (
                              data.Payments[0].MembershipPayments[0]
                                .MembershipType.id === 4 ? null : (
                                <Dropdown.Item
                                  onClick={() =>
                                    this.sendPaymentReminder(data.id)
                                  }
                                >
                                  Payment Reminder
                                </Dropdown.Item>
                              )
                            ) : (
                              <Dropdown.Item
                                onClick={() =>
                                  this.sendPaymentReminder(data.id)
                                }
                              >
                                Payment Reminder
                              </Dropdown.Item>
                            )
                          ) : data.active ? (
                            <Dropdown.Item
                              onClick={() => this.sendPaymentReminder(data.id)}
                            >
                              Payment Reminder
                            </Dropdown.Item>
                          ) : null}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        history.push(`/admin/manageMembers/payment/${data.id}`)
                      }
                      text="View All Payments"
                    />                    
                      <UpdatePayment userId={data.id} {...this.props} />
                    <Modal
                      size="tiny"
                      centered
                      trigger={<Dropdown.Item text="Add Comment" />}
                      open={this.state.commentModal}
                    >
                      <Modal.Content>
                        <Comment
                          userId={data.id}
                          modal={open => this.onOpen('commentModal', open)}
                        />
                      </Modal.Content>
                    </Modal>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          }
        }
      ],
      groupColumns: [
        {
          title: 'GROUP NAME',
          render: data => {
            return <div>{data.name ? data.name : '-'}</div>;
          }
        },
        {
          title: ' ',
          render: data => {
            return (
              <div>
                <p>MEMBERS</p>
                {_.isEmpty(data.Users)
                  ? '-'
                  : data.Users.map((item, i) => {
                      return (
                        <p key={i}>
                          {item.firstName || item.lastName
                            ? item.firstName + ' ' + item.lastName
                            : '-'}
                        </p>
                      );
                    })}
              </div>
            );
          }
        },
        {
          title: 'OPTIONS',
          render: data => {
            return (
              <div>
                <p className="add-delete">
                  <AddGroup item={data} {...this.props} />
                  {data.name === 'Admins' ? null : (
                    <DeleteModal
                      trigger={<b className="delete">Delete Group</b>}
                      item={data}
                      {...this.props}
                      type="deleteGroup"
                    />
                  )}
                </p>

                {_.isEmpty(data.Users)
                  ? null
                  : data.Users.map((item, i) => {
                      const userId = localStorage.getItem('user_id');
                      if (parseInt(userId, 10) === item.id) {
                        return (
                          <p key={i}>
                            <b className="delete">
                              <br />
                            </b>
                          </p>
                        );
                      } else {
                        return (
                          <p key={i} className="add-delete">
                            <DeleteModal
                              trigger={
                                <b className="remove">Remove from Group</b>
                              }
                              item={data}
                              user={item}
                              {...this.props}
                              type="deleteMemberFromGroup"
                            />
                          </p>
                        );
                      }
                    })}
              </div>
            );
          }
        }
      ],

      groupData: [],
      loading: false,
      page: 1,
      open: false,
      view: false,
      add: false,
      delete: false,
      deleteGroup: false,
      removeGroup: false
    };
    this.renderMembershipTypes = this.renderMembershipTypes.bind(this);
  } //end of constructor

  async componentDidMount() {
    try {
      window.scrollTo(0, 0);
      const { getAllMembers, getAllGroups, getMembershipTypes } = this.props;

      const { page } = this.state;
      await getAllMembers(page);
      await getAllGroups();
      await getMembershipTypes();
      this.setState({
        loading: this.props.getMembersLoading,
        groupData: this.props.allGroups
      });
    } catch (error) {
      console.log('>: Members -> componentDidMount -> error', error);
    }
  }
  componentDidUpdate(prevProps) {
    // if (this.props.count !== prevProps.count) {
    //   console.log('here============');

    //   const { getAllMembers } = this.props;
    //   const { page } = this.state;
    //   getAllMembers(page);
    //   this.setState({
    //     loading: this.props.getMembersLoading
    //   });
    // }
    if (!_.isEqual(this.props.allGroups, prevProps.allGroups)) {
      this.setState({
        groupData: this.props.allGroups
      });
    }
  }

  sendPasswordReset(email) {
    const { forgotPassword } = this.props;
    forgotPassword({ email: email });
  }

  sendWelcomeEmail(userId) {
    const { welcomeEmail } = this.props;
    welcomeEmail({ userId: userId });
  }

  viewMember(memberId) {
    history.push(`/admin/manageMembers/${memberId}`);
  }
  async sendPaymentReminder(userId) {
    try {
      const { sendPaymentReminder } = this.props;
      await sendPaymentReminder({ userId: userId });
    } catch (error) {
      console.log('TCL: sendPaymentReminder -> error', error);
    }
  }

  onOpen(type, open) {
    this.setState({
      [type]: open
    });
  }

  async deleteItem(userId) {
    try {
      const { deleteMember, getAllMembers } = this.props;
      await deleteMember(userId);
      this.onOpen('delete', false);
      await getAllMembers(this.state.page);
    } catch (error) {
      console.log('>: deleteItem -> error', error);
    }
  }

  async handlePaginationChange(activePage) {
    try {
      const { getAllMembers } = this.props;
      const { viewBy, membershipTypeFilter, searchValue } = this.state;
      await getAllMembers(
        activePage,
        viewBy,
        membershipTypeFilter,
        searchValue
      );
      await this.setState({
        page: activePage
      });
    } catch (error) {
      console.log('>:Members-> handlePaginationChange -> error', error);
    }
  }

  renderGroupNames(memberId) {
    const { allGroups } = this.props;
    return allGroups.map((item, i) => {
      return (
        <Dropdown.Item
          key={i}
          onClick={() => this.addToGroup(memberId, item.id)}
        >
          {item ? item.name : '-'}
        </Dropdown.Item>
      );
    });
  }
  renderMembershipTypes() {
    const { membership } = this.props;
    return (
      <>
        <option></option>
        {membership.map((item, i) => {
          return (
            <option key={i} value={item.id}>
              {item.title}
            </option>
          );
        })}
      </>
    );
  }

  addToGroup(memberId, groupId) {
    const { addMemberToGroup } = this.props;
    const groupMembers = [];
    groupMembers.push(memberId);
    addMemberToGroup({ groupMembers: groupMembers, groupId: groupId });
  }

  async viewByChange(event, data) {
    try {
      const { getAllMembers } = this.props;
      const { page, searchValue, membershipTypeFilter } = this.state;
      if (data.value === 'allMembers') {
        this.setState({
          viewBy: null,
          page: 1
        });
        await getAllMembers(page, null, membershipTypeFilter, searchValue);
      } else {
        this.setState({
          viewBy: data.value,
          page: 1
        });
        await getAllMembers(1, data.value, membershipTypeFilter, searchValue);
      }
    } catch (error) {
      console.log('TCL: viewByChange -> error', error);
    }
  }

  async viewByMembershipType(event, data) {
    try {
      const { getAllMembers } = this.props;
      const { page, viewBy, searchValue } = this.state;
      if (data.value === 'allTypes') {
        this.setState({
          membershipTypeFilter: null,
          page: 1
        });
        await getAllMembers(page, viewBy, null, searchValue);
      } else {
        this.setState({
          membershipTypeFilter: data.value,
          page: 1
        });
        await getAllMembers(page, viewBy, data.value, searchValue);
      }
    } catch (error) {
      console.log('TCL: viewByMembershipType -> error', error);
    }
  }

  handleSearchChange = async (e, { value }) => {
    const { getAllMembers } = this.props;
    let { viewBy, membershipTypeFilter } = this.state;

    await this.setState({ searchLoading: true, searchValue: value, page: 1 });
    await getAllMembers(1, viewBy, membershipTypeFilter, value);

    setTimeout(async () => {
      if (this.state.searchValue.length < 1) {
        return this.setState({ searchLoading: false, searchValue: '' });
      }

      const re = new RegExp(_.escapeRegExp(this.state.searchValue), 'i');
      const isMatch = result => {
        return re.test(result.title);
      };
      const memberNames = this.props.allMembers.map((item, i) => {
        return {
          key: i,
          title: item.firstName,
          price: item.lastName
        };
      });
      this.setState({
        searchLoading: false,
        searchResults: _.filter(memberNames, isMatch)
      });
    }, 500);
  };

  handleResultSelect = (e, { result }) => {
    this.setState({ searchValue: result.title });
    const { getAllMembers } = this.props;
    const { page, viewBy, membershipTypeFilter } = this.state;
    getAllMembers(page, viewBy, membershipTypeFilter, result.title);
  };

  async onExportButtonClick() {
    try {
      const { exportMembers } = this.props;
      await exportMembers();
      window.open(this.props.exportMemberCsv.Location);
    } catch (error) {
      toast.error('Could not download member csv');
    }
  }

  async onExportGroupButtonClick() {
    try {
      const { exportGroups } = this.props;
      await exportGroups();
      window.open(this.props.exportGroupCsv.Location);
    } catch (error) {
      toast.error('Could not download group csv');
    }
  }

  renderTabPanes() {
    const { membership } = this.props;
    const { searchLoading, searchResults, searchValue } = this.state;
    const membershipType = membership.map(item => {
      return {
        text: item.title,
        value: item.id
      };
    });
    membershipType.splice(0, 0, { text: 'All Types', value: 'allTypes' });
    return [
      {
        menuItem: 'Members',
        tabPane: (
          <ButtonControls
            viewByChange={(event, data) => this.viewByChange(event, data)}
            viewByMembershipType={(event, data) =>
              this.viewByMembershipType(event, data)
            }
            search={{
              items: [
                { text: 'All Members', value: 'allMembers' },
                { text: 'Recently Joined', value: 'recentlyJoined' },
                { text: 'Expiring Soon', value: 'expiringSoon' },
                { text: 'Deceased Status', value: 'deceasedStatus' },
                { text: 'Active Members', value: 'activeMembers' },
                { text: 'Inactive Members', value: 'inactiveMembers' },
                { text: 'Expired Members', value: 'expiredMembers' }
              ],
              memberTypes: membershipType,
              searchLoading: searchLoading,
              handleSearchChange: (e, data) => this.handleSearchChange(e, data),
              // handleResultSelect: (e, data) => this.handleResultSelect(e, data),
              results: searchResults,
              value: searchValue
            }}
            loading={this.props.getMembersLoading}
            controlObj={{
              btnArr: [
                {
                  loading: this.props.exportMemberCsvLoading,
                  icon: 'file text',
                  name: 'EXPORT CSV',
                  className: 'export-button',
                  onClick: () => this.onExportButtonClick()
                },
                {
                  icon: 'upload',
                  name: 'UPLOAD CSV',
                  className: 'first-button',
                  size: 'small',
                  onOpen: () => this.onOpen('openUploadModal', true),
                  onClose: () => this.onOpen('openUploadModal', false),
                  open: this.state.openUploadModal,
                  modalContent: (
                    <UploadCSV
                      trigger={open => this.onOpen('openUploadModal', open)}
                      {...this.props}
                      type="members"
                    />
                  )
                },
                {
                  icon: 'add circle',
                  name: 'ADD NEW MEMBER',
                  className: 'second-button',
                  onOpen: () => this.onOpen('add', true),
                  onClose: () => this.onOpen('add', false),
                  open: this.state.add,
                  modalContent: (
                    <MemberForm
                      admin
                      toggle={() => this.onOpen('add', false)}
                      trigger={open => this.onOpen('add', open)}
                      {...this.props}
                    />
                  )
                }
              ]
            }}
          >
            <CustomTable
              header
              columns={this.state.columns}
              data={this.props.allMembers}
              pagination
              noOfPages={this.props.pages}
              page={this.state.page}
              handlePaginationChange={activePage =>
                this.handlePaginationChange(activePage)
              }
            />
          </ButtonControls>
        )
      },
      {
        menuItem: 'Groups',
        tabPane: (
          <ButtonControls
            loading={this.props.getGroupsLoading}
            controlObj={{
              btnArr: [
                {
                  loading: this.props.exportGroupCsvLoading,
                  icon: 'file text',
                  name: 'EXPORT CSV',
                  className: 'export-button',
                  onClick: () => this.onExportGroupButtonClick()
                },
                {
                  icon: 'add circle',
                  name: 'CREATE GROUP',
                  className: 'second-button',
                  onOpen: () => this.onOpen('open', true),
                  onClose: () => this.onOpen('open', false),
                  open: this.state.open,
                  size: 'tiny',
                  modalContent: (
                    <AddGroup
                      trigger={open => this.onOpen('open', open)}
                      {...this.props}
                    />
                  )
                }
              ]
            }}
          >
            <CustomTable
              header
              columns={this.state.groupColumns}
              data={this.state.groupData}
              pagination
            />
          </ButtonControls>
        )
      }
    ];
  }

  render() {
    return <Tabs tabs panesArray={this.renderTabPanes()}></Tabs>;
  }
}

const mapStateToProps = state => {
  const { members, auth } = state;
  return {
    allMembers: members.allMembers,
    getMembersLoading: members.getMembersLoading,
    memberUpdateLoading: members.putMembersLoading,
    allGroups: members.allGroups,
    pages: members.pages,
    count: members.count,
    getGroupsLoading: members.getGroupsLoading,
    membership: members.membership,
    authRegisterLoading: auth.authRegisterLoading,
    csvUploadLoading: members.csvUploadLoading,
    csvUploadError: members.csvUploadError,
    exportMemberCsv: members.exportMemberCsv,
    exportMemberCsvLoading: members.exportMemberCsvLoading,
    exportGroupCsv: members.exportGroupCsv,
    exportGroupCsvLoading: members.exportGroupCsvLoading
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllMembers,
      getAllGroups,
      deleteMember,
      addMemberToGroup,
      getMembershipTypes,
      updateMember,
      getMember,
      register,
      sendPaymentReminder,
      forgotPassword,
      deleteGroup,
      deleteMemberFromGroup,
      welcomeEmail,
      uploadMemberCsv,
      exportMembers,
      exportGroups
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Members);
