import React from "react";
import { ssoLogin } from "../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import history from "../../history";
import qs from "qs";
import { REACT_APP_HIDE_LANDING_REDIRECT } from "../../constant";

class LoginSSO extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    try {
      this.setState({ errorMessage: null });
      const props = this.props;
      const { token } = qs.parse(props.location.search.replace("?", ""));
      if (!token) {
        this.setState({ errorMessage: "Looks like the url is broken" });
      } else {
        this.props.ssoLogin(token);
        
        this.handleRedirect();
      }
    } catch (error) {
      this.setState({ errorMessage: "Looks like the url is broken" });
    }
  }

  handleRedirect = async () => {
      this.setState({loading: true});
      // added fake timeout because updating the redux is async
      setTimeout(()=>{
      this.setState({loading: true});

        const userType = localStorage.getItem("user_type");
        switch (userType) {
          case "1":
            history.push("/admin/manageMembers");
            break;
          case "2":
            history.push("/events#events");
            break;
          default:
            break;
        }
      }, 500)
   
  };

  render() {
    const { loading, errorMessage } = this.state;

    return (
      <>
        <div style={{ padding: 100 }}>
          {loading ? "loading" : errorMessage}
          <br />
          <br />
          <a href={REACT_APP_HIDE_LANDING_REDIRECT}> Go to Home Page </a>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    authLoginLoading: auth.authLoginLoading,
    errorResponse: auth.errorResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ssoLogin,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginSSO);
