import React from 'react';
import { BackgroundPage } from '../../components';
import './register';
import { Grid, Button } from 'semantic-ui-react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { isMobile } from 'react-device-detect';
import { resetPassword } from '../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../../history';
import {Footer} from '../../components';

class ResetPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      token: props.match.params.token
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleValidSubmit(event, values) {
    values.token = this.state.token;
    this.setState(
      {
        password: values
      },
      async () => {
        try {
          const { resetPassword } = this.props;
          if(!this.props.resetPasswordLoading){
            await resetPassword(values);
          }
          history.push('/login');
        } catch (error) {
          console.log('>: ResetPassword -> handleValidSubmit -> error', error);
        }
      }
    );
  }

  render() {
    const { password } = this.state;
    const { resetPasswordLoading } = this.props;
    return (
      <>
      <BackgroundPage header={'RESET PASSWORD'} desc={'Enter a new password'}>
        <Grid stackable textAlign="left">
          <Grid.Row>
            <Grid.Column width={isMobile ? 3 : 5}></Grid.Column>
            <Grid.Column width={isMobile ? 10 : 6}>
              <AvForm
                onValidSubmit={this.handleValidSubmit}
                model={{ password }}
              >
                <AvField
                  name="password"
                  label="Password"
                  errorMessage="Password is required"
                  type="password"
                  required
                />
                <Grid.Row className="login-btn">
                  <Button loading={resetPasswordLoading}>RESET PASSWORD</Button>
                </Grid.Row>
              </AvForm>
            </Grid.Column>
            <Grid.Column width={isMobile ? 3 : 5}></Grid.Column>
          </Grid.Row>
        </Grid>
       
      </BackgroundPage>
       <Footer/>
       </>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    resetPasswordLoading: auth.resetPasswordLoading
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      resetPassword
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
