import React from 'react';
import './layout.scss';
import { Tab } from 'semantic-ui-react';
import ButtonControls from '../layout/buttonControls';

class Tabs extends React.PureComponent {
  render() {
    const { tabs, panesArray, buttons, controlObj } = this.props;
    return (
      <div>
        {tabs ? (
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={panesArray.map((item, i) => {
              return {
                menuItem: item.menuItem,
                render: () => (
                  <Tab.Pane key={i} className="tab-pane">
                    {buttons ? (
                      <ButtonControls controlObj={controlObj} />
                    ) : null}
                    {item.tabPane}
                  </Tab.Pane>
                )
              };
            })}
          />
        ) : null}
      </div>
    );
  }
}

export default Tabs;
