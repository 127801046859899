import React from 'react';
import './register';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button, Grid } from 'semantic-ui-react';
import { BackgroundPage, Footer } from '../../components';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { forgotPassword } from '../../store/actions';


class ForgotPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: {},
      loading: false
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleValidSubmit(event, values) {
    this.setState({ email: values }, async () => {
      try {
        const { forgotPassword } = this.props;
        await forgotPassword(values);
      } catch (error) {
        console.log('>: ForgotPassword -> handleValidSubmit -> error', error);
      }
    });
  }

  render() {
    const { email } = this.state;
    const { forgotPasswordLoading } = this.props;
    return (
      <>
      <BackgroundPage
        header={'FORGOT PASSWORD'}
        desc={'Get a password reset link'}
      >
        <Grid stackable textAlign="left">
          <Grid.Row>
            <Grid.Column width={isMobile ? 3 : 5}></Grid.Column>
            <Grid.Column width={isMobile ? 10 : 6}>
              <AvForm onValidSubmit={this.handleValidSubmit} model={email}>
                <AvField
                  name="email"
                  label="Email Address"
                  type="email"
                  errorMessage="Email address is required"
                  required
                />
                <Grid.Row className="login-btn">
                  <Button loading={forgotPasswordLoading}>
                    RESET PASSWORD
                  </Button>
                </Grid.Row>
              </AvForm>
            </Grid.Column>
            <Grid.Column width={isMobile ? 3 : 5}></Grid.Column>
          </Grid.Row>
        </Grid>
      </BackgroundPage>
      <Footer/>
      </>
    );
  }
}
const mapStateToProps = state => {
  const { auth } = state;
  return {
    forgotPasswordLoading: auth.forgotPasswordLoading
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      forgotPassword
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
