/* eslint-disable eqeqeq */
import React from 'react';
import { Icon } from 'semantic-ui-react';
import Autocomplete from 'react-google-autocomplete';
export class LocationSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async sendLocation(data) {
    // console.log("TCL: LocationSearch -> sendLocation -> data", data)
    const { getLocation } = this.props;
    if (data.address_components && data.address_components.length) {
      try {
        const [streetNumber, streetAddress, state, suburb, suburbExtra, postalCodes] = await Promise.all([
          data.address_components
          .filter(it => {
            return it.types.indexOf('street_number') !== -1;
          })
          .map(it => {
            return it.long_name;
          }),
          data.address_components
          .filter(it => {
            return it.types.indexOf('route') !== -1;
          })
          .map(it => {
            return it.long_name;
          }),
          data.address_components
          .filter(it => {
            return it.types.indexOf('administrative_area_level_1') !== -1;
          })
          .map(it => {
            return it.long_name;
          }),
          data.address_components
          .filter(it => {
            return it.types.indexOf('locality') !== -1;
          })
          .map(it => {
            return it.long_name;
          }),
          data.address_components
          .filter(it => {
            return it.types.indexOf('political') !== -1;
          })
          .map(it => {
            return it.long_name;
          }),
          data.address_components
          .filter(it => {
            return it.types.indexOf('postal_code') !== -1;
          })
          .map(it => {
            return it.long_name;
          })
        ]);

        // set location search data
        if (streetNumber[0]) {
          data.streetNumber = streetNumber[0];
        }
        if (streetAddress[0]) {
          data.streetAddress = streetAddress[0];
        }
        if (state[0]) {
          data.state = state[0];
        }
        if (suburb[0] || suburbExtra[0]) {
          data.suburb = suburb[0] || suburbExtra[0];
        }
        if (postalCodes[0]) {
          data.postcode = postalCodes[0];
        }
      } catch (error) {
        // console.log("TCL: LocationSearch -> sendLocation -> error", error) 
      }
    }

    getLocation(data);
  }

  handleKeyPress = event => {
    if (event.key == 'Enter') {
      event.preventDefault();
    }
  };

  render() {    
    if(!window.google) return null;
    
    return (
      <div>
        <label>Search Address</label>
        <div className="form-group input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <Icon name="location arrow" />
            </span>
          </div>
          <div className="form-group location-search">
            <Autocomplete
              onKeyPress={this.handleKeyPress}
              onPlaceSelected={data => {
                this.sendLocation(data);
              }}
              className="is-touched is-pristine av-invalid form-control"
              types={[]}
              componentRestrictions={{ country: 'AU' }}
            />
            <div className="invalid-feedback">Search Address is required</div>
          </div>
        </div>
      </div>
    );
  }
}
