import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, Button, Icon, Image, Modal, Dropdown } from "semantic-ui-react";
import ReactDragListView from 'react-drag-listview'
import _ from "lodash";
import S3 from "aws-s3";
import cryptoRandomString from "crypto-random-string";
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation";
import {
  createPostItem,
  createMagazineItem,
  createAlbumItem,
  updateMagazineItem,
  updatePostItem,
  addAlbumImage,
  updateAlbumItem,
  getAlbumImages
} from "../../store/actions";
import { AWSConfig } from "../../constant";
import "./content.scss";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";

const S3Client = new S3(AWSConfig);

class ContentForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      itemType: "post",
      eventImage: null,
      thumbnail: null,
      albumImages: props.isNew
        ? []
        : _.map(props.albumImages, item => {
          return item.src;
        }),
      thumbnailLoading: false,
      postContent: props.isNew ? "" : props.item.content
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      const { isNew, itemType, item, getAlbumImages } = this.props;
      if (!isNew) {
        await getAlbumImages("albumImages", item.id);
        this.setState({
          itemType,
          eventImage:
            itemType === "post"
              ? { location: item ? item.image : null }
              : { location: item ? item.file : null },
          thumbnail: { location: item ? item.thumbnail : null },
          albumImages: isNew
            ? []
            : _.map(this.props.albumImages, item => {
              return item.src;
            }),
          presidents: item.title === 'PAST PRESIDENTS' ? true : false
        });
      }
    } catch (error) {
      console.log("TCL: ContentForm -> componentDidMount -> error", error)
    }
  }

  onRadioChange(e) {
    e.persist();

    this.setState({
      itemType: e.target.value,
      eventImage: null,
      thumbnail: null,
      albumImages: []
    });
    // this.form && this.form.reset();
  }

  triggerInputFile = type => {
    if (type === "thumbnail") {
      this.thumbnailInput.click();
    } else {
      this.fileInput.click();
    }
  };

  onImageChange = async (event, type) => {
    try {
      const file = event.target.files;
      let data = {};
      const rdmString = cryptoRandomString({ length: 30, type: "url-safe" });

      if (file) {
        switch (type) {
          case "thumbnail":
            this.setState({ thumbnailLoading: true });
            data = await S3Client.uploadFile(file[0], rdmString);
            this.setState({ [type]: data, thumbnailLoading: false });
            break;

          case "albumImages":
            this.setState({ imageLoading: true }, async () => {
              try {
                const album = [];
                // eslint-disable-next-line no-unused-vars
                for (const item of file) {
                  const albumImageName = cryptoRandomString({
                    length: 30,
                    type: "url-safe"
                  });
                  data = await S3Client.uploadFile(item, albumImageName);
                  album.push(data.location);
                }
                this.setState({ [type]: album.concat(this.state.albumImages), imageLoading: false });
              } catch (error) {
                console.log("TCL: ContentForm -> onImageChange -> error", error)
              }
            });
            break;

          default:
            this.setState({ imageLoading: true });
            data = await S3Client.uploadFile(file[0], rdmString);
            this.setState({ [type]: data, imageLoading: false });
            break;
        }
      }
    } catch (error) {
      console.log("TCL: ContentForm -> onImageChange -> error", error);
    }
  };

  async handleValidSubmit(event, values) {
    try {
      const { postContent } = this.state;

      this.setState({ values });
      const {
        isNew,
        item,
        trigger,
        toggle,
        createPostItem,
        createMagazineItem,
        createAlbumItem,
        updateMagazineItem,
        updatePostItem,
        updateAlbumItem
      } = this.props;

      switch (this.props.itemType) {
        case "post":
          if (!postContent) {
            toast.warn("Content is required!");
          } else {
            const postForm = {
              title: values.postTitle,
              content: postContent,
              image: this.state.eventImage.location,
              tags: values.postTags.split(",")
            };
            if (isNew) {
              await createPostItem(postForm);
              trigger(false);
            } else {
              postForm.newsId = item.id;
              await updatePostItem(postForm);
              toggle();
            }
          }
          break;
        case "magazine":
          const magazineForm = {
            title: values.magazineTitle,
            ISBN: values.magazineISBN,
            file: this.state.eventImage.location,
            thumbnail: this.state.thumbnail.location,
            tags: values.magazineTags.split(",")
          };
          if (isNew) {
            await createMagazineItem(magazineForm);
            trigger(false);
          } else {
            magazineForm.magazineId = item.id;
            await updateMagazineItem(magazineForm);
            toggle();
          }
          break;
        case "gallery":
          const galleryForm = {
            title: values.galleryTitle,
            albumImages: this.state.albumImages
          };
          if (isNew) {
            await createAlbumItem(galleryForm);
            trigger(false);
          } else {

            //   var newList = this.state.albumImages.filter((word) => {
            //     return this.props.albumImages.includes(word);
            //  })
            await updateAlbumItem({ title: values.galleryTitle, albumId: item.id, albumImages: this.state.albumImages })
            // await addAlbumImage({
            //   albumId: item.id,
            //   albumImages: this.state.albumImages
            // })
            // galleryForm.albumId = item.id;
            // await createAlbumItem(galleryForm);
            toggle();
          }
          break;

        default:
          break;
      }
    } catch (error) {
      console.log("TCL: ContentForm -> handleValidSubmit -> error", error);
    }
  }

  onDragEnd(fromIndex, toIndex) {
    const { albumImages } = this.state;
    const data = [...albumImages];
    const item = data.splice(fromIndex, 1)[0];
    data.splice(toIndex, 0, item);
    this.setState({ albumImages: data });
  }

  renderContent() {
    const { eventImage, thumbnail, albumImages, modalKey, deleteIcon, presidents, postContent } = this.state;
    const { isNew } = this.props;

    switch (this.props.itemType) {
      case "post":
        return (
          <Grid>
            <Grid.Row>
              <AvField
                name="postTitle"
                label="Title"
                disabled={presidents}
                errorMessage="Title is required"
                validate={{
                  required: { value: true }
                }}
              />
            </Grid.Row>
            <Grid.Row>
              {/* <AvField
                name="postContent"
                label="Content"
                type="textarea"
                errorMessage="Content is required"
                validate={{
                  required: { value: true }
                }}
              /> */}
              <label>Content</label>
              <JoditEditor
                value={postContent}
                config={{
                  readonly: false,
                  height: 400,
                  width: 'inherit'
                }}
                onBlur={newContent => this.setState({ postContent: newContent })}  // preferred to use only this option to update the content for performance reasons
                onChange={newContent => {}}
              />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="item-upload-container">
                  <div>
                    {" "}
                    <label>
                      {isNew ? "Image" : "Update Image"}{" "}
                      <span className="item-upload-required">*</span>
                      {" "}<i>( image size should be in 1080 X 608 )</i>
                    </label>
                    <br />
                    <label
                      className="item-upload-button"
                      onClick={() => this.triggerInputFile()}
                    >
                      <Icon
                        name={this.state.imageLoading ? "refresh" : "upload"}
                        loading={this.state.imageLoading}
                      />
                      {this.state.imageLoading ? "Please Wait" : "Upload Image"}
                    </label>
                    <input
                      name="eventImage"
                      ref={fileInput => (this.fileInput = fileInput)}
                      type="file"
                      hidden={true}
                      accept=".jpg,.jpeg,.png,.webp"
                      onChange={e => this.onImageChange(e, "eventImage")}
                    />
                  </div>
                </div>
                {eventImage ? (
                  <div className="content-image">
                    <Icon />
                    <Image size="small" src={eventImage.location} bordered />
                  </div>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <AvField
                name="postTags"
                label="Tags"
                placeholder="Ex: Technologies, Event, Innovation"
              />
            </Grid.Row>
          </Grid>
        );
      case "magazine":
        return (
          <Grid>
            <Grid.Row>
              <AvField
                name="magazineTitle"
                label="Title"
                errorMessage="Title is required"
                validate={{
                  required: { value: true }
                }}
              />
            </Grid.Row>
            <Grid.Row>
              <AvField
                name="magazineISBN"
                label="ISBN"
              />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="item-upload-container">
                  <div>
                    {" "}
                    <label>
                      PDF File <span className="item-upload-required">*</span>
                    </label>
                    <br />
                    <label
                      className="item-upload-button"
                      onClick={() => this.triggerInputFile()}
                    >
                      <Icon
                        name={this.state.imageLoading ? "refresh" : "upload"}
                        loading={this.state.imageLoading}
                      />
                      {this.state.imageLoading
                        ? "Please Wait"
                        : "Upload PDF File"}
                    </label>
                    <input
                      name="eventImage"
                      ref={fileInput => (this.fileInput = fileInput)}
                      type="file"
                      hidden={true}
                      accept=".pdf"
                      onChange={e => this.onImageChange(e, "eventImage")}
                    />
                  </div>
                </div>
                {eventImage ? (
                  <div className="content-image">
                    <a
                      href={eventImage.location}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Uploaded Magazine File
                    </a>
                  </div>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="item-upload-container">
                  <div>
                    {" "}
                    <label>
                      Magazine Thumbnail{" "}
                      <span className="item-upload-required">*</span>
                      {"  "}<i>(Image resolution should be 200x300)</i>
                    </label>
                    <br />
                    <label
                      className="item-upload-button"
                      onClick={() => this.triggerInputFile("thumbnail")}
                    >
                      <Icon
                        name={
                          this.state.thumbnailLoading ? "refresh" : "upload"
                        }
                        loading={this.state.thumbnailLoading}
                      />
                      {this.state.thumbnailLoading
                        ? "Please Wait"
                        : "Upload Image"}
                    </label>
                    <input
                      name="thumbnailImage"
                      ref={thumbnailInput =>
                        (this.thumbnailInput = thumbnailInput)
                      }
                      type="file"
                      hidden={true}
                      accept=".jpg,.jpeg,.png,.webp"
                      onChange={e => this.onImageChange(e, "thumbnail")}
                    />
                  </div>
                </div>
                {thumbnail ? (
                  <div className="content-image">
                    <Image size="small" src={thumbnail.location} />
                  </div>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <AvField
                name="magazineTags"
                label="Tags"
                placeholder="Ex: Technologies, Political, Finance"
              />
            </Grid.Row>
          </Grid>
        );
      case "gallery":
        return (
          <Grid>
            <Grid.Row>
              <AvField
                name="galleryTitle"
                label="Album Title"
                errorMessage="Title is required"
                validate={{
                  required: { value: true }
                }}
              />
            </Grid.Row>
            {/* {this.props.isNew ? ( */}
            <Grid.Row>
              <div className="item-upload-container">
                <div>
                  {" "}
                  <label>
                    Images <span className="item-upload-required">*</span>{" "}
                    {"  "}<i>(Image resolution should be in 16:9 ratio)</i>
                    <br /><em>You can select multiple images</em>
                  </label>
                  <br />
                  <label
                    className="item-upload-button"
                    onClick={() => this.triggerInputFile()}
                  >
                    <Icon
                      name={this.state.imageLoading ? "refresh" : "upload"}
                      loading={this.state.imageLoading}
                    />
                    {this.state.imageLoading ? "Please Wait" : "Upload Images"}
                  </label>
                  <input
                    name="albumImages"
                    ref={fileInput => (this.fileInput = fileInput)}
                    type="file"
                    hidden={true}
                    accept=".jpg,.jpeg,.png,.webp"
                    multiple={true}
                    onChange={e => this.onImageChange(e, "albumImages")}
                  />
                </div>
              </div>
              {albumImages && albumImages.length ? (
                <ReactDragListView
                  onDragEnd={(fromIndex, toIndex) => this.onDragEnd(fromIndex, toIndex)}
                  nodeSelector='div'
                  handleSelector='div'
                >
                  <div className="content-image album-content">
                    {_.map(albumImages, (item, i) => {
                      return (
                        <div
                          key={i}
                          onMouseEnter={() => this.deleteIconControl(i, true)}
                          onMouseLeave={() => this.deleteIconControl(i, false)}
                          className="image-container"
                        >
                          <Icon
                            className={modalKey === i ? deleteIcon ? 'view-delete-icon' : 'hide-delete-icon' : 'hide-delete-icon'}
                            // className="view-delete-icon"
                            onClick={() => {
                              this.removeImage(item, i);
                            }}
                            size="small"
                            name="trash"
                          />
                          <Image className="uploaded-image" key={i} size="small" src={item} bordered />
                        </div>
                      );
                    })}
                  </div>
                </ReactDragListView>
              ) : null}
            </Grid.Row>
            {/* ) : null} */}
          </Grid>
        );
      default:
        break;
    }
  }

  deleteIconControl(key, deleteIcon) {
    this.setState({
      deleteIcon: deleteIcon,
      modalKey: key
    });
  }

  removeImage(item, i) {
    let { albumImages } = this.state;
    const removedImageArray = _.remove(albumImages, o => {
      return o !== item;
    });
    this.setState({
      albumImages: removedImageArray
    });
  }

  render() {
    const { itemType, contentLoading, isNew, item } = this.props;
    const { eventImage, thumbnail, albumImages } = this.state;
    const form = {};
    form.itemTypes = itemType ? itemType : "post";

    let isDisabled = false;
    switch (itemType) {
      case "magazine":
        isDisabled = !(eventImage && thumbnail);
        if (item) {
          form.magazineTitle = item.title;
          form.magazineISBN = item.ISBN;
          form.magazineTags = item.tags ? JSON.parse(item.tags).toString() : "";
        }
        break;
      case "gallery":
        isDisabled = !albumImages.length;
        if (item) {
          form.galleryTitle = item.title;
        }
        break;

      default:
        isDisabled = !eventImage;
        if (item) {
          form.postTitle = item.title;
          form.postContent = item.content;
          form.postTags = item.tags ? JSON.parse(item.tags).toString() : "";
        }
        break;
    }

    return (
      <Grid padded stackable>
        {isNew ? (
          <Grid.Row className="modal-header-div content-header">
            <span className="modal-head">Add New Content Item</span>
            <Icon
              onClick={() => this.props.trigger(false)}
              name="close"
              className="closeIcon"
            />
          </Grid.Row>
        ) : null}
        <AvForm
          className="content-form"
          onValidSubmit={this.handleValidSubmit}
          ref={c => (this.form = c)}
          model={form}
        >
          {/* <Grid.Row>
            <AvRadioGroup
              className="content-type"
              inline
              name="itemTypes"
              label="Item Type"
              required
              errorMessage="Pick one!"
              onChange={e => this.onRadioChange(e)}
            >
              <AvRadio
                label="News"
                value="post"
                disabled={itemType ? itemType !== "post" : false}
              />
              <AvRadio
                label="Magazine"
                value="magazine"
                disabled={itemType ? itemType !== "magazine" : false}
              />
              <AvRadio
                label="Gallery"
                value="gallery"
                disabled={itemType ? itemType !== "gallery" : false}
              />
            </AvRadioGroup>
          </Grid.Row> */}
          {this.renderContent()}
          <div>
            <hr />
            <Button loading={contentLoading.itemAdd} disabled={isDisabled}>
              {isNew ? "ADD NEW ITEM" : "UPDATE ITEM"}
            </Button>
          </div>
        </AvForm>
      </Grid>
    );
  }
}

class ContentEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    const { modal } = this.state;
    const { item } = this.props;
    return (
      <Modal
        centered
        trigger={<div className="view">Edit Content</div>}
        open={modal}
        onOpen={() => this.toggle()}
        size="small"
      >
        <Modal.Content>
          <div className="modal-header-div content-header">
            Edit - {item.title}
            <Icon
              className="close-icon"
              name="close"
              onClick={() => this.toggle()}
            />
          </div>
          <ContentForm
            toggle={() => this.toggle()}
            item={item}
            isNew={false}
            {...this.props}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class ContentDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  async deleteItemHandle() {
    try {
      const {
        deleteMagazineItem,
        deletePostItem,
        item,
        itemType,
        archiveAlbum,
        deleteAlbumImageById,
        albumId,
        deleteMember
      } = this.props;
      switch (itemType) {
        case "magazine":
          await deleteMagazineItem(item.id);
          break;
        case "gallery":
          await archiveAlbum({ albumId: item.id });
          break;
        case "image":
          await deleteAlbumImageById(albumId, item.id);
          break;
        case "member":
          await deleteMember(item.id);
          break;
        default:
          await deletePostItem(item.id);
          break;
      }
      this.toggle();
    } catch (error) {
      console.log("TCL: ItemDelete -> deleteItemHandle -> error", error);
    }
  }

  render() {
    const { modal } = this.state;
    const { item, contentLoading, itemType, className } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        trigger={
          itemType === "image" ? (
            <Icon
              className={className ? className : null}
              size="large"
              name="trash"
            />
          ) : itemType === 'member' ? (<Dropdown.Item text="Remove Member" />) : (
            <div className="unpublish">Archive</div>
          )
        }
        size="tiny"
      >
        <Modal.Content>
          <div className="modal-header-div content-header">
            Delete - {item.title}
            <Icon
              className="close-icon"
              name="close"
              onClick={() => this.toggle()}
            />
          </div>
          <p>Are you sure you want to delete this item?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="modal-button"
            onClick={() => this.toggle()}
            negative
            content="No"
          />
          <Button
            className="modal-button"
            loading={contentLoading ? contentLoading.deleteItem : null}
            onClick={() => this.deleteItemHandle()}
            positive
            labelPosition="right"
            icon="checkmark"
            content="Yes"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { content } = state;
  return {
    ...content
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createPostItem,
      createMagazineItem,
      createAlbumItem,
      updateMagazineItem,
      updatePostItem,
      addAlbumImage,
      updateAlbumItem,
      getAlbumImages
    },
    dispatch
  );
};

ContentForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentForm);

export { ContentForm, ContentEdit, ContentDelete };
