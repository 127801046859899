import React from "react";
import { PaymentHolder } from "../../../components";
import { Header, Grid, Image, Icon } from "semantic-ui-react";
import "./eventPayment.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getEventByEventId,
  getEventPaymentReceipt
} from "../../../store/actions";
import moment from "moment";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

class EventPaymentReceipt extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      event: {},
      responsive: {
        0: {
          items: 1
        },
        1024: {
          items: 1
        },
        750: {
          items: 1
        }
      },
      currentIndex: 0
    };
  }

  async componentDidMount() {
    try {
      const {
        getEventByEventId,
        match,
        getEventPaymentReceipt,
        itemPaymentId,
        eventId
      } = this.props;

      await getEventByEventId(match ? match.params.eventId : eventId);
      await getEventPaymentReceipt({
        eventId: match ? match.params.eventId : eventId,
        itemPaymentId: match ? match.params.itemPaymentId : itemPaymentId
      });
      this.setState({
        event: this.props.event,
        paymentReceipt: this.props.paymentReceipt,
        tickets: this.tickets(this.props.paymentReceipt)
      });
    } catch (error) {
      console.log(
        "TCL: EventPaymentReceipt -> componentDidMount -> error",
        error
      );
    }
  }

  slideNext = () => {
    const { paymentReceipt } = this.props;
    this.setState({
      currentIndex:
        this.state.currentIndex + 1 === paymentReceipt.tickets.length
          ? 0
          : this.state.currentIndex + 1
    });
  };

  slidePrev = () => {
    const { paymentReceipt } = this.props;
    this.setState({
      currentIndex:
        this.state.currentIndex - 1 === -1
          ? paymentReceipt.tickets.length - 1
          : this.state.currentIndex - 1
    });
  };
  tickets(paymentReceipt) {
    const { event } = this.props;

    return (
      paymentReceipt &&
      paymentReceipt.tickets &&
      paymentReceipt.tickets.map((item, i) => {
        return (
          <div key={i} className="event-ticket-div">
            <h2 className="event-header">{event.title}</h2>
            <label className="header-label">When</label>
            <p className="event-details">
              {moment(event.date).format("dddd  DD MMMM YYYY")} at{" "}
              {moment(event.time, "HH:mm:ss").format("h.mm a")}
            </p>
            <label className="header-label">Where</label>
            <p className="event-details">
              {event.venueName +
                ", " +
                event.streetName +
                ", " +
                event.suburb +
                ", " +
                event.state +
                ", " +
                event.postCode}
            </p>
            <div className="ticket-details-div">
              <div>
                <label className="header-label">Ticket Number</label>
                <p className="event-details">{item.ticketNo}</p>
              </div>
              <div>
                <Image src={item.qrUrl} />
              </div>
            </div>
          </div>
        );
      })
    );
  }

  onSlideChanged(e) {
    this.setState({
      currentIndex: e.item
    });
  }

  render() {
    const { paymentReceipt, responsive, tickets, currentIndex } = this.state;
    const { segmentLoading } = this.props;
    if (segmentLoading) {
      segmentLoading(this.props.paymentReceiptLoading);
    }
    return (
      <>
        {paymentReceipt && (
          <PaymentHolder {...this.props}>
            <Grid padded className="event-payment">
              <Grid.Row className="ticket-div">
                <div>
                  <Header>PAYMENT SUCCESSFUL</Header>
                  <p className="desc-para">
                    Here are your tickets for the event. Save as PDF to show
                    your ticket at the event. We have also sent the tickets by
                    email.
                  </p>
                </div>

                <div className="btn-section">
                  <div
                    className="pdf-div"
                    onClick={() =>
                      window.open(paymentReceipt.ticketPdf.ticketPdf)
                    }
                  >
                    Save as PDF
                  </div>
                  <div>
                    <label>
                      Ticket <b>{currentIndex + 1}</b> of{" "}
                      <b>{paymentReceipt.tickets.length}</b>
                    </label>
                    <button
                      className="prev-btn"
                      onClick={() => this.slidePrev()}
                    >
                      <div>
                        <Icon size="mini" name="chevron left" />
                      </div>
                    </button>
                    <button
                      className="next-btn"
                      onClick={() => this.slideNext()}
                    >
                      <div>
                        <Icon size="tiny" name="chevron right" />
                      </div>
                    </button>
                  </div>
                </div>
                <AliceCarousel
                  dotsDisabled={true}
                  buttonsDisabled={true}
                  items={tickets}
                  responsive={responsive}
                  slideToIndex={currentIndex}
                  onSlideChanged={e => this.onSlideChanged(e)}
                />
              </Grid.Row>
            </Grid>
          </PaymentHolder>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { items } = state;
  return {
    ...items
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getEventByEventId,
      getEventPaymentReceipt
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventPaymentReceipt);
