import React from "react";
import { CustomTable } from "../../../components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllAttendanceOfAUser } from "../../../store/actions";
import _ from "lodash";
import moment from "moment";
import history from "../../../history";
import ButtonControls from "../../layout/buttonControls";

class MemberAttendance extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: "EVENT",
          render: data => {
            return <div>{!_.isEmpty(data.Item) ? data.Item.title : "-"}</div>;
          }
        },
        {
          title: "DATE",
          render: data => {
            return (
              <div>
                {!_.isEmpty(data.Item)
                  ? moment(data.Item.date).format("DD MMMM YYYY")
                  : "-"}
              </div>
            );
          }
        },
        {
          title: "STATUS",
          render: data => {
            return <div>{data.attendanceStatus ? "Attended" : "Absent"}</div>;
          }
        },
        props.profile
          ? {
              title: (
                <div>
                  <span>View All Attendance</span>
                </div>
              ),
              textAlign: "right",
              onClick: () => {
                history.push(
                  `/admin/manageMembers/attendance/${props.match.params.memberId}`
                );
              },
              className: "profile-edit-member",
              render: () => {
                return <div> </div>;
              }
            }
          : {
              render: () => {
                return <div></div>;
              }
            }
      ],
      page: 0
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const { getAllAttendanceOfAUser, match } = this.props;
    await getAllAttendanceOfAUser({ userId: match.params.memberId });
  }

  async handlePaginationChange(activePage) {
    try {
      const { getAllAttendanceOfAUser, match } = this.props;
      await getAllAttendanceOfAUser({
        userId: match.params.memberId,
        offset: activePage
      });
      await this.setState({
        page: activePage
      });
    } catch (error) {
      console.log(">:Members-> handlePaginationChange -> error", error);
    }
  }

  goBack() {
    history.push("/admin/manageMembers");
  }

  render() {
    const { userAttendance, profile } = this.props;
    return (
      <div>
        {profile ? (
          <CustomTable
            header
            columns={this.state.columns}
            data={userAttendance.docs}
            pagination
            noOfPages={this.props.userAttendance.total}
            page={this.state.page}
            handlePaginationChange={activePage =>
              this.handlePaginationChange(activePage)
            }
          />
        ) : (
          <ButtonControls
            backArrow={"Back to Member Profile"}
            goBack={() => this.goBack()}
          >
            <CustomTable
              header
              columns={this.state.columns}
              data={userAttendance.docs}
              pagination
              noOfPages={this.props.userAttendance.total}
              page={this.state.page}
              handlePaginationChange={activePage =>
                this.handlePaginationChange(activePage)
              }
            />
          </ButtonControls>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { attendance } = state;
  return {
    ...attendance
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllAttendanceOfAUser
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberAttendance);
