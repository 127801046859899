import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ButtonControls from "../layout/buttonControls";
import { Segment, Grid, Image } from "semantic-ui-react";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../constant";
import { getAlbumImages, deleteAlbumImageById } from "../../store/actions";
import history from "../../history";
import "./content.scss";
import _ from "lodash";
import Viewer from "react-viewer";
import "react-viewer/dist/index.css";

class Albums extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      albumId: null,
      deleteModal: false,
      deleteIcon: false,
      modalKey: null,
      addModal: false,
      landing: props.match.params.imageId ? true : false,
      visible: false,
      imageSrc: []
    };
  }

  async componentDidMount() {
    try {
      const { landing } = this.state;
      const { getAlbumImages, match } = this.props;
      let id;
      if (landing) {
        id = parseInt(match.params.imageId, 10);
      } else {
        id = match.params.id;
      }
      await this.setState({ albumId: id });
      await getAlbumImages("albumImages", id);
      let imageSrc = [];
      this.props.albumImages.forEach(async item => {
        imageSrc.push({ src: item.src });
      });
      this.setState({
        imageSrc: imageSrc
      });
    } catch (error) {
      console.log(">: Albums -> componentDidMount -> error", error);
      toast.error(ERROR_MSG);
    }
  }

  goBack() {
    if (this.props.landing) {
      history.push(`/media/1`);
    } else {
      history.push("/admin/content/Gallery");
    }
  }

  deleteModal(open) {
    this.setState({
      deleteModal: open
    });
  }

  deleteItem(id) {
    // delete image call here
    const { albumId } = this.state;
    const { deleteAlbumImageById } = this.props;
    deleteAlbumImageById(albumId, id);
    this.setState({ deleteModal: false });
  }

  onOpen(modal, open) {
    this.setState({
      [modal]: open
    });
  }

  deleteIconControl(key, deleteIcon) {
    this.setState({
      deleteIcon: deleteIcon,
      modalKey: key
    });
  }

  trigger(open) {
    this.setState({
      addModal: open
    });
  }

  render() {
    const { contentLoading, albumImages } = this.props;
    const { landing, imageSrc, activeIndex } = this.state;
    return (
      <ButtonControls
        backArrow={"Back to Gallery"}
        goBack={() => this.goBack()}
      >
        <div id="albumImages" className="album-images-container">
          <Segment
            className="album-segment"
            loading={contentLoading.albumImages}
          >
            <Grid stackable>
              {!_.isEmpty(albumImages) ? (
                <Grid.Row>
                  {albumImages.map((item, i) => {
                    return (
                      <Grid.Column computer={4} key={i}>
                        <div
                          onMouseEnter={() => this.deleteIconControl(i, true)}
                          onMouseLeave={() => this.deleteIconControl(i, false)}
                          className={
                            landing
                              ? "gallery-image-div-landing"
                              : 
                              "gallery-image-div"
                          }
                        >
                          {/* {landing ? null : (
                            <DeleteModal
                              className={
                                this.state.modalKey === i
                                  ? this.state.deleteIcon
                                    ? "delete-icon-view"
                                    : "delete-icon-hide"
                                  : "delete-icon-hide"
                              }
                              trigger={
                                <Icon
                                  className={
                                    this.state.modalKey === i
                                      ? this.state.deleteIcon
                                        ? "delete-icon-view"
                                        : "delete-icon-hide"
                                      : "delete-icon-hide"
                                  }
                                  size="large"
                                  name="trash"
                                />
                              }
                              item={item}
                              {...this.props}
                              type="image"
                              albumId={this.state.albumId}
                            />
                          )} */}
                          <Image
                            onClick={
                               () => {
                                    this.setState({
                                      visible: !this.state.visible,
                                      activeIndex: i
                                    });
                                  }
                            }
                            className={
                              landing
                                ? "gallery-image-landing"
                                : "gallery-image"
                            }
                            src={item ? item.src : null}
                          />
                        </div>
                        <div>
                          {/* <button onClick={() => { this.setState({ visible: !this.state.visible }); } }>show</button> */}
                        </div>
                      </Grid.Column>
                    );
                  })}
                  <Viewer
                    activeIndex={activeIndex}
                    visible={this.state.visible}
                    onClose={() => {
                      this.setState({ visible: false });
                    }}
                    images={imageSrc}
                  />
                </Grid.Row>
              ) : (
                <div className="empty-div">No Pictures</div>
              )}
            </Grid>
          </Segment>
        </div>
      </ButtonControls>
    );
  }
}

const mapStateToProps = state => {
  const { content } = state;
  return {
    ...content
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAlbumImages,
      deleteAlbumImageById
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Albums);
