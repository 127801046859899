import React from "react";
import "./members.scss";
import { Grid, Icon, Modal } from "semantic-ui-react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addMemberToGroup, createMemberGroup, getAllMembersWithoutLimit } from "../../../store/actions";
import _ from "lodash";

class AddGroup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      members: [],
      memberError: false,
      openGroupModal: false,
      value: []
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
  }

  componentDidMount() {
    const { getAllMembersWithoutLimit } = this.props;
    getAllMembersWithoutLimit()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.openGroupModal !== prevState.openGroupModal) {
      this.setState({
        value: [],
        memberError: false
      });
    }
  }

  handleValidSubmit(event, values) {
    const { members } = this.state;
    const { addMemberToGroup, item, createMemberGroup, trigger} = this.props;

    if (members.length) {
      values.groupMembers = members;
      if(item){
        addMemberToGroup({ groupId: item.id, groupMembers: values.groupMembers });
        this.onOpen(false);
      }else{
        createMemberGroup('createGroup', values);
        trigger(false);
      }
    } 
  }

  handleInvalidSubmit(event, values) {
    const { members } = this.state;

    if (!members.length) {
      this.setState({ memberError: true });
    } else {
      this.setState({ memberError: false });
    }
  }

  handleChange = (e, { searchQuery, value }) => {
    this.setState({ searchQuery, value, members: value, memberError: false });
  };

  handleSearchChange = (e, { searchQuery }) => {
    this.setState({ searchQuery });
  };

  onOpen(open) {
    this.setState({
      openGroupModal: open
    });
  }

  dropDownOnBlur(event, data){
  if(_.isEmpty(data.value)){
    this.setState({
      memberError: true
    })
  }
  }

  render() {
    const { item, membershipLoading, allMembersNoLimit } = this.props;
    const { searchQuery, value, memberError } = this.state;
    const memberList = _.map(allMembersNoLimit, item => {
      return {
        key: item.id,
        text: item.firstName + " " + item.lastName,
        value: item.id
      };
    });
    return (
      <>
        {item ? (
          <Modal
            size="tiny"
            centered
            trigger={<b className="add">Add Member</b>}
            open={this.state.openGroupModal}
            onOpen={() => this.onOpen(true)}
            onClose={() => this.onOpen(false)}
          >
            <div className="content">
              <Grid className="comment-grid">
                <Grid.Row className="modal-header-div">
                  <span className="modal-head">Add Member(s) to Group</span>
                  <Icon
                    onClick={() => this.onOpen(false)}
                    name="close"
                    className="closeIcon"
                  />
                </Grid.Row>
                <AvForm
                  onValidSubmit={this.handleValidSubmit}
                  onInvalidSubmit={this.handleInvalidSubmit}
                  model={item ? { groupName: item.name } : null}
                  className="register-form"
                >
                  <AvField
                    name="groupName"
                    label="Group Name"
                    validate={{
                      required: { value: true }
                    }}
                  />
                  <label>Add Members</label>
                  <Dropdown
                    onBlur={(event, data) => this.dropDownOnBlur(event, data)}
                    className={
                      memberError
                        ? "invalid-input search-dropdown"
                        : "search-dropdown"
                    }
                    fluid
                    scrolling
                    multiple
                    onChange={this.handleChange}
                    onSearchChange={this.handleSearchChange}
                    options={memberList}
                    search
                    searchQuery={searchQuery}
                    selection
                    value={value}
                    />
                  {memberError ? (
                    <div className="invalid-input-msg">
                      This field is required
                    </div>
                  ) : null}
                  <Button loading={false}>ADD MEMBERS</Button>
                </AvForm>
              </Grid>
            </div>
          </Modal>
        ) : (
          <Grid className="comment-grid">
            <Grid.Row className="modal-header-div">
              <span className="modal-head">Create New Group</span>
              <Icon
                onClick={() => this.props.trigger(false)}
                name="close"
                className="closeIcon"
              />
            </Grid.Row>
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              onInvalidSubmit={this.handleInvalidSubmit}
              model={item ? { groupName: item.name } : null}
              className="register-form"
            >
              <AvField
                name="groupName"
                label="Group Name"
                validate={{
                  required: { value: true }
                }}
              />
              <label>Add Members</label>
              <Dropdown
                onBlur={(event, data) => this.dropDownOnBlur(event, data)}
                className={
                  memberError
                    ? "invalid-input search-dropdown"
                    : "search-dropdown"
                }
                fluid
                scrolling
                multiple
                onChange={this.handleChange}
                onSearchChange={this.handleSearchChange}
                options={memberList}
                search
                searchQuery={searchQuery}
                selection
                value={value}
              />
              {memberError ? (
                <div className="invalid-input-msg">This field is required</div>
              ) : null}
              <Button loading={membershipLoading.status}>SAVE NEW GROUP</Button>
            </AvForm>
          </Grid>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { members } = state;
  return {
    ...members
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      addMemberToGroup,
      createMemberGroup,
      getAllMembersWithoutLimit
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddGroup);
