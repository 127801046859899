import React from "react";
import { toast } from "react-toastify";
import { Icon } from "semantic-ui-react";
import { CustomTable } from "../../components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPastEvents } from "../../store/actions";
import moment from "moment";
import ButtonControls from "../layout/buttonControls";
import history from "../../history";
import './events.scss';
import { REACT_APP_ANDROID_URL } from "../../constant";

class Events extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: "EVENT NAME",
          render: data => {
            return <div>{data.title ? data.title : "-"}</div>;
          }
        },
        {
          title: "EVENT DATE",
          render: data => {
            return (
              <div>
                {data.date ? moment(data.date).format("DD MMMM YYYY") : "-"}
              </div>
            );
          }
        },
        {
          title: "TICKETS SOLD",
          render: data => {
            return (
              <div>
                {data.ItemPayments[0] ? data.ItemPayments[0].ticketSold : "-"}
              </div>
            );
          }
        },
        {
          title: "NO. OF ATTENDEES",
          render: data => {
            return (
              <div>{data.Tickets[0] ? data.Tickets[0].AttendeesNo : "-"}</div>
            ); //set the value when its available
          }
        },
        {
          title: " ",
          render: data => {
            return (
              <div className="options-row">
                <div
                  className="view"
                  onClick={() =>
                    history.push(`/admin/manageAttendance/${data.id}`)
                  }
                >
                  Manage Attendance
                </div>
              </div>
            );
          }
        }
      ],
      eventData: [],
      type: "all-events",
      page: 1
    };
  }

  async componentDidMount() {
    try {
      window.scrollTo(0, 0);
      const { getPastEvents } = this.props;
      // const itemType = 1; //for events
      await getPastEvents();
      this.setState({
        eventData: this.props.pastEvents
      });
    } catch (error) {
      console.log("TCL: Events -> componentDidMount -> error", error);
    }
  }

  async handlePaginationChange(activePage) {
    try {
      const { getPastEvents } = this.props;
      await getPastEvents({ page: activePage });
      await this.setState({
        page: activePage,
        eventData: this.props.pastEvents
      });
    } catch (error) {
      console.log(">:Members-> handlePaginationChange -> error", error);
    }
  }

  render() {
    return (
      <div>
        <div>
          <label className="android-label">Download <Icon name="android" size="large" /> app to mark attendance</label>&nbsp;
          <Icon
            name="copy"
            size="big"
            onClick={() => {
              navigator.clipboard.writeText(REACT_APP_ANDROID_URL);
              toast.success("Successfully copied to clipboard!");
            }}
            className="android-icon"
          />
        </div>
        <ButtonControls loading={this.props.pastEventsLoading}>
          <CustomTable
            header
            columns={this.state.columns}
            data={this.state.eventData}
            pagination
            noOfPages={this.props.pastEventsPages}
            page={this.state.page}
            handlePaginationChange={activePage =>
              this.handlePaginationChange(activePage)
            }
          />
        </ButtonControls>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { items } = state;
  return {
    ...items
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getPastEvents }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Events);
