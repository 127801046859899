import { createStore, applyMiddleware, compose } from 'redux';
import initSubscriber from 'redux-subscriber';
import reduxReset from 'redux-reset';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducer';
const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = applyMiddleware(thunk);
let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enHanceCreateStore = composeEnhancers(
  middleware,
  reduxReset() // Will use 'RESET' as default action.type to trigger reset
)(createStore);

const store = enHanceCreateStore(persistedReducer);
initSubscriber(store);

// ------------- these are the actions -------------
export * from './reducer';

// ------------- this is our store -------------
export default () => {
  let store = enHanceCreateStore(persistedReducer);
  let persistor = persistStore(store);
  initSubscriber(store);
  return { store, persistor };
};