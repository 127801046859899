import axios from "axios";
import { toast } from "react-toastify";
import { CREATED_MSG, DELETED_MSG, LIMIT, UPDATED_MSG } from "../../constant";
import { showError } from "../../utils";

const GET_CONTENT_LOADING = "GET_CONTENT_LOADING";
const CONTENT = "CONTENT";
const GET_POSTS_LOADING = "GET_POSTS_LOADING";
const POSTS = "POSTS";
const GET_ALBUMS_LOADING = "GET_ALBUMS_LOADING";
const ALBUMS = "ALBUMS";
const CONTENT_GET_LOADING = "CONTENT_GET_LOADING";
const GET_ALBUM_IMAGES = "GET_ALBUM_IMAGES";
const IMAGE_UPLAOD_LOADING = "IMAGE_UPLAOD_LOADING";
const ALBUM_ARCHIVE_LOADING = "ALBUM_ARCHIVE_LOADING";
const GET_POSTS_BY_ID_LOADING = "GET_POSTS_BY_ID_LOADING";
const POST = "POST";

const initialState = {
  getContentLoading: true,
  content: [],
  getPostsLoading: false,
  posts: [],
  getAlbumsLoading: false,
  albums: [],
  contentLoading: {},
  albumImages: [],
  albumArchiveLoading: false,
  getPostByIdLoading: false,
  post: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONTENT_LOADING:
      return {
        ...state,
        getContentLoading: action.payload
      };
    case CONTENT:
      return {
        ...state,
        content: action.payload.docs,
        pages: action.payload.pages,
        count: action.payload.total
      };
    case GET_POSTS_LOADING:
      return {
        ...state,
        getPostsLoading: action.payload
      };
    case POSTS:
      return {
        ...state,
        posts: action.payload.docs,
        postPages: action.payload.pages,
        postCount: action.payload.total
      };
    case ALBUMS:
      return {
        ...state,
        albums: action.payload.docs,
        albumPages: action.payload.pages,
        albumCount: action.payload.total
      };
    case GET_ALBUMS_LOADING:
      return {
        ...state,
        getAlbumsLoading: action.payload
      };
    case CONTENT_GET_LOADING:
      return {
        ...state,
        contentLoading: {
          [action.payload.type]: action.payload.status
        }
      };
    case GET_ALBUM_IMAGES:
      return {
        ...state,
        albumImages: action.payload
      };
    case IMAGE_UPLAOD_LOADING:
      return {
        ...state,
        imageUploadLoading: action.payload
      };
    case ALBUM_ARCHIVE_LOADING:
      return {
        ...state,
        albumArchiveLoading: action.payload
      };
    case GET_POSTS_BY_ID_LOADING:
      return {
        ...state,
        getPostByIdLoading: action.payload
      };
    case POST:
      return {
        ...state,
        post: action.payload
      };
    default:
      return state;
  }
}

export function getAllContent(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: GET_CONTENT_LOADING, payload: true });
        const params = {
          limit: LIMIT,
          offset: data ? (data.page ? data.page : 1) : 1,
          orderBy: "DESC",
          type: data ? (data.type ? data.type : null) : null,
          active: data? data.active : true
        };
        const response = await axios.get("/magazines", {
          params
        });
        dispatch({ type: CONTENT, payload: response.data });
        dispatch({ type: GET_CONTENT_LOADING, payload: false });
        resolve();
      } catch (error) {
        console.log("TCL: getAllContent -> error", error)
        showError(error);
        dispatch({ type: GET_CONTENT_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function getAllPosts(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: GET_POSTS_LOADING, payload: true });
        const params = {
          limit: data ? (data.limit ? data.limit : LIMIT) : LIMIT,
          offset: data ? (data.page ? data.page : 1) : 1,
          orderBy: "DESC",
          active: data? data.active : true
        };
        const response = await axios.get("/news", {
          params
        });
        dispatch({ type: POSTS, payload: response.data });
        dispatch({ type: GET_POSTS_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: GET_POSTS_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function getAllAlbums(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: GET_ALBUMS_LOADING, payload: true });
        const params = {
          limit: data ? (data.limit ? data.limit : LIMIT) : LIMIT,
          offset: data ? (data.page ? data.page : 1) : 1,
          orderBy: "DESC",
          active: data? data.active : true
        };
        const response = await axios.get("/albums", {
          params
        });
        dispatch({ type: ALBUMS, payload: response.data });
        dispatch({ type: GET_ALBUMS_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: GET_ALBUMS_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function getAlbumImages(type, id) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: true }
      });
      const response = await axios.get(`/albums/images?albumId=${id}`);
      dispatch({ type: GET_ALBUM_IMAGES, payload: response.data });
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    } catch (error) {
      showError(error);
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    }
  };
}

export function deleteAlbumImageById(albumId, imageId) {
  return async (dispatch, getState) => {
    try {
      await axios.delete(
        `/albums/images?albumId=${albumId}&imageId=${imageId}`
      );
      toast.success(DELETED_MSG);
      await dispatch(getAlbumImages("albumImages", albumId));
    } catch (error) {
      showError(error);
    }
  };
}

export function createPostItem(formData, type = "itemAdd") {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: true }
      });
      await axios.post(`/news`, formData);
      toast.success(CREATED_MSG);
      await dispatch(getAllPosts());
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    } catch (error) {
      showError(error);
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    }
  };
}

export function updatePostItem(formData, type = "itemAdd") {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: true }
      });
      await axios.put(`/news`, formData);
      toast.success(UPDATED_MSG);
      await dispatch(getAllPosts());
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    } catch (error) {
      showError(error);
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    }
  };
}

export function deletePostItem(id, params, type = "deleteItem") {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: true }
      });
      await axios.delete(`/news?newsId=${id}&active=${params}`);
      toast.success('Item archived successfully...');
      await dispatch(getAllPosts({active: !params}));
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    } catch (error) {
      showError(error);
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    }
  };
}

export function createMagazineItem(formData, type = "itemAdd") {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: true }
      });
      await axios.post(`/magazines`, formData);
      toast.success(CREATED_MSG);
      await dispatch(getAllContent());
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    } catch (error) {
      showError(error);
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    }
  };
}

export function updateMagazineItem(formData, type = "itemAdd") {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: true }
      });
      await axios.put(`/magazines`, formData);
      toast.success(UPDATED_MSG);
      await dispatch(getAllContent());
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    } catch (error) {
      showError(error);
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    }
  };
}

export function deleteMagazineItem(id, active, type = "deleteItem") {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: true }
      });
      await axios.delete(`/magazines?magazinesId=${id}&active=${active}`);
      toast.success('Item archived successfully...');
      await dispatch(getAllContent({active: !active}));
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    } catch (error) {
      showError(error);
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    }
  };
}

export function createAlbumItem(formData, type = "itemAdd") {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: true }
      });
      await axios.post(`/albums`, formData);
      toast.success(CREATED_MSG);
      await dispatch(getAllAlbums());
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    } catch (error) {
      showError(error);
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: { type, status: false }
      });
    }
  };
}

export function updateAlbumItem(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: true
      });
      await axios.put(`/albums`, data);
      toast.success(UPDATED_MSG);
      await dispatch(getAllAlbums());
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: false
      });
    } catch (error) {
      showError(error);
      dispatch({
        type: CONTENT_GET_LOADING,
        payload: false
      });
    }
  };
}

// export function deleteAlbumItem(id, type = "deleteItem") {
//   return async (dispatch, getState) => {
//     try {
//       dispatch({
//         type: CONTENT_GET_LOADING,
//         payload: { type, status: true }
//       });
//       await axios.delete(`/albums/?albumId=${id}`);
//       toast.success(DELETED_MSG);
//       await dispatch(getAllAlbums());
//       dispatch({
//         type: CONTENT_GET_LOADING,
//         payload: { type, status: false }
//       });
//     } catch (error) {
//       showError(error);
//       dispatch({
//         type: CONTENT_GET_LOADING,
//         payload: { type, status: false }
//       });
//     }
//   };
// }

export function addAlbumImage(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: IMAGE_UPLAOD_LOADING, payload: true });
        await axios.post("/albums/images", data);
        await dispatch(getAlbumImages("albumImages", data.albumId));
        dispatch({ type: IMAGE_UPLAOD_LOADING, payload: false });
        toast.success(CREATED_MSG);
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: IMAGE_UPLAOD_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function archiveAlbum(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: ALBUM_ARCHIVE_LOADING, payload: true });
        await axios.delete(
          `/albums/archive?albumId=${data.albumId}&active=${data.active}`
        );
        await dispatch(getAllAlbums({active: !data.active}));
        dispatch({ type: ALBUM_ARCHIVE_LOADING, payload: false });
        toast.success("Album archived successfully");
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: ALBUM_ARCHIVE_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function getNewsById(data) {
  
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: GET_POSTS_BY_ID_LOADING, payload: true });
        const response = await axios.get(`/news/${data}`);
        dispatch({ type: POST, payload: response.data });
        dispatch({ type: GET_POSTS_BY_ID_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: GET_POSTS_BY_ID_LOADING, payload: false });
        reject();
      }
    });
  };
}
