import React from "react";
import "./layout.scss";
import {
  Button,
  Grid,
  Icon,
  Search,
  Label,
  Dropdown,
  Modal,
  Segment
} from "semantic-ui-react";
import _ from "lodash";

const source = _.times(5, () => ({
  title: "f",
  description: "f",
  image: "f",
  price: "f"
}));

class ButtonControls extends React.PureComponent {
  handleResultSelect = (e, { result }) =>
    this.setState({ value: result.title });
  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState({});

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = result => re.test(result.title);

      this.setState({
        isLoading: false,
        results: _.filter(source, isMatch)
      });
    }, 300);
  };

  render() {
    const { controlObj, children, search, backArrow, loading } = this.props;
    return (
      <Grid stackable padded>
        <Grid.Row>
          <Grid.Column width={6} className="sort-col">
            {controlObj && controlObj.filter ? (
              <>
                {_.map(controlObj.filter, (item, i) => {
                  return (
                    <div key={i}>
                      <Label>{item.label ? item.label : "View By:"}</Label>
                      <Dropdown
                        options={item.items}
                        defaultValue={item.defaultValue}
                        onChange={item.dropDownChange}
                      ></Dropdown>
                    </div>
                  );
                })}
              </>
            ) : backArrow ? (
              <div className="back-label">
                <Label onClick={this.props.goBack}>
                  <Icon name="arrow left" />
                  {backArrow}
                </Label>
              </div>
            ) : null}
          </Grid.Column>
          <Grid.Column width={10} className="button-col">
            {_.isEmpty(controlObj && controlObj.btnArr)
              ? null
              : controlObj.btnArr.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      {item.modalContent ? (
                        <Modal
                          centered
                          closeOnDocumentClick={true}
                          closeOnDimmerClick={true}
                          size={item.size ? item.size : null}
                          key={i}
                          open={item.open}
                          onOpen={item.onOpen}
                          onClose={item.onClose}
                          trigger={
                            <Button
                              disabled={item.disabled ? item.disabled : false}
                              key={i}
                              className={item.className}
                            >
                              {" "}
                              {item.icon ? <Icon name={item.icon} /> : null}
                              {item.name}
                            </Button>
                          }
                        >
                          <Modal.Content>{item.modalContent}</Modal.Content>
                        </Modal>
                      ) : (
                        <Button
                          loading={item.loading? item.loading : false}
                          onClick={item.onClick}
                          key={i}
                          className={item.className}
                          disabled={item.loading? true : false}
                        >
                          {" "}
                          <Icon name={item.icon} /> {item.name}
                        </Button>
                      )}
                    </React.Fragment>
                  );
                })}
          </Grid.Column>
        </Grid.Row>
        {search ? (
          <Grid.Row className="search-sort-row">
            <Grid.Column width={6} className="search-col">
              <Search
                className="search-input"
                loading={this.props.search.searchLoading}
                onResultSelect={this.props.search.handleResultSelect}
                onSearchChange={_.debounce(
                  this.props.search.handleSearchChange,
                  500,
                  {
                    leading: true
                  }
                )}
                results={this.props.search.results}
                value={this.props.search.value}
                open={false}
              />
            </Grid.Column>
            <Grid.Column width={10} className="sort-col button-col">
              <div>
                <Label>View By:</Label>
                <Dropdown
                  options={search.items}
                  defaultValue={search.items[0].value}
                  onChange={this.props.viewByChange}
                ></Dropdown>
              </div>
              <div>
                <Label>Member Type:</Label>
                <Dropdown
                  options={search.memberTypes}
                  defaultValue={search.memberTypes[0].value}
                  onChange={this.props.viewByMembershipType}
                ></Dropdown>
              </div>
            </Grid.Column>
          </Grid.Row>
        ) : null}
        <Segment className="layout-container-segment" loading={loading}>
          {children}
        </Segment>
      </Grid>
    );
  }
}
export default ButtonControls;
