import React from 'react';
import './register.scss';
import { BackgroundPage, Footer } from '../../components';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button, Grid } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import { userLogin } from '../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../../history';
import { toast } from 'react-toastify';

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loginDetails: {},
      pathName: props.location.state ? props.location.state.from.pathname : null
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    const authToken = localStorage.getItem('auth_token');
    const userType = localStorage.getItem('user_type');
    const { pathName } = this.state;
    if (pathName) {
      toast.warn('Please login to continue');
    }
    if (authToken) {
      switch (userType) {
        case '1':
          history.push('/admin/manageMembers');
          break;
        case '2':
          history.push('/events#events');
          break;
        default:
          break;
      }
    }
  }

  handleValidSubmit(event, values) {
    this.setState({ loginDetails: values }, async () => {
      try {
        const { userLogin } = this.props;

        await userLogin(values);
        const userType = await localStorage.getItem('user_type');

        if (userType === '1') {
          if (this.state.pathName) {
            history.push(this.state.pathName);
          } else {
            history.push('/admin/manageMembers');
          }
        } else if (userType === '2') {
          if (this.state.pathName) {
            history.push(this.state.pathName);
          } else {
            history.push('/events#events');
          }
        }
      } catch (error) {
        const { errorResponse } = this.props;
        if (errorResponse) {
          if (errorResponse.user && !errorResponse.user.active) {
            history.push(
              `/membership/payment/${this.props.errorResponse.user.MembershipType.id}/renew/false/${errorResponse.user.id}`
            );
          } else if (errorResponse.message === 'membership is expired') {
            history.push(
              `/membership/payment/${this.props.errorResponse.user.MembershipType.id}/renew/true/${errorResponse.user.id}`
            );
          }
        }
      }
    });
  }

  render() {
    const { loginDetails } = this.state;
    const { authLoginLoading } = this.props;

    return (
      <>
        <BackgroundPage header={'LOGIN TO RCOBAWA'} desc={'Hi, welcome back!'}>
          <Grid stackable textAlign="left">
            <Grid.Row>
              <Grid.Column width={isMobile ? 3 : 5}></Grid.Column>
              <Grid.Column width={isMobile ? 10 : 6}>
                <AvForm
                  onValidSubmit={this.handleValidSubmit}
                  model={loginDetails}
                >
                  <AvField
                    name="email"
                    label="Email Address"
                    type="email"
                    errorMessage="Email address is required"
                    validate={{
                      required: { value: true }
                    }}
                  />
                  <AvField
                    name="password"
                    label="Password"
                    type="password"
                    errorMessage="Password is required"
                    validate={{
                      required: { value: true }
                    }}
                  />
                  <Grid.Row>
                    <a href="/forgotPassword">Forgot Password?</a>
                  </Grid.Row>
                  <Grid.Row className="login-btn">
                    <Button loading={authLoginLoading}>LOGIN</Button>
                  </Grid.Row>
                </AvForm>
              </Grid.Column>
              <Grid.Column width={isMobile ? 3 : 5}></Grid.Column>
            </Grid.Row>
          </Grid>
        </BackgroundPage>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = state => {
  const { auth } = state;
  return {
    authLoginLoading: auth.authLoginLoading,
    errorResponse: auth.errorResponse
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      userLogin
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
