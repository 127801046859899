import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Footer,
  About,
  InfoCards,
} from '../components';
import {
  getAllPosts,
  getAllAlbums
} from '../store/actions';
import Header from './homePage/header';

class LandingPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
    const { getAllPosts, getAllAlbums } = this.props;
    getAllPosts({ page: 1, limit: 10, active: true });
    getAllAlbums({ page: 1, limit: 10, active: true });
  }


  render() {
    const { posts, albums } = this.props;
    return (
      <div>
        <Header path="home" />
        <InfoCards heading={'NEWS'} type={'news'} data={posts} {...this.props}/>
        <About />
        <InfoCards heading={'GALLERY'} type={'gallery'} data={albums} {...this.props}/>
        <Footer />
      </div>
    );
  }
}


const mapStateToProps = state => {
  const { content: {
    getPostsLoading = false,
    posts = [],
    getAlbumsLoading = false,
    albums = [],
  } } = state;
  return {
    getPostsLoading,
    posts,
    getAlbumsLoading,
    albums
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllPosts,
      getAllAlbums
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPage);
