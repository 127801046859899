import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllContent } from '../../store/actions';
import './content.scss';
import { Segment, Image } from 'semantic-ui-react';
import { CustomTable } from '../../components';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

class Magazines extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          render: data => {
            return (
              <div className="news-div">
                <div className="news-title">
                  {data.title ? data.title : '-'}
                </div>

                <div className="news-date">{data.createdAt ? moment(data.createdAt).format('DD MMMM YYYY') : '-'}</div>
                <div className="magazines-img"> 
                  <Image
                    size={isMobile? 'small': 'medium'}
                    onClick={() => window.open(data.file)}
                    style={{ cursor: 'pointer' }}
                    src={
                      data.thumbnail
                        ? data.thumbnail
                        : require('../../assets/image-placeholder.png')
                    }
                  />
                </div>
                {/* <div className="album-name">
                  View PDF
                </div> */}
              </div>
            );
          }
        }
      ],
      magazines: [],
      page: 1
    };
  }

  async componentDidMount() {
    try {
      const { getAllContent } = this.props;
      await getAllContent();
      this.setState({
        magazines: this.props.content
      });
    } catch (error) {
      console.log("TCL: Magazines -> componentDidMount -> error", error)
    }
  }
  
  async handlePaginationChange(activePage) {
    try {
      const { getAllContent } = this.props;
      await getAllContent(activePage);
      await this.setState({
        page: activePage,
        magazines: this.props.content
      });
    } catch (error) {
      console.log('>:Members-> handlePaginationChange -> error', error);
    }
  }
  render() {
    const { columns, magazines } = this.state;
    const { getContentLoading } = this.props;
    return (
      <Segment loading={getContentLoading}>
        <CustomTable
          columns={columns}
          data={magazines}
          pagination
          noOfPages={this.props.postPages}
          page={this.state.page}
          handlePaginationChange={activePage =>
            this.handlePaginationChange(activePage)
          }
        />
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  const { content } = state;
  return {
    content: content.content,
    pages: content.pages,
    count: content.count,
    getContentLoading: content.getContentLoading
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllContent
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Magazines);
