import React from 'react';
import './about.scss';
import {
  Container,
  Header as HomePageHeader,
  Grid,
  Card,
  Image,
  Icon
} from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import Header from '../../containers/homePage/header';
import { Footer } from '../homePage/footer';
import history from '../../history';

// TODO: transfer this to constant.js and import from there
const memebers = [
  {
    img: 'Soba Ranasinghe.jpg',
    name: 'Soba Ranasignhe',
    dsignation: 'PRESIDENT'
  },
  {
    img: 'Indrajith Wijegunawardena.jpg',
    name: 'Indrajith Wijegunawardane',
    dsignation: 'IMMEDIATE PAST PRESIDENT'
  },
  {
    img: 'Dhammika Perera.jpg',
    name: 'Dhammika Perera',
    dsignation: 'VICE PRESIDENT'
  },
  {
    img: 'Ranga Perera  - Vice President.jpg',
    name: 'Ranga Perera',
    dsignation: 'VICE PRESIDENT'
  },
  {
    img: 'Dinesh Rahim  - Secretary.jpg',
    name: 'Dinesh Rahim',
    dsignation: 'SECRETARY'
  },
  {
    img: 'Charith Jayathilake   - Treasurer.jpg',
    name: 'Charith Jayathilake',
    dsignation: 'TREASURER'
  },
  {
    img: 'Aynkaran Sivaratnam.jpg',
    name: 'Aynkaran Sivaratnam',
    dsignation: ''
  },

  {
    img: 'Dinesh Perera.jpg',
    name: 'Dinesh Perera',
    dsignation: ''
  },
  {
    img: 'Shyam Sideek.jpg',
    name: 'Shyam Sideek',
    dsignation: ''
  },
  {
    img: 'George Fernando.jpg',
    name: 'George Fernando',
    dsignation: ''
  },
  {
    img: 'Athula Rathnayake.jpg',
    name: 'Athula Rathnayake',
    dsignation: ''
  },
  {
    img: 'Aslam Assen.jpg',
    name: 'Aslam Assen',
    dsignation: ''
  },
  {
    img: 'Deepal Perera.jpg',
    name: 'Deepal Perera',
    dsignation: ''
  },
  {
    img: 'Shanaka Perera.jpg',
    name: 'Shanaka Perera',
    dsignation: ''
  },
  {
    img: 'Lochana Premarathna.jpg',
    name: 'Lochana Premarathna',
    dsignation: ''
  },
  {
    img: 'Akvan Gajanayake.jpg',
    name: 'Akvan Gajanayake',
    dsignation: ''
  },
  {
    img: 'Pasan Gunesekera.jpg',
    name: 'Pasan Gunesekara',
    dsignation: ''
  },
  {
    img: 'Chinthana Wijeweera.jpg',
    name: 'Chinthana Wijeweera',
    dsignation: ''
  }
];

export const AboutUs = props => {
  return (
    <>
      <Header path="about" />
      <Container className="about-us" id="about">
        <HomePageHeader>ABOUT RCOBAWA</HomePageHeader>
        <p>
          Royal College Old Boys in Australia Association is an organisation
          incorporated in the state of Victoria , Australia. Membership is open
          to all past students of Royal College (RC) Colombo, Sri Lanka and
          Junior School formerly know as Royal Primary School( RPS). RCOBAWA has
          been recognised as the sole representative organisation in Victoria of
          Old boys of Royal College by the parent body in Sri Lanka the Royal
          College Union (RCU).
          <br />
          <br />
          The origins of the current RCOBAWA date back to the late 1970’s when
          Old Royalists domiciled in Melbourne used to meet in what was very
          much a ”boys club” environment at each other’s houses. These were men
          who had joined Royal College in the 1920s and 30s.They were mainly
          social gatherings with old friends reminiscing about the good old days
          in Ceylon (with an occasional game of cricket) This nascent group was
          led by late Mr Edward “Eddie” Gray an outstanding Royalist who
          captained RC in Rugby, Athletics and Boxing and a former IGP and
          Olympian (1948 and 1950) in Ceylon.He and his cohort of friends formed
          the nucleus of this group. Coincidentally another group of younger
          likeminded Old Royalist from the Gross (Group of 1966) and the Olive
          Groups (RC Group of 1968) were having their own informal gatherings.
          <br />
          <br />
          It was then decided to register this group as an association and the
          Royal College OBA in Melbourne was born in 1980 under the helm of late
          Mr Arthur Kenuman, with late Mr. Eddie Gray as the Secretary. Mr Dilip
          Somaratne was elected from the younger members to be their
          representative in the committee.
          <br />
          <br />
          With the aim of fostering fellowship among the growing number of Old
          Royalists, larger scale and more formal social events were organised
          with the Annual Dinner Dance being the highlight of the association’s
          calendar.
          <br />
          <br />
          The OBA was incorporated as RCOBAWA under an act of government in
          Jan.1992 and has continued to flourish since then.
          <br />
          <br />
          Along with fostering fellowship among Old Royalists, RCOBAWA has been
          keeping the traditions and spirit of our old school alive in Melbourne
          by hosting annual events such as the Melbourne version of Royal
          Thomian Cricket match and the Bradby shield in addition to the Stag
          night. Members and their families are very much a part of these
          events. The annual general meeting of the association is held
          typically in September to elect office bearers and to present the
          annual accounts. The Eddie Gray Memorial oration and the “Toast to the
          teachers of Royal” have become part of the fabric that makes the AGM
          special.
        </p>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <HomePageHeader>RCOBAWA COMMITTEE</HomePageHeader>
            </Grid.Column>
            <Grid.Column className="past-presidents">
              {/* past presidents news article */}
              <HomePageHeader
                onClick={() => history.push(`/news/newsArticle/1`)}
              >
                View Past Presidents
                <Icon size="mini" name="arrow right" />
              </HomePageHeader>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid
          stackable
          padded
          columns={isMobile ? 2 : 4}
          className="committee-members"
        >
          <Grid.Row>
            {memebers.map((item, i) => {
              return (
                <Grid.Column key={i}>
                  <Card>
                    <Image
                      src={require(`../../assets/rcobaa commitee/${item.img}`)}
                      wrapped
                    />
                    <Card.Content>
                      <Card.Header>{item.name}</Card.Header>
                      <Card.Meta>
                        <span className="date">{item.dsignation}</span>
                      </Card.Meta>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              );
            })}
          </Grid.Row>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
