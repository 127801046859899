import React from 'react';
import { PaymentHolder } from '../../components';
import {
  Grid,
  Header,
  Icon,
  Card,
  Message,
  Button,
  Modal,
} from 'semantic-ui-react';
import './payment.scss';
import history from '../../history';
import BrainTreeDropIn from './brainTreeDropIn';
import { AvRadioGroup, AvRadio, AvForm } from 'availity-reactstrap-validation';
import {
  membershipPayment,
  getMembershipTypes,
  getUserById,
} from '../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';
var generatePassword = require('password-generator');

class Payment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      membership: {},
      card: true,
      year: moment().year(),
      form: {
        paymentType: 'CARD',
      },
      member: null,
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  async componentWillMount() {
    try {
      const { getMembershipTypes, match, getUserById } = this.props;
      await getMembershipTypes();
      const membershipType = await _.find(this.props.membership, (o) => {
        return o.id === parseInt(match.params.id, 10); //get value fron props.match
      });
      await getUserById(match.params.memberId);
      this.setState({
        membership: membershipType,
        refId: this.renderRef(),
      });
    } catch (error) {
      console.log('>: Payment -> componentDidMount -> error', error);
    }
  }

  handleValidSubmit(event, values) {
    this.setState({ paymentDetails: values }, async () => {
      try {
        const {
          membershipPayment,
          match,
        } = this.props;
        const { refId } = this.state;
        // const userPaymentDetails = userRegister || errorResponse.user;
        const membershipType = this.state.membership;
        if (values.paymentType === 'BANK_TRANSFER') {
          await membershipPayment({
            paymentType: 'MEMBERSHIP',
            amount: membershipType.amount,
            userId: match.params.memberId,
            membershipTypeId: membershipType.id,
            paymentMode: values.paymentType,
            bankReferenceNumber: refId,
          });
        }
        history.push(`/membership/payment/success/${membershipType.id}`);
      } catch (error) {
        console.log('>: Payment -> handleValidSubmit -> error', error);
      }
    });
  }

  onRadioChange(card) {
    this.setState({
      card: card,
    });
  }

  modal(open) {
    this.setState({
      open: open,
    });
  }

  renderRef() {
    return generatePassword(10, false, /\d/, 'RCB');
  }

  render() {
    const { membership, card, open, form, refId } = this.state;
    const { paymentLoading, match } = this.props;
    // const userPaymentDetails = userRegister || errorResponse.user;
    return (
      <PaymentHolder>
        <Grid padded className="payement-form">
          <Grid.Row className="header-row">
            <Header>
              <Icon name="lock" />
              SECURED PAYMENT
            </Header>
          </Grid.Row>
          {/* {match.params.renew === 'true' ? (
            <Grid.Row className="membership-type">
              <Grid.Column>
                <Card>
                  <Card.Content>
                    <Card.Header>MEMBERSHIP RENEWAL</Card.Header>
                    <Card.Meta>
                      Your membership expires on 30 June {this.state.year}. Renew your
                      annual membership to continue being part of Royal College
                      Old Boys in Australia Association.
                    </Card.Meta>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
          ) : null} */}
          <Grid.Row className="membership-type">
            <Grid.Column computer={8} tablet={8} mobile={8}>
              <Card>
                <Card.Content>
                  <Card.Header>
                    {!_.isEmpty(this.props.user)
                      ? this.props.user.firstName
                        ? this.props.user.firstName
                        : '-'
                      : '-'}
                  </Card.Header>
                  <Card.Meta>{membership.title} COST </Card.Meta>
                  <Card.Description>${membership.amount}</Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={8}></Grid.Column>
          </Grid.Row>
          <Grid.Row className="payment-details">
            <Grid.Column>
              <Header>Payment Details</Header>
              <AvForm
                onValidSubmit={card ? null : this.handleValidSubmit}
                model={form}
                className={`${
                  match.params.renew === 'true' ? 'av-from' : null
                }`}
              >
                <AvRadioGroup
                  style={{ fontSize: '18px' }}
                  label="Payment Mode"
                  inline
                  name="paymentType"
                  required
                  errorMessage="Pick one!"
                >
                  <Modal size="tiny" centered>
                    <Modal.Content>
                      <BrainTreeDropIn
                        getNonce={(nonce) => this.getNonce(nonce)}
                      />
                    </Modal.Content>
                  </Modal>
                  <AvRadio
                    onChange={() => this.onRadioChange(true)}
                    label="VISA/Mastercard"
                    value="CARD"
                    disabled={match.params.renew === 'true' ? true : false}
                  />
                  {match.params.renew === 'true' ? null : (
                    <AvRadio
                      onChange={() => this.onRadioChange(false)}
                      label="Bank Transfer"
                      value="BANK_TRANSFER"
                    />
                  )}
                </AvRadioGroup>
                {card ? null : (
                  <>
                    <label>Reference Number</label>
                    <br />
                    <label className="bold-letter">
                      {refId}
                      {/* {userPaymentDetails.bankTransferReferenceNumber} */}
                    </label>
                    <Message>
                      <p>
                        Make a bank transfer for the mentioned amount and enter
                        your unique reference number in the description.
                        <br />
                        <br />
                        <b>Account holder: Rcobaa</b>
                        <br />
                        <b>Rcobaa BSB: 753819</b>
                        <br />
                        <b>Account Number: 18531383</b>
                      </p>
                    </Message>
                  </>
                )}
                {card ? (
                  <Modal
                    size="tiny"
                    centered
                    trigger={
                      <Button
                        loading={paymentLoading}
                        className={`${
                          match.params.renew === 'true'
                            ? 'payment-button'
                            : null
                        }`}
                      >
                        {match.params.renew === 'true'
                          ? 'RENEW MEMBERSHIP'
                          : 'BECOME A MEMBER'}
                      </Button>
                    }
                    open={open}
                    onOpen={() => this.modal(true)}
                    onClose={() => this.modal(false)}
                  >
                    <Modal.Content>
                      <BrainTreeDropIn
                        type="event"
                        match={match}
                        modal={(open) => this.modal(open)}
                        // getNonce={nonce => this.getNonce(nonce)}
                      />
                    </Modal.Content>
                  </Modal>
                ) : (
                  <Button loading={paymentLoading}>
                    {match.params.renew === 'true'
                      ? 'RENEW MEMBERSHIP'
                      : 'BECOME A MEMBER'}
                  </Button>
                )}
              </AvForm>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </PaymentHolder>
    );
  }
}

const mapStateToProps = (state) => {
  const { members, auth } = state;
  return {
    membership: members.membership,
    membershipLoading: members.membershipLoading,
    paymentLoading: members.paymentLoading,
    userRegister: auth.userRegister,
    errorResponse: auth.errorResponse,
    member: members.member,
    user: members.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      membershipPayment,
      getMembershipTypes,
      getUserById,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
