import React from "react";
import { Card, Button, Header, Image } from "semantic-ui-react";
import { isMobileOnly } from "react-device-detect";
import _ from "lodash";
import history from "../../history";
import moment from "moment";
import "./ticket.scss";
import { toast } from "react-toastify";

export class Ticket extends React.PureComponent {
  renderCard(types, type) {
    return _.map(types, (element, i) => {
      // TODO: add validations to element values
      const timeString12hr = new Date('1970-01-01T' + (element.time || '') + 'Z')
      .toLocaleTimeString({},
        {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
      );
      const eventDisabled =
        type === "events" &&
        element.numberOfTickets <=
          _.get(element, "ItemPayments[0].ticketSold", 0);
      return (
        <Card key={i}>
          {type !== "membership"? <Image className="event-image-container" src={element.eventImage} wrapped ui={true} /> : null}
          <Card.Content>
            <Card.Header>
              {element.title}
              {type !== "membership" && element.date && element.time? (
                <Header sub className="set-time">
                  {moment(element.date).format("DD MMMM YYYY")} | {timeString12hr}
                </Header>
              ) : null}
            </Card.Header>
            {type !== "membership"? 
              <Card.Meta className="event-locate">
                <label className="event-venue">{element.venueName? element.venueName : ''}</label><br/>
                <label className="event-address">{`${element.streetName? element.streetName+',' : ''} ${element.suburb? element.suburb+',' : ''} ${element.state? element.state+',' : ''} ${element.postCode? element.postCode : ''}`}</label>
                <br/><br/>
              </Card.Meta>
            : null}
            <Card.Meta className="event-description">{element.description}</Card.Meta>
            <Card.Description>
              {type === "membership"
                ? element.value
                : element.ticketPrice === 0
                ? "FREE"
                : "$" + element.ticketPrice}
            </Card.Description>
            <Button
              onClick={() => this.changeRoute(type, element)}
              disabled={eventDisabled}
            >
              {type === "membership"
                ? "SELECT MEMBERSHIP"
                : eventDisabled
                ? "TICKETS SOLD OUT"
                : element.ticketPrice === 0
                ? "GET PASS"
                : "BUY TICKETS"}
            </Button>
          </Card.Content>
        </Card>
      );
    });
  }

  async changeRoute(type, record) {
    try {
      switch (type) {
        case "membership":
          history.push(`/register/${record.id}`);
          break;
        case "events":
          const auth_token = localStorage.getItem("auth_token");
          const user_id = localStorage.getItem("user_id");
          if (record.accessType !== "public" && !auth_token) {
            toast.warn("Please login to continue!");
            history.push("/login");
          } else {
            let params = {
              eventId: record.id
            };
            if(user_id){
              params.userId = user_id
            }
            await this.props.eventValidation(params);
            if (this.props.eventValidationVal.accessValidation) {
              history.push(`/events/payment/${record.id}`);
            } else {
              toast.info(
                `This event is only accessible for ${this.props.eventValidationVal.group}`
              );
            }
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("TCL: Ticket -> changeRoute -> error", error);
    }
  }
  render() {
    const { types, type, loading = false, to } = this.props;

    return loading ? (
      <div className="ticket-card-loading">
        <div className="ticket-card-loader" />
      </div>
    ) : (
      <Card.Group className="ticket-card" itemsPerRow={isMobileOnly ? 1 : 3}>
        {this.renderCard(types, type, to)}
      </Card.Group>
    );
  }
}
