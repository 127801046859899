import React from "react";
import { AvForm, AvField} from "availity-reactstrap-validation";
import { Grid, Button, Modal, Dropdown,Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    updatePayment
} from "../../store/actions";
import "./updatePayment.scss";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
class UpdatePayment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal:false,
      date: moment().utc().format('YYYY-MM-DD')
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  async handleValidSubmit(event, values) {
    const { userId,membership,updatePayment, getAllMembers,page} = this.props;
      
        try {
           if(values.paymentMode==="BANK_TRANSFER" && values.referenceNumber===""){
               toast.error("bank reference number is required")
           }else{
            let id = parseInt(values.membershipType);
            const amount = _.find(membership, { id });

            let payment={
                paymentType: "MEMBERSHIP",
                amount: amount.amount,
                userId: userId,
                membershipTypeId: values.membershipType,
                paymentMode: values.paymentMode,
                
              }
              if( values.paymentMode==="BANK_TRANSFER"){
                  payment.bankReferenceNumber= values.referenceNumber
              }
            
             await updatePayment(payment);

            this.toggle();
            await getAllMembers(page);
            }
        } catch (error) {
        console.log(">: Register -> handleValidSubmit -> error", error);
        }
        
  }

  renderMembershipTypes() {
    const { membership } = this.props;
    return (
      <>
        <option></option>
        {membership.map((item, i) => {
          return (
            <option key={i} value={item.id}>
              {item.title}
            </option>
          );
        })}
      </>
    );
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  formRef(c) {
    this.form = c;
  }

  render() {
    const {membershipType, modal, date } = this.state;
    const {  paymentLoading} = this.props;
    return (
      <>
       <Modal
        centered
        trigger={<Dropdown.Item text="Update Payment" />}
        open={modal}
        onOpen={() => this.toggle()}
        className="updateModal"
      >
        <Modal.Content>
          <div className="modal-header-div">
           Update Membership Payment
            <Icon
              className="close-icon"
              name="close"
              onClick={() => this.toggle()}
            />
          </div>
          <AvForm
          onValidSubmit={this.handleValidSubmit}
          className="register-form"
          ref={c => this.formRef(c)}
        >
          <Grid columns={2} stackable className="paymentForm">
            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="membershipType"
                  label="Membership Type"
                  type="select"
                  value={membershipType}
                  errorMessage="Membership type is required"
                  validate={{
                    required: { value: true }
                  }}
                >
                  {this.renderMembershipTypes()}
                </AvField>
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                {" "}
                <AvField
                  name="DOB"
                  label="Payment Received Date "
                  type="date"
                  value={date}
                  errorMessage="Payment received date is required"
                  validate={{
                    required: { value: true }
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="paymentMode"
                  label="Mode of Payment"
                  type="select"
                  errorMessage="Payment mode is required"
                  validate={{
                    required: { value: true }
                  }}
                > <option></option>
                  <option value="BANK_TRANSFER">Bank Transfer</option>
                  <option value="CASH">Cash</option>
                </AvField>
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="referenceNumber"
                  label="Reference Number (if applicable)"

                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="btn-row">
              <div>
                <Button loading={ paymentLoading}>
                  Update Payment
                </Button>
              </div>
              <br />
              
            </Grid.Row>
          </Grid>
        </AvForm>
        </Modal.Content>
      </Modal>
       
      </>
    );
  }
}

const mapStateToProps = state => {
  const { members } = state;
  return {
    paymentLoading: members.paymentLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updatePayment
  }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)( UpdatePayment);
