import React from "react";
import { Grid, Image } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import "../../components/homePage/header.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUser, logOut } from "../../store/actions";
import history from "../../history";

class Header extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      log: 'login'
    }
  }
  
  async componentDidMount() {
    const authToken = localStorage.getItem("auth_token");
    if (authToken) {
      const { getUser } = this.props;
      await getUser();
      
    }
  }
  componentDidUpdate(prevProps, prevState){
  if(this.state.log !== prevState.log){
    this.setState({
      log: this.state.log
    })
  }
  }

  renderUser() {
    const { user } = this.props;
    const userType = localStorage.getItem("user_type");
    if (user) {
      if (userType === "1") {
        this.setState({
          log: 'Logout'
        })
        return "Admin";
      } else if (userType === "2") {
        this.setState({
          log: 'Logout'
        })
        return user.firstName + " " + user.lastName;
      } else {
        this.setState({
          log: 'Login'
        })
        return "Become a Member";
      }
    } else {
      this.setState({
        log: 'Login'
      })
      return "Become a Member";
    }
  }

  renderLogin() {
    const { user } = this.props;
    const userType = localStorage.getItem("user_type");
    if (user) {
      if (userType === "1") {
        return "Logout";
      } else if (userType === "2") {
        return "Logout";
      } else {
        return "Login";
      }
    } else {
      return "Login";
    }
  }

  userClick() {
    const { user } = this.props;
    const userType = localStorage.getItem("user_type");
    if (user) {
      if (userType === "1") {
        history.push("/admin/manageMembers");
      } else if (userType === "2") {
        history.push("#");
        return user.firstName + " " + user.lastName;
      } else {
        history.push("/register");
      }
    } else {
      history.push("/register");
    }
  }

  userLogin() {
    const { user } = this.props;
    const userType = localStorage.getItem("user_type");
    if (user) {
      if (userType === "1") {
        this.logOutUser();
      } else if (userType === "2") {
        this.logOutUser();
      } else {
        history.push("/login");
      }
    } else {
      history.push("/login");
    }
  }

  logOutUser() {
    const { logOut } = this.props;
    this.setState({
      log:'Login'
    }, () => {
      logOut();
    });
  }

  render() {
    const { path, backgroundPage } = this.props;
    const { log } = this.state;
    return (
      <div className={backgroundPage? 'background-page-header' : "header-banner"}>
        <Grid>
          <Grid.Row>
            {backgroundPage? null : 
            <Grid.Column mobile={16} tablet={10} computer={5}>
              <div className="left-navigation">
                <Image
                  src={require("../../assets/rcobawaLogo.png")}
                  size={isMobile ? "small" : "medium"}
                  centered
                />
                <div className="navigation-items">
                  <a
                    href="/"
                    className={`nav-item ${path === "home" ? "active" : null}`}
                  >
                    Home
                  </a>
                  <a
                    href="/membership#membership"
                    className={`nav-item ${
                      path === "membership" ? "active" : null
                    }`}
                  >
                    Membership
                  </a>
                  <a
                    href="/events#events"
                    className={`nav-item ${
                      path === "events" ? "active" : null
                    }`}
                  >
                    Events
                  </a>
                  <a
                    href="/media/0#media"
                    className={`nav-item ${path === "media" ? "active" : null}`}
                  >
                    Media
                  </a>
                  <a
                    href="/about#about"
                    className={`nav-item ${path === "about" ? "active" : null}`}
                  >
                    About
                  </a>
                  <a
                    href="/contact#contact"
                    className={`nav-item ${
                      path === "contact" ? "active" : null
                    }`}
                  >
                    Contact
                  </a>
                </div>
              </div>
            </Grid.Column>}
            <Grid.Column mobile={16} tablet={10} computer={11}>
              <div className="login-nav">
                <div onClick={() => this.userClick()}>{this.renderUser()}</div>
                <div onClick={() => this.userLogin()}>{log}</div>
              </div>
              {backgroundPage? null : <div className="slogan-div">
                <p>RAISE YOUR VOICE FOR ROYAL</p>
              </div>}
              
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    ...auth
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getUser,
      logOut
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
