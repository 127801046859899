import React from "react";
import { Grid, Segment, Image } from "semantic-ui-react";
import "./paymentHolder.scss";
import history from "../../../history";

export const PaymentHolder = props => {
  const { children, admin } = props;
  return (
    <Grid padded className={admin? "payment-holder-no-background" : "payment-holder"}>
      <Segment>
        <Grid padded>
          {admin ? null : (
            <Grid.Row>
              <Image
                onClick={() => history.push("/")}
                src={require("../../../assets/rcobawaLogo.png")}
              />
            </Grid.Row>
          )}

          <Grid.Row className="children-content">{children}</Grid.Row>
        </Grid>
      </Segment>
    </Grid>
  );
};
