import axios from "axios";
import { toast } from "react-toastify";
import { showError } from "../../utils";
import { CREATED_MSG, UPDATED_MSG, DELETED_MSG, LIMIT } from "../../constant";

const ALL_ITEMS = "GET_ALL_ITEMS";
const GET_ALL_ITEMS_LOADING = "GET_ALL_ITEMS_LOADING";
const MANAGE_ITEM_LOADING = "MANAGE_ITEM_LOADING";
const SET_ITEMS_LOADING = "SET_ITEMS_LOADING";
const SET_ITEM_TYPES = "SET_ITEM_TYPES";
const SET_UPCOMING_ITEM = "SET_UPCOMING_ITEM";
const GET_EVENT_BY_ID_LOADING = "GET_EVENT_BY_ID_LOADING";
const EVENT = "EVENT";
const EVENT_PAYMENT_LOADING = "EVENT_PAYMENT_LOADING";
const EVENT_PAYMENT = "EVENT_PAYMENT";
const EVENT_VALIDATION = "EVENT_VALIDATION";
const PAYMENT_RECEIPT = "PAYMENT_RECEIPT";
const GET_ALL_PAST_ITEMS_LOADING = "GET_ALL_PAST_ITEMS_LOADING";
const ALL_PAST_ITEMS = "ALL_PAST_ITEMS";
const EVENT_PAYMENT_ADMIN = "EVENT_PAYMENT_ADMIN";
const EVENT_PAYMENT_ADMIN_LOADING = "EVENT_PAYMENT_ADMIN_LOADING";
const PAYMENT_RECEIPT_LOADING = "PAYMENT_RECEIPT_LOADING";
const SHOP_EXPORT_CSV = "SHOP_EXPORT_CSV";
const SHOP_EXPORT_CSV_LOADING = "SHOP_EXPORT_CSV_LOADING";
const TICKETS_GENERATED = "TICKETS_GENERATED";
const TICKETS_GENERATED_LOADING = "TICKETS_GENERATED_LOADING";

const initialState = {
  itemLoading: {},
  getAllItemsLoading: false,
  allItems: [],
  manageItemLoading: false,
  itemTypes: [],
  upcomingItems: [],
  getEventByIdLoading: false,
  event: {},
  eventPaymentLoading: false,
  eventPayment: [],
  eventValidationVal: {},
  paymentReceipt: {},
  eventPaymentAdminLoading: false,
  paymentReceiptLoading: false,
  shopExportCsv: {},
  shopExportCsvLoading: false,
  ticketsGenerated: {},
  ticketsGeneratedLoading: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ITEMS_LOADING:
      return {
        ...state,
        getAllItemsLoading: action.payload
      };
    case ALL_ITEMS:
      return {
        ...state,
        allItems: action.payload.docs,
        pages: action.payload.pages,
        count: action.payload.total
      };
    case MANAGE_ITEM_LOADING:
      return {
        ...state,
        manageItemLoading: action.payload
      };
    case SET_ITEM_TYPES:
      return {
        ...state,
        itemTypes: action.payload
      };
    case SET_ITEMS_LOADING:
      return {
        ...state,
        itemLoading: {
          [action.payload.type]: action.payload.status
        }
      };
    case SET_UPCOMING_ITEM:
      return {
        ...state,
        upcomingItems: action.payload
      };
    case GET_EVENT_BY_ID_LOADING:
      return {
        ...state,
        getEventByIdLoading: action.payload
      };
    case EVENT:
      return {
        ...state,
        event: action.payload
      };
    case EVENT_PAYMENT_LOADING:
      return {
        ...state,
        eventPaymentLoading: action.payload
      };
    case EVENT_PAYMENT:
      return {
        ...state,
        eventPayment: action.payload
      };
    case EVENT_VALIDATION:
      return {
        ...state,
        eventValidationVal: action.payload
      };
    case PAYMENT_RECEIPT:
      return {
        ...state,
        paymentReceipt: action.payload
      };
    case GET_ALL_PAST_ITEMS_LOADING:
      return {
        ...state,
        pastEventsLoading: action.payload
      };
    case ALL_PAST_ITEMS:
      return {
        ...state,
        pastEvents: action.payload.docs,
        pastEventsCount: action.payload.total,
        pastEventsPages: action.payload.pages
      };
    case EVENT_PAYMENT_ADMIN:
      return {
        ...state,
        eventPaymentAdmin: action.payload
      };
    case EVENT_PAYMENT_ADMIN_LOADING:
      return {
        ...state,
        eventPaymentAdminLoading: action.payload
      };
    case PAYMENT_RECEIPT_LOADING:
      return {
        ...state,
        paymentReceiptLoading: action.payload
      };
    case SHOP_EXPORT_CSV:
      return {
        ...state,
        shopExportCsv: action.payload
      };
    case SHOP_EXPORT_CSV_LOADING:
      return {
        ...state,
        shopExportCsvLoading: action.payload
      };
    case TICKETS_GENERATED:
      return {
        ...state,
        ticketsGenerated: action.payload
      };
    case TICKETS_GENERATED_LOADING:
      return {
        ...state,
        ticketsGeneratedLoading: action.payload
      };
    default:
      return state;
  }
}

export function getAllItems(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: GET_ALL_ITEMS_LOADING, payload: true });
        const params = {
          limit: LIMIT,
          offset: data ? (data.page ? data.page : 1) : 1,
          orderBy: "DESC",
          type: data ? (data.type ? data.type : null) : null
        };
        const response = await axios.get("/items", {
          params
        });
        dispatch({ type: ALL_ITEMS, payload: response.data });
        dispatch({ type: GET_ALL_ITEMS_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: GET_ALL_ITEMS_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function deleteItem(id, getAll) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: MANAGE_ITEM_LOADING, payload: true });
        await axios.delete(`/items/${id}`);
        await dispatch(getAllItems(getAll));
        dispatch({ type: MANAGE_ITEM_LOADING, payload: false });
        resolve();
        toast.success(DELETED_MSG);
      } catch (error) {
        showError(error);
        dispatch({ type: MANAGE_ITEM_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function createItem(data, getAll) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: MANAGE_ITEM_LOADING, payload: true });
        await axios.post("/items", data);
        await dispatch(getAllItems(getAll));
        dispatch({ type: MANAGE_ITEM_LOADING, payload: false });
        toast.success(CREATED_MSG);
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: MANAGE_ITEM_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function editItem(data, getAll) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: MANAGE_ITEM_LOADING, payload: true });
        await axios.put("/items", data);
        await dispatch(getAllItems(getAll));
        dispatch({ type: MANAGE_ITEM_LOADING, payload: false });
        toast.success(UPDATED_MSG);
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: MANAGE_ITEM_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function getItemTypes(type = "itemTypes") {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: SET_ITEMS_LOADING,
        payload: { type, status: true }
      });
      const response = await axios.get(`/item-types`);
      dispatch({ type: SET_ITEM_TYPES, payload: response.data });
      dispatch({
        type: SET_ITEMS_LOADING,
        payload: { type, status: false }
      });
    } catch (error) {
      showError(error);
      dispatch({
        type: SET_ITEMS_LOADING,
        payload: { type, status: false }
      });
    }
  };
}

export function getUpcomingItems(type = "upcomingItems") {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: SET_ITEMS_LOADING,
        payload: { type, status: true }
      });
      const response = await axios.get(`/items/upComping`);
      dispatch({ type: SET_UPCOMING_ITEM, payload: response.data });
      dispatch({
        type: SET_ITEMS_LOADING,
        payload: { type, status: false }
      });
    } catch (error) {
      showError(error);
      dispatch({
        type: SET_ITEMS_LOADING,
        payload: { type, status: false }
      });
    }
  };
}

export function getEventByEventId(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: GET_EVENT_BY_ID_LOADING, payload: true });
        const response = await axios.get(`/items/${data}`);
        dispatch({ type: EVENT, payload: response.data });
        dispatch({ type: GET_EVENT_BY_ID_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: GET_EVENT_BY_ID_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function eventTicketPayment(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: EVENT_PAYMENT_LOADING, payload: true });
        const response = await axios.post("/payment/items", data);
        dispatch({ type: EVENT_PAYMENT, payload: response.data });
        dispatch({ type: EVENT_PAYMENT_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: EVENT_PAYMENT_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function eventValidation(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch({ type: EVENT_PAYMENT_LOADING, payload: true });
        const response = await axios.post("/payment/eventValidation", data);
        dispatch({ type: EVENT_VALIDATION, payload: response.data });
        // dispatch({ type: EVENT_PAYMENT_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        // dispatch({ type: EVENT_PAYMENT_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function getEventPaymentReceipt(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: PAYMENT_RECEIPT_LOADING, payload: true });
        const response = await axios.get(
          `/payment/getTickets/?itemPaymentId=${data.itemPaymentId}&eventId=${data.eventId}`
        );
        dispatch({ type: PAYMENT_RECEIPT, payload: response.data });
        dispatch({ type: PAYMENT_RECEIPT_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: PAYMENT_RECEIPT_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function getPastEvents(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: GET_ALL_PAST_ITEMS_LOADING, payload: true });
        const params = {
          limit: LIMIT,
          offset: data ? (data.page ? data.page : 1) : 1,
          orderBy: "DESC",
          type: data ? (data.type ? data.type : null) : null
        };
        const response = await axios.get("/items/manage", {
          params
        });
        dispatch({ type: ALL_PAST_ITEMS, payload: response.data });
        dispatch({ type: GET_ALL_PAST_ITEMS_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: GET_ALL_PAST_ITEMS_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function buyTicketsFromAdminPanel(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: EVENT_PAYMENT_ADMIN_LOADING, payload: true });
        const response = await axios.post("/payment/items/cash", data);
        dispatch({ type: EVENT_PAYMENT_ADMIN, payload: response.data });
        dispatch({ type: EVENT_PAYMENT_ADMIN_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: EVENT_PAYMENT_ADMIN_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function exportShopItemsCsv(data) {
  return async dispatch => {
    const params = {
      date: data.date
    };
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SHOP_EXPORT_CSV_LOADING, payload: true });
        const response = await axios.get("/payment/items/export", { params });
        dispatch({
          type: SHOP_EXPORT_CSV,
          payload: response.data
        });
        dispatch({ type: SHOP_EXPORT_CSV_LOADING, payload: false });
        toast.success('Exported Successfully!');
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: SHOP_EXPORT_CSV_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function getTicketsGenerated(itemId, data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: TICKETS_GENERATED, payload: {} });
        dispatch({ type: TICKETS_GENERATED_LOADING, payload: true });
        const params = {
          limit: LIMIT,
          offset: data ? (data.page ? data.page : 1) : 1,
          orderBy: "DESC"
        };
        const response = await axios.get(`/item/${itemId}/tickets-purchased`, {
          params
        });
        dispatch({ type: TICKETS_GENERATED, payload: response.data });
        dispatch({ type: TICKETS_GENERATED_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: TICKETS_GENERATED_LOADING, payload: false });
        reject();
      }
    });
  };
}