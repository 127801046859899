import axios from 'axios';
import { toast } from 'react-toastify';
import { CREATED_MSG, REACT_APP_HIDE_LANDING_REDIRECT } from '../../constant';
import { showError } from '../../utils';
import jwtDecode from 'jwt-decode';
import * as FullStory from '@fullstory/browser';

const AUTH_REGISTER_LOADING = 'AUTH_REGISTER_LOADING';
const AUTH_REGISTER = 'AUTH_REGISTER';
const AUTH_LOGIN_LOADING = 'AUTH_LOGIN_LOADING';
const FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING';
const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING';
const GET_USER_LOADING = 'GET_USER_LOADING';
const USER = 'USER';
const ERROR_RESPONSE = 'ERROR_RESPONSE';

const initialState = {
  authRegisterLoading: false,
  authLoginLoading: false,
  forgotPasswordLoading: false,
  resetPasswordLoading: false,
  getUserLoading: false,
  user: {},
  errorResponse: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_REGISTER_LOADING:
      return {
        ...state,
        authRegisterLoading: action.payload,
      };
    case AUTH_LOGIN_LOADING:
      return {
        ...state,
        authLoginLoading: action.payload,
      };
    case FORGOT_PASSWORD_LOADING:
      return {
        ...state,
        forgotPasswordLoading: action.payload,
      };
    case RESET_PASSWORD_LOADING:
      return {
        ...state,
        resetPasswordLoading: action.payload,
      };
    case GET_USER_LOADING:
      return {
        ...state,
        getUserLoading: action.payload,
      };
    case USER:
      return {
        ...state,
        user: action.payload,
      };
    case AUTH_REGISTER:
      return {
        ...state,
        userRegister: action.payload,
      };
    case ERROR_RESPONSE:
      return {
        ...state,
        errorResponse: action.payload,
      };
    default:
      return state;
  }
}

export function initalizeApp() {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const authToken = await localStorage.getItem('auth_token');

        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        resolve();
      } catch (error) {
        reject();
      }
    });
  };
}

export function register(data) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: AUTH_REGISTER_LOADING, payload: true });
      try {
        let formData = {
          firstName: data.firstName,
          lastName: data.lastName,
          DOB: data.DOB,
          email: data.email,
          phoneNumber: data.phoneNumber,
          batch: data.batch,
          streetName: data.streetName,
          suburb: data.suburb,
          state: data.state,
          postCode: data.postCode,
          deceasedStatus: data.deceasedStatus ? data.deceasedStatus : false,
          membershipType: data.membershipType,
        };

        if (data.spouse) {
          formData.spouse = data.spouse;
        }

        const response = await axios.post('/users', formData);
        toast.success(CREATED_MSG);
        dispatch({ type: AUTH_REGISTER, payload: response.data });
        dispatch({ type: AUTH_REGISTER_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: AUTH_REGISTER_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function ssoLogin(token) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const decodedToken = jwtDecode(token);
        await localStorage.setItem('auth_token', token);
        await localStorage.setItem('user_type', decodedToken.userType);
        await localStorage.setItem('user_id', decodedToken.id);
        FullStory.identify(decodedToken.id);
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${token}`;
        resolve();
      } catch (error) {
        showError(error);
        reject();
      }
    });
  };
}

export function userLogin(data) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: AUTH_LOGIN_LOADING, payload: true });
      try {
        data = {
          username: data.email,
          password: data.password,
        };
        const response = await axios.post('/users/login', data);
        const decodedToken = jwtDecode(response.data.token);
        await localStorage.setItem('auth_token', response.data.token);
        await localStorage.setItem('user_type', decodedToken.userType);
        await localStorage.setItem('user_id', decodedToken.id);
        FullStory.identify(decodedToken.id);
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${response.data.token}`;
        dispatch({ type: AUTH_LOGIN_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({
          type: ERROR_RESPONSE,
          payload: error.response ? error.response.data : null,
        });
        dispatch({ type: AUTH_LOGIN_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function forgotPassword(data) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: FORGOT_PASSWORD_LOADING, payload: true });
        data = {
          email: data.email,
        };
        const response = await axios.post('/users/forgetPassword', data);
        toast.success(response.data.message);
        dispatch({ type: FORGOT_PASSWORD_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: FORGOT_PASSWORD_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function welcomeEmail(data) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post('/users/welcomeEmail', data);
        toast.success(response.data);
        resolve();
      } catch (error) {
        showError(error);
        reject();
      }
    });
  };
}

export function paymentReceipt(data) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post('/users/paymentReceipt', data);
        toast.success(response.data);
        resolve();
      } catch (error) {
        showError(error);
        reject();
      }
    });
  };
}

export function resetPassword(data) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: RESET_PASSWORD_LOADING, payload: true });
        data = {
          token: data.token,
          password: data.password,
        };
        const response = await axios.post('/users/resetPassword', data);
        toast.success(response.data.message);
        dispatch({ type: RESET_PASSWORD_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: RESET_PASSWORD_LOADING, payload: false });
        reject();
      }
    });
  };
}

export function logOut() {
  return async (dispatch) => {
    try {
      await localStorage.clear();
      axios.defaults.headers.common['Authorization'] = null;
      if(REACT_APP_HIDE_LANDING_REDIRECT){
        window.location.href = `${REACT_APP_HIDE_LANDING_REDIRECT}/?logout=true`;
      }
    } catch (error) {
      console.log('>: logout -> error', error);
    }
  };
}

export function getUser() {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: GET_USER_LOADING, payload: true });
      try {
        const response = await axios.get('/users/me');
        FullStory.identify(response.data.id, {
          id: response.data.id,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          membershipType: response.data.membershipType,
        });
        dispatch({ type: USER, payload: response.data });
        dispatch({ type: GET_USER_LOADING, payload: false });
        resolve();
      } catch (error) {
        showError(error);
        dispatch({ type: GET_USER_LOADING, payload: false });
        reject();
      }
    });
  };
}
