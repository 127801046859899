import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import './header.scss';

export class Header extends React.PureComponent {
  render() {
    const { path } = this.props;

    return (
      <div className="header-banner">
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={10} computer={5}>
              <div className="left-navigation">
                <Image 
                  src={require('../../assets/rcobawaLogo.png')}
                  size={isMobile ? 'small' : 'medium'}
                  centered
                />
                <div className="navigation-items">
                  <a
                    href="/"
                    className={`nav-item ${path === 'home' ? 'active' : null}`}
                  >
                    Home
                  </a>
                  <a
                    href="/membership#membership"
                    className={`nav-item ${
                      path === 'membership' ? 'active' : null
                    }`}
                  >
                    Membership
                  </a>
                  <a
                    href="/events#events"
                    className={`nav-item ${
                      path === 'events' ? 'active' : null
                    }`}
                  >
                    Events
                  </a>
                  <a
                    href="/media/0#media"
                    className={`nav-item ${path === 'media' ? 'active' : null}`}
                  >
                    Media
                  </a>
                  <a
                    href="/about#about"
                    className={`nav-item ${path === 'about' ? 'active' : null}`}
                  >
                    About
                  </a>
                  <a
                    href="/contact#contact"
                    className={`nav-item ${
                      path === 'contact' ? 'active' : null
                    }`}
                  >
                    Contact
                  </a>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={10} computer={11}>
              <div className="login-nav">
                <a href="/register">Become a Member</a>
                <a href="/login">Login</a>
              </div>
              <div className="slogan-div">
                <p>RAISE YOUR VOICE FOR ROYAL</p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
