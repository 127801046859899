import React from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { 
  Grid, 
  Button, 
  Icon, 
  Modal, 
  Image, 
  // Radio 
} from "semantic-ui-react";
import cryptoRandomString from "crypto-random-string";
import S3 from "aws-s3";
import _ from "lodash";
import moment from "moment";
import "./shop.scss";
import { AWSConfig } from "../../constant";
import { toast } from "react-toastify";
import { getAllGroups } from "../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import Cropper from "react-cropper";
// import "cropperjs/dist/cropper.css";

const S3Client = new S3(AWSConfig);

export class ItemForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      eventImage: "",
      thumbnailLoading: false,
      isMain: false
    };
    this.cropper = React.createRef();
  }

  async handleValidSubmit(event, values) {
    const { createItem, editItem, isNew, item, getAll, toggle } = this.props;
    // const rdmString = cryptoRandomString({ length: 30, type: "url-safe" });
    // let imageFile = "";
    this.setState({
      btnLoading: true
    });
    // if(this.state.imageFile){
    //   await S3Client.uploadFile(this.state.imageFile, `item-${rdmString}`)
    //     .then(data => {
    //       imageFile = data.location;
    //       this.setState({
    //         eventImage: data.location,
    //         thumbnailLoading: false
    //       });
    //     })
    //     .catch(err => console.error("err", err));
    //     values.eventImage = imageFile;
    // }else{
    //   values.eventImage = item.eventImage;
    // }
    values.eventImage = this.state.eventImage? this.state.eventImage : item.eventImage;
    this.setState({
      btnLoading: false
    });
    values.itemTypeId = 1;
    values.isMain = this.state.isMain;
    try {
      if (moment(values.date).diff(moment().subtract(1, "d")) < 0) {
        toast.warn("Please select a date in the future!");
      } else {
        if (isNew) {
          await createItem(values, getAll);
          // console.log("TCL: ItemForm -> handleValidSubmit -> values", values)
          toggle(false);
        } else {
          values.itemId = item.id;
          await editItem(values, getAll);
          // console.log("TCL: ItemForm -> handleValidSubmit -> values", values)
          toggle(false);
        }
      }
    } catch (error) {
      console.log("TCL: ItemForm -> handleValidSubmit -> error", error);
    }
  }

  componentDidMount() {
    let { item = {}, getAllGroups } = this.props;
    if (!_.isEmpty(item)) {
      this.setState({
        eventImage:
          item.eventImage !== "" && item.eventImage !== null
            ? item.eventImage
            : ""
      });
      // this.setState({
      //   isMain: this.props.item.isMain
      // });
    }
    getAllGroups();
  }

  triggerInputFile = () => {
    this.fileInput.click();
    this.setState({
      inputTriggered: true
    });
  };

  onImageChange = async (event) => {
    try {
      this.setState({
        thumbnailLoading: true
      });
      const file = event.target.files;
      const rdmString = cryptoRandomString({ length: 30, type: "url-safe" });
      const data = await S3Client.uploadFile(file[0], rdmString);
      this.setState({
        eventImage: data.location,
        thumbnailLoading: false
      });
    } catch (error) {
      console.log("TCL: ContentForm -> onImageChange -> error", error);
      this.setState({
        thumbnailLoading: false
      });
    }
  };

  renderGroups() {
    const { allGroups } = this.props;

    return allGroups.map((item, i) => {
      return item ? (
        <option key={i} value={item.name}>
          {item.name}
        </option>
      ) : null;
    });
  }

  renderEventTimes() {
    const time = moment().startOf("day");
    const durations = new Array(24 * 2).fill(0).map((_, i) => `${i}`); //no of half hour durations within a day
    return durations.map((item, i) => {
      return (
        <option
          key={i}
          value={moment(time)
            .add(30 * i, "minutes")
            .format("HH:mm:ss")}
        >
          {moment(time)
            .add(30 * i, "minutes")
            .format("h.mm a")}
        </option>
      );
    });
  }

  // setMainEvent() {
  //   this.setState({
  //     isMain: !this.state.isMain
  //   });
  // }

  // _crop() {
  //   const dataUri = this.cropper.current.getCroppedCanvas().toDataURL();
  //   const rdmString = cryptoRandomString({ length: 30, type: "url-safe" });
  //   var file = this.dataURLtoFile(dataUri, `item-${rdmString}`);
  //   if (file) {
  //     this.setState({
  //       imageFile: file,
  //       imageSelectError: ""
  //     });
  //   } else {
  //     this.setState({
  //       imageSelectError: "Select image area"
  //     });
  //   }
  // }

  // dataURLtoFile(dataUri, filename) {
  //   try {
  //     var arr = dataUri.split(",");
  //     if (!_.isEmpty(arr[0].match(/:(.*?);/))) {
  //       var mime = arr[0].match(/:(.*?);/)[1];
  //       var bstr = atob(arr[1]);
  //       var n = bstr.length;
  //       var u8arr = new Uint8Array(n);
  //       while (n--) {
  //         u8arr[n] = bstr.charCodeAt(n);
  //       }
  //       return new File([u8arr], filename, { type: mime });
  //     }
  //   } catch (error) {
  //     console.log("TCL: ItemForm -> dataURLtoFile -> error", error);
  //   }
  // }

  render() {
    let { isNew, item = {}, manageItemLoading, toggle } = this.props;
    const {
      eventImage,
      thumbnailLoading,
      // isMain,
      // imageSelectError,
      // inputTriggered,
      btnLoading
    } = this.state;
    if (!_.isEmpty(item)) {
      item.date = moment(item.date).format("YYYY-MM-DD");
    }
    return (
      <>
        {isNew ? (
          <div className="modal-header-div">
            Add New Item
            <Icon
              className="close-icon"
              name="close"
              onClick={() => toggle(false)}
            />
          </div>
        ) : null}
        <AvForm
          onValidSubmit={(event, values) =>
            this.handleValidSubmit(event, values)
          }
          className="register-form"
          ref={c => (this.form = c)}
          model={item}
        >
          <Grid columns={2} stackable>
            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="title"
                  label="Event Title"
                  errorMessage="Event title is required"
                  validate={{
                    required: { value: true }
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <AvField
                  name="date"
                  label="Event Date"
                  type="date"
                  errorMessage="Event date is required"
                  validate={{
                    date: { format: "YYYY-MM-DD" },
                    required: { value: true }
                  }}
                  // defaultValue={!_.isEmpty(item)? item.date : null}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {" "}
                <AvField
                  type="textarea"
                  rows="3"
                  name="description"
                  label="Description"
                  errorMessage="Description is required"
                  validate={{
                    required: { value: true }
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <AvField
                  type="select"
                  name="time"
                  label="Time"
                  errorMessage="Time is required"
                  validate={{
                    required: { value: true }
                  }}
                >
                  <option disabled value="">
                    Select time...
                  </option>
                  {this.renderEventTimes()}
                </AvField>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="streetName"
                  label="Street Number/Name"
                  errorMessage="Street Number/Name name is required"
                  validate={{
                    required: { value: true }
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <AvField
                  name="suburb"
                  label="Suburb"
                  errorMessage="Suburb is required"
                  validate={{
                    required: { value: true }
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="state"
                  label="State"
                  errorMessage="State is required"
                  validate={{
                    required: { value: true }
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <AvField
                  name="postCode"
                  label="Postcode"
                  errorMessage="Postcode is required"
                  validate={{
                    required: { value: true }
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="venueName"
                  label="Venue"
                  errorMessage="Venue is required"
                  validate={{
                    required: { value: true }
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <AvField
                  type="select"
                  name="accessType"
                  label="Event Access"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Event Access type is required"
                    }
                  }}
                >
                  <option value="" defaultValue disabled>
                    Select event access type...
                  </option>
                  <option value="private">All Members</option>
                  <option value="public">Public</option>
                  <optgroup label="Groups"></optgroup>
                  {this.renderGroups()}
                </AvField>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="item-upload-container">
                  <div>
                    {" "}
                    <label>
                      Image <span className="item-upload-required">*</span>
                    </label>
                    <br />
                    <i style={{ fontSize: 15 }}>
                      ( image size should be in 1080 X 608 )
                    </i>
                    <br />
                    <label
                      className="item-upload-button"
                      onClick={() => this.triggerInputFile()}
                    >
                      <Icon
                        name={thumbnailLoading ? "refresh" : "upload"}
                        loading={thumbnailLoading}
                      />
                      {thumbnailLoading ? "Please Wait" : "Upload Image"}
                    </label>
                    <input
                      name="eventImage"
                      ref={fileInput => (this.fileInput = fileInput)}
                      type="file"
                      hidden={true}
                      accept=".jpg,.jpeg,.png,.webp"
                      onChange={e => this.onImageChange(e)}
                    />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column>
                {/* {inputTriggered ? (
                  !thumbnailLoading ? (
                    <div className="content-image">
                      <Cropper
                        ref={this.cropper}
                        src={eventImage}
                        style={{ height: 300, width: "100%" }}
                        crop={() => this._crop()}
                      />
                      {imageSelectError ? (
                        <div className="display-err text-danger form-group">
                          {imageSelectError}
                        </div>
                      ) : null}
                    </div>
                  ) : null
                ) : (
                  <Image size="medium" src={eventImage} />
                )} */}
                {eventImage ? (
                  <Image size="medium" src={eventImage} />
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="ticketPrice"
                  label="Ticket Price"
                  type="number"
                  errorMessage="Ticket Price is required"
                  min={0}
                  validate={{
                    required: { value: true },
                    pattern: {
                      value: "^[0-9]+$",
                      errorMessage: "Only numbers are accepted"
                    }
                  }}
                />
              </Grid.Column>
              {/* <Grid.Column>
                <>
                  <label>Main Event</label>
                  <br />
                  <Radio
                    toggle
                    checked={isMain}
                    onChange={() => this.setMainEvent()}
                  />
                </>
              </Grid.Column> */}
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="numberOfTickets"
                  label="Total Number of Tickets"
                  type="number"
                  errorMessage="Total Number of Tickets is required"
                  validate={{
                    min: {
                      value: 1,
                      errorMessage: "Value should be more than one"
                    },
                    required: { value: true },
                    pattern: {
                      value: "^[0-9]+$",
                      errorMessage: "Only numbers are accepted"
                    }
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <AvField
                  name="maxTicketPerMember"
                  label="Max Tickets per Member"
                  type="number"
                  errorMessage="Max Tickets per Member is required"
                  validate={{
                    min: {
                      value: 1,
                      errorMessage: "Value should be more than one"
                    },
                    required: { value: true },
                    pattern: {
                      value: "^[0-9]+$",
                      errorMessage: "Only numbers are accepted"
                    }
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="btn-row">
              <div>
                <Button
                  loading={manageItemLoading || btnLoading}
                  disabled={
                    eventImage ? manageItemLoading ? true : false : true
                  }
                  type="sumbit"
                >
                  {isNew ? "ADD NEW ITEM" : "UPDATE ITEM"}
                </Button>
              </div>
            </Grid.Row>
          </Grid>
        </AvForm>
      </>
    );
  }
}

export class ItemDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  async deleteItemHandle() {
    try {
      const { deleteItem, item, getAll } = this.props;
      await deleteItem(item.id, getAll);
      this.toggle();
    } catch (error) {
      console.log("TCL: ItemDelete -> deleteItemHandle -> error", error);
    }
  }

  render() {
    const { modal } = this.state;
    const { item, manageItemLoading } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        trigger={<div className="unpublish">Delete Item</div>}
      >
        <Modal.Content>
          <div className="modal-header-div">
            Delete Item - {item.title}
            <Icon
              className="close-icon"
              name="close"
              onClick={() => this.toggle()}
            />
          </div>
          <p>Are you sure you want to delete this item?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.toggle()} negative>
            No
          </Button>
          <Button
            loading={manageItemLoading}
            onClick={() => this.deleteItemHandle()}
            positive
            labelPosition="right"
            icon="checkmark"
            content="Yes"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export class ItemEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    const { modal } = this.state;
    const { item } = this.props;
    return (
      <Modal
        centered
        trigger={<div className="view">View/Edit Item</div>}
        open={modal}
        onOpen={() => this.toggle()}
        onClose={() => this.toggle()}
      >
        <Modal.Content>
          <div className="modal-header-div">
            Edit Item - {item.title}
            <Icon
              className="close-icon"
              name="close"
              onClick={() => this.toggle()}
            />
          </div>
          <ItemForm
            toggle={() => this.toggle()}
            item={item}
            isNew={false}
            {...this.props}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { members } = state;
  return {
    ...members
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllGroups
    },
    dispatch
  );
};

ItemForm = connect(mapStateToProps, mapDispatchToProps)(ItemForm);
