import React from 'react';
import './shop.scss';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Grid, Button, Segment, Search, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getEventByEventId,
  buyTicketsFromAdminPanel,
  getAllMembers,
  getMember
} from '../../store/actions';
import _ from 'lodash';
import EventPaymentReceipt from '../payment/events/eventPaymentReceipt';

class BuyTicketForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      maxTicketPerMember: null,
      totalTicketPrice: 0,
      firstNameValue: '',
      form: {}
    };
  }

  componentDidMount() {
    const { getAllMembers } = this.props;
    getAllMembers();

    this.setState({
      firstNameLoading: this.props.getMembersLoading,
      firstNames: this.props.allMembers.map((item, i) => {
        return { key: item.id, title: item.firstName + ' ' + item.lastName };
      })
    });
  }

  renderAllEvents() {
    const { allItems } = this.props;
    return allItems.map(item => {
      return (
        <option key={item.id} value={item.id}>
          {item.title}
        </option>
      );
    });
  }

  async onEventSelect(e) {
    try {
      const { getEventByEventId } = this.props;
      await getEventByEventId(e.target.value);
      const { event } = this.props;
      this.setState({
        maxTicketPerMember: event.maxTicketPerMember,
        ticketPrice: event.ticketPrice,
        eventId: event.id,
        accessType: event.accessType
      });
    } catch (error) {
      console.log('TCL: BuyTicketForm -> onEventSelect -> error', error);
    }
  }

  renderNoOfTickets() {
    const { maxTicketPerMember } = this.state;
    return _.times(maxTicketPerMember, item => {
      return <option key={item}>{item + 1}</option>;
    });
  }

  NoOfTicketChange(e) {
    const { ticketPrice } = this.state;
    this.setState({
      totalTicketPrice: e.target.value * ticketPrice,
      noOfTickets: e.target.value
    });
  }

  async handleValidSubmit(event, values) {
    const { buyTicketsFromAdminPanel } = this.props;
    const { totalTicketPrice, eventId, noOfTickets } = this.state;
    let params = {
      paymentMode: 'CASH',
      paymentType: 'EVENT',
      amount: totalTicketPrice,
      eventId: eventId,
      numberOfTickets: noOfTickets,
      guestName: values.firstName + ' ' + values.lastName,
      guestEmail: values.email
    };
    await buyTicketsFromAdminPanel(params);
    await this.setState({
      eventPaymentAdmin: this.props.eventPaymentAdmin
    });
  }
  handleResultSelect = async (e, { result }) => {
    const { getMember } = this.props;
    await getMember(result.key);

    try {
      this.setState({
        firstNameValue: result.title,
        form: {
          lastName: this.props.member.lastName,
          email: this.props.member.email,
          firstName: this.props.member.firstName
        }
      });
    } catch (error) {
      console.log('TCL: AddAttendeeForm -> handleResultSelect -> error', error);
    }
  };

  async handleSearchChange(e, value) {
    try {
      const { getAllMembers } = this.props;
      this.setState({ firstNameLoading: true, firstNameValue: value.value });

      await getAllMembers(1, null, null, value.value);
      setTimeout(() => {
        if (this.state.firstNameValue.length < 1)
          return this.setState({
            firstNameLoading: false,
            firstNameValue: '',
            firstNameResults: []
          });

        const re = new RegExp(_.escapeRegExp(this.state.firstNameValue), 'i');
        const isMatch = result => re.test(result.title);
        const memberNames = this.props.allMembers.map((item, i) => {
          return { key: item.id, title: item.firstName + ' ' + item.lastName };
        });
        this.setState({
          firstNameLoading: false,
          firstNameResults: _.filter(memberNames, isMatch)
        });
      }, 300);
    } catch (error) {
      console.log('TCL: handleSearchChange -> error', error);
    }
  }

  segmentLoading(value) {
    this.setState({
      loading: value
    });
  }

  render() {
    const {
      maxTicketPerMember,
      totalTicketPrice,
      eventPaymentAdmin,
      ticketPrice,
      firstNameLoading,
      firstNameResults,
      firstNameValue,
      form,
      loading,
      accessType
    } = this.state;
    const {
      getEventByIdLoading,
      trigger,
      allItems,
      eventPaymentAdminLoading
    } = this.props;

    return (
      <>
        {eventPaymentAdmin ? (
          <Segment loading={loading}>
            <Icon
              onClick={() => trigger(false)}
              name="close"
              className="closeIcon"
            />
            <EventPaymentReceipt
              admin
              eventId={eventPaymentAdmin.itemId}
              itemPaymentId={eventPaymentAdmin.itemPaymentId}
              segmentLoading={value => this.segmentLoading(value)}
            />
          </Segment>
        ) : (
          <>
            <div className="modal-header-div">
              Buy Ticket
              <Icon
                onClick={() => trigger(false)}
                name="close"
                className="closeIcon"
              />
            </div>

            <AvForm
              onValidSubmit={(event, values) =>
                this.handleValidSubmit(event, values)
              }
              className="buy-ticket-form"
              model={form}
            >
              <Grid padded>
                <Grid.Row>
                  <Grid.Column className="field-col" width={9}>
                    <AvField
                      disabled={_.isEmpty(allItems) ? true : false}
                      name="eventNames"
                      label="Event Name"
                      type="select"
                      onChange={value => this.onEventSelect(value)}
                      errorMessage="Event Name is required"
                      validate={{
                        required: { value: true }
                      }}
                    >
                      <option disabled value="">
                        {_.isEmpty(allItems) ? 'No events' : 'Select...'}
                      </option>
                      {this.renderAllEvents()}
                    </AvField>
                  </Grid.Column>
                  <Grid.Column className="field-col" width={7}>
                    {accessType ? (
                      <div className="access-type">
                        <label>Access Type</label>
                        <div className="type">{accessType}</div>
                      </div>
                    ) : null}
                  </Grid.Column>

                  <Grid.Column className="field-col" width={9}>
                    <AvField
                      disabled={maxTicketPerMember ? false : true}
                      name="ticketCount"
                      label="Number of Tickets"
                      type="select"
                      onChange={e => this.NoOfTicketChange(e)}
                      errorMessage="Number of tickets is required"
                      validate={{
                        required: { value: true }
                      }}
                    >
                      <option disabled value="">
                        {getEventByIdLoading ? 'Loading...' : 'Select...'}
                      </option>
                      {this.renderNoOfTickets()}
                    </AvField>
                  </Grid.Column>
                  <Grid.Column className="field-col free-div" width={7}>
                    {ticketPrice === 0 ? (
                      <div className="free-div">
                        <label>FREE</label>
                      </div>
                    ) : (
                      <AvField
                        disabled
                        className="tot-price"
                        name="totPrice"
                        label="Total Price"
                        value={'$' + totalTicketPrice.toString()}
                        errorMessage="Total Price is required"
                        validate={{
                          required: { value: true }
                        }}
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="field-row">
                  <Grid.Column className="field-col" width={9}>
                    <b>Enter Details</b>
                    <br />
                    <br />
                    <label>Search Member</label>
                    <Search
                      className="search-input"
                      loading={firstNameLoading}
                      onResultSelect={(e, value) =>
                        this.handleResultSelect(e, value)
                      }
                      onSearchChange={_.debounce(
                        (e, value) => this.handleSearchChange(e, value),
                        500,
                        {
                          leading: true
                        }
                      )}
                      results={firstNameResults}
                      value={firstNameValue}
                    />
                    <br />
                    <br />
                  </Grid.Column>
                  <Grid.Column className="field-col" width={14}>
                    <AvField
                      name="firstName"
                      label="First Name"
                      errorMessage="First Name is required"
                      value={form.firstName}
                      validate={{
                        required: { value: true }
                      }}
                    />
                    <AvField
                      name="lastName"
                      label="Last Name"
                      errorMessage="Last Name is required"
                      value={form.lastName}
                      validate={{
                        required: { value: true }
                      }}
                    />
                    <AvField
                      name="email"
                      label="Email Address"
                      value={form.email}
                      errorMessage="Email Address is required"
                      type="email"
                      validate={{
                        required: { value: true }
                      }}
                    />
                    <Button
                      disabled={
                        _.isEmpty(allItems)
                          ? true
                          : eventPaymentAdminLoading
                          ? true
                          : false
                      }
                      loading={eventPaymentAdminLoading}
                    >
                      {ticketPrice === 0 ? 'GET TICKETS' : 'BUY TICKETS'}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </AvForm>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { items, members } = state;
  return {
    ...items,
    ...members
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getEventByEventId,
      buyTicketsFromAdminPanel,
      getAllMembers,
      getMember
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyTicketForm);
