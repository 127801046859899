import React from "react";
import { Button, Icon } from "semantic-ui-react";
import _ from "lodash";

export class UploadCSV extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      csvUploadError: {}
    };
  }

  triggerInputFile = () => {
    this.fileInput.click();
  };

  onFileChange = event => {
    const file = event.target.files;
    // console.log("file type======================", file)
    if (file.length) {
      this.setState({
        csvFile: file[0],
        fileName: file[0].name
      });
    }
  };

  async uploadCsv() {
    try {
      const {
        uploadAttendeeCsv,
        match,
        trigger,
        uploadBankStatement,
        type,
        startDate,
        endDate,
        reportObj,
        uploadMemberCsv
      } = this.props;
      if (type === "report") {
        const formData = new FormData();
        formData.set("file", this.state.csvFile);
        formData.append("startDate", startDate);
        formData.append("endDate", endDate);
        await uploadBankStatement(formData);
        reportObj(this.props.reportPayments);
      } else if (type === "members") {
        const formData = new FormData();
        formData.set("file", this.state.csvFile);
        await uploadMemberCsv(formData);
        trigger(false);
      } else {
        const formData = new FormData();
        formData.set("file", this.state.csvFile);
        formData.append("itemId", match.params.eventId);
        await uploadAttendeeCsv(formData);
        trigger(false);
      }
    } catch (error) {
      this.setState({
        csvUploadError: this.props.csvUploadError
      });
    }
  }

  renderBtnLoading() {
    const {
      fileLoading,
      reportPaymentsLoading,
      type,
      csvUploadLoading
    } = this.props;
    switch (type) {
      case "report":
        return reportPaymentsLoading;
      case "members":
        return csvUploadLoading;
      default:
        return fileLoading;
    }
  }

  renderBtnText() {
    const { type } = this.props;
    switch (type) {
      case "report":
        return "UPLOAD BANK STATEMENT";
      case "members":
        return "UPLOAD MEMBER CSV";
      default:
        return "UPLOAD CSV TO EVENT";
    }
  }

  renderModalTitle() {
    const { type } = this.props;
    switch (type) {
      case "report":
        return "Upload Bank Statement";
      case "members":
        return "Upload Member CSV";
      default:
        return "Upload Attendance CSV";
    }
  }

  renderSampleCsv() {
    const { type } = this.props;
    switch (type) {
      case "report":
        return "https://rcobaa-app-images.s3-ap-southeast-2.amazonaws.com/bank-statement-csv.csv";
      case "members":
        return "https://rcobaa-app-images.s3-ap-southeast-2.amazonaws.com/member-csv.csv";
      default:
        return "https://rcobaa-app-images.s3-ap-southeast-2.amazonaws.com/csv-file.csv";
    }
  }

  renderDesc() {
    const { type } = this.props;
    switch (type) {
      case "report":
        return <p>Upload a bank statement</p>;
      case "members":
        return <p>Attach Member Data</p>;
      default:
        return <p>Attach Attendee Data</p>;
    }
  }

  render() {
    const { fileName, thumbnailLoading, csvFile, csvUploadError } = this.state;

    return (
      <>
        <div className="modal-header-div">{this.renderModalTitle()}</div>
        <div className="upload-section">
          {this.renderDesc()}
          <Button
            className="upload-btn"
            onClick={() => this.triggerInputFile()}
          >
            {thumbnailLoading ? (
              <Icon loading={thumbnailLoading} name="refresh" />
            ) : (
              <Icon name="upload" />
            )}
            Upload CSV
          </Button>
          {fileName ? <u>{fileName}</u> : null}
          <br />
          <i style={{ fontSize: 15 }}>
            Upload csv according to the given format:{" "}
            <a href={this.renderSampleCsv()} alt="link">
              {" "}
              csv file
            </a>
          </i>
          {!_.isEmpty(csvUploadError) ? (
            <i style={{ fontSize: 15 }}>
              <br />
              <br />
              <br />
              <i className="error-file">Note: </i>
              <br />
              There were errors in this csv file:{" "}
              <a href={csvUploadError.Location} alt="link">
                {" "}
                <i>{fileName}</i>
              </a>
            </i>
          ) : null}

          <input
            name="attendeeCsv"
            ref={fileInput => (this.fileInput = fileInput)}
            type="file"
            hidden={true}
            onChange={e => this.onFileChange(e)}
            accept=".csv"
          />
        </div>
        <Button
          disabled={!csvFile}
          loading={this.renderBtnLoading()}
          onClick={() => this.uploadCsv()}
        >
          {this.renderBtnText()}
        </Button>
      </>
    );
  }
}

export default UploadCSV;
