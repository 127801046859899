import axios from "axios";
import { LIMIT, DELETED_MSG, CREATED_MSG, UPDATED_MSG } from "../../constant";
import { showError } from "../../utils";
import { toast } from "react-toastify";

const GET_TICKETS_LOADING = "GET_TICKETS_LOADING";
const TICKETS = "TICKETS";
const GET_ALL_ATTENDEES_LOADING = "GET_ALL_ATTENDEES_LOADING";
const ALL_ATTENDEES = "ALL_ATTENDEES";
const FILE_LOADING = "FILE_LOADING";
const EXPORT_CSV = "EXPORT_CSV";
const GET_USER_ATTENDANCE_LOADING = 'GET_USER_ATTENDANCE_LOADING';
const USER_ATTENDANCE = "USER_ATTENDANCE";

const initialState = {
  getTicketsLoading: false,
  tickets: [],
  getAllAttendeesLoading: false,
  allAttendees: [],
  fileLoading: false,
  exportedFile: '',
  getUserAttendanceLoading: false,
  userAttendance: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TICKETS_LOADING:
      return {
        ...state,
        getTicketsLoading: action.payload
      };
    case TICKETS:
      return {
        ...state,
        tickets: action.payload
      };
    case GET_ALL_ATTENDEES_LOADING:
      return {
        ...state,
        getAllAttendeesLoading: action.payload
      };
    case ALL_ATTENDEES:
      return {
        ...state,
        allAttendees: action.payload.docs,
        attendancePages: action.payload.pages
      };
    case FILE_LOADING:
      return {
        ...state,
        fileLoading: action.payload
      };
    case EXPORT_CSV:
      return {
        ...state,
        exportedFile: action.payload
      };
    case GET_USER_ATTENDANCE_LOADING:
      return {
        ...state,
        getUserAttendanceLoading: action.payload
      };
    case USER_ATTENDANCE:
      return {
        ...state,
        userAttendance: action.payload
      };
    default:
      return state;
  }
}

export function getAllAttendees(data) {
  return async dispatch => {
    try {
      dispatch({ type: GET_ALL_ATTENDEES_LOADING, payload: true });
      const params = {
        limit: LIMIT,
        offset: data ? (data.offset ? data.offset : 1) : 1,
        itemId: data.itemId
      };
      const response = await axios.get("/attendance", { params });
      dispatch({ type: ALL_ATTENDEES, payload: response.data });
      dispatch({ type: GET_ALL_ATTENDEES_LOADING, payload: false });
    } catch (error) {
      showError(error);
      dispatch({ type: GET_ALL_ATTENDEES_LOADING, payload: false });
    }
  };
}
export function getAlltickets(data) {
  return async dispatch => {
    try {
      dispatch({ type: GET_TICKETS_LOADING, payload: true });
      const params = {
        itemId: data.itemId
      };
      const response = await axios.get("/item/tickets", { params });
      dispatch({ type: TICKETS, payload: response.data });
      dispatch({ type: GET_TICKETS_LOADING, payload: false });
    } catch (error) {
      showError(error);
      dispatch({ type: GET_TICKETS_LOADING, payload: false });
    }
  };
}

export function addAttendee(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          ticketNo: data.ticketNo ? data.ticketNo : null,
          guestFirstName: data.guestFirstName ? data.guestFirstName : null,
          guestLastName: data.guestLastName ? data.guestLastName : null,
          guestMobileNumber: data.guestMobileNumber
            ? data.guestMobileNumber
            : null,
          guestEmail: data.guestEmail ? data.guestEmail : null,
          itemId: data.eventId
        };
        if (data.userId) {
          params.userId = data.userId;
        }
        await axios.post("/attendance", params);
        toast.success(CREATED_MSG);
        await dispatch(getAllAttendees({ itemId: data.eventId }));
        resolve();
      } catch (error) {
        showError(error);
        console.log("TCL: getAllAttendees -> error", error);
        reject();
      }
    });
  };
}

export function updateAttendee(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          ticketNo: data.ticketNo ? data.ticketNo : null,
          guestFirstName: data.guestFirstName ? data.guestFirstName : null,
          guestLastName: data.guestLastName ? data.guestLastName : null,
          guestMobileNumber: data.guestMobileNumber
            ? data.guestMobileNumber
            : null,
          guestEmail: data.guestEmail ? data.guestEmail : null,
          itemId: data.eventId
        };
        if (data.userId) {
          params.userId = data.userId;
        }
        await axios.put("/attendance", params);
        toast.success(UPDATED_MSG);
        await dispatch(getAllAttendees({ itemId: data.eventId }));
        resolve();
      } catch (error) {
        showError(error);
        console.log("TCL: getAllAttendees -> error", error);
        reject();
      }
    });
  };
}

export function deleteAttendee(data) {
  return async dispatch => {
    try {
      await axios.delete(`/attendance/?ticketNo=${data.ticketNo}&itemId=${data.eventId}`);
      await dispatch(getAllAttendees({ itemId: data.eventId }));
      toast.success(DELETED_MSG);
    } catch (error) {
      showError(error);
    }
  };
}

export function uploadAttendeeCsv(data) {
  return async dispatch => {
    try {
      dispatch({ type: FILE_LOADING, payload: true });
      const response = await axios.post("/attendee/import", data);
      dispatch({ type: FILE_LOADING, payload: false });
      toast.success(response.data);
      dispatch(getAllAttendees({ itemId: data.get('itemId') }));
    } catch (error) {
      showError(error);
      dispatch({ type: FILE_LOADING, payload: false });
    }
  };
}

export function exportAttendeeCsv(data) {
  return async dispatch => {
    try {
      const response = await axios.post("/attendee/export", data);
      dispatch({type: EXPORT_CSV, payload: response.data.Location})
    } catch (error) {
      showError(error);
    }
  };
}


export function getAllAttendanceOfAUser(data) {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_ATTENDANCE_LOADING, payload: true });
      const params = {
        limit: LIMIT,
        offset: data ? (data.offset ? data.offset : 1) : 1,
        userId: data.userId
      };
      const response = await axios.get("/users/attendance", { params });
      dispatch({ type: USER_ATTENDANCE, payload: response.data });
      dispatch({ type: GET_USER_ATTENDANCE_LOADING, payload: false });
    } catch (error) {
      showError(error);
      dispatch({ type: GET_USER_ATTENDANCE_LOADING, payload: false });
    }
  };
}