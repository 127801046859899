import React from 'react';
import { Grid, Header, Image } from 'semantic-ui-react';
import './cardHolder.scss';

export const CardHolder = props => {
  const { heading, description, image, children, type, id } = props;
  return (
    <Grid id={id ? id : null} padded stackable className="card-holder-grid">
      <Grid.Row className="description-row">
        <Header as="h1">{heading}</Header>
        {description ? (
          <p> {description} </p>
        ) : image ? (
          <div className="responsive-image">
            <Image src={image} className="responsive-image__image" />
          </div>
        ) : null}
      </Grid.Row>
      {type === 'events' ? (
        <Header className="event-header">Upcoming Events</Header>
      ) : null}
      {children}
    </Grid>
  );
};
