import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import withTracker from './lib/withPageTracker';
import history from './history';
import LandingPage from './containers/landingPage';
import Payment from './containers/payment/payment';
import PaymentSuccess from './containers/payment/paymentSuccess';
import EventPayment from './containers/payment/events/eventPayment';
import Layout from './containers/layout/layout';
import Members from './containers/manageMembers/members/members';
import Shop from './containers/shop/shop';
import ShopItem from './containers/shop/shopItem';
import Content from './containers/content/content';
import Events from './containers/events/events';
import Album from './containers/content/album';
import Membership from './containers/manageMembers/membership';
import Register from './containers/register/register';
import EventTypes from './containers/events/eventTypes';
import ContactForm from './containers/contactUs/contactForm';
import { AboutUs } from './components/about/about';
import { TermsAndConditions } from './components/backgroundPage/termsAndConditions';
import Login from './containers/register/login';
import ForgotPassword from './containers/register/forgotPassword';
import ResetPassword from './containers/register/resetPassword';
import Media from './containers/content/media';
import ViewGallery from './containers/content/viewGallery';
import MembershipPayment from './containers/manageMembers/members/membershipPayment';
import Store from './containers/payment/brainTreeDropIn';
import NewsArticle from './containers/content/newsArticle';
import MemberProfile from './containers/manageMembers/members/memberProfile';
import ManageAttendance from './containers/events/attendance/manageAttendance';
import EventPaymentReceipt from './containers/payment/events/eventPaymentReceipt';
import { PageNotFound } from './components/notFound/pageNotFound';
import Report from './containers/report/report';
import MemberAttendance from './containers/manageMembers/members/memberAttendance';
import { REACT_APP_HIDE_LANDING_REDIRECT } from './constant';
import LoginSSO from './containers/register/loginSSO';

//https://tylermcginnis.com/react-router-protected-routes-authentication/

const PublicRoute = ({ component: Component, ...rest }) =>{
  if(REACT_APP_HIDE_LANDING_REDIRECT){
    window.location.href = REACT_APP_HIDE_LANDING_REDIRECT;
    return null;
  }
  return <Route {...rest} render={props => <Component {...props} />} />;
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const authToken = localStorage.getItem('auth_token');
      const userType = localStorage.getItem('user_type');
      return (authToken && userType === '1') ? (
        <Layout {...props} pageTitle={rest.pageTitle}>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      );
    }}
  />
);

// const PublicPrivateRoute = ({ component: Component, ...rest }) => (
//   <Route 
//     {...rest}
//     render = {props => {
//       const authToken = localStorage.getItem('auth_token');
//       return (authToken) ? (
//           <Component {...props} />
//       ) : (
//         <Redirect to={{ pathname: '/login', state: { from: props.location} }} />
//       );
//     }}
//   />
// )

const AppRouting = () => (
  <Router history={history}>
    <Switch>

      <PublicRoute exact path="/" component={withTracker(LandingPage)} />
      <Route
        exact
        path="/loginSSO"
        component={withTracker(LoginSSO)}
      />
      <PublicRoute exact path="/login" component={withTracker(Login)} />
     
        <PublicRoute
          exact
          path="/membership"
          component={withTracker(Membership)}
        />
        <PublicRoute exact path="/register" component={withTracker(Register)} />

        <PublicRoute
          exact
          path="/register/:id"
          component={withTracker(Register)}
        />
        <PublicRoute exact path="/events" component={withTracker(EventTypes)} />
        <PublicRoute exact path="/contact" component={withTracker(ContactForm)} />
        <PublicRoute exact path="/about" component={withTracker(AboutUs)} />
        <PublicRoute
          exact
          path="/termsAndConditions"
          component={withTracker(TermsAndConditions)}
        />
        <PublicRoute exact path="/media/:mediaType" component={withTracker(Media)} />
        <PublicRoute
          exact
          path="/membership/payment/:id/renew/:renew/:memberId"
          component={withTracker(Payment)}
        />
        <PublicRoute
          exact
          path="/events/payment/:id"
          component={withTracker(EventPayment)}
        />
        <PublicRoute
          exact
          path="/membership/:type/success/:membershipTypeId"
          component={withTracker(PaymentSuccess)}
        />
        {/* <PublicRoute
        exact
        path="/event/:type"
        component={withTracker(EventPayment)}
      /> */}
        <PublicRoute
          exact
          path="/gallery/:imageId"
          component={withTracker(ViewGallery)}
        />
        <PublicRoute
          exact
          path="/payment/dropIn"
          component={withTracker(Store)}
        />
        <PublicRoute
          exact
          path="/news/newsArticle/:newsId"
          component={withTracker(NewsArticle)}
        />
        <PublicRoute
          exact
          path="/event/:eventId/payment/:itemPaymentId"
          component={withTracker(EventPaymentReceipt)}
        />

        <PublicRoute
          exact
          path="/forgotPassword"
          component={withTracker(ForgotPassword)}
        />
        <PublicRoute
          exact
          path="/resetPassword/:token"
          component={withTracker(ResetPassword)}
        />
      

      <PrivateRoute
        exact
        pageTitle="Manage Members"
        path="/admin/manageMembers"
        component={withTracker(Members)}
      />
      <PrivateRoute
        exact
        pageTitle="Manage Shop Items"
        path="/admin/shop"
        component={withTracker(Shop)}
      />
      <PrivateRoute
        exact
        path="/admin/shop/:shopId"
        component={withTracker(ShopItem)}
      />
      <PrivateRoute
        exact
        pageTitle="Manage Content"
        path="/admin/content/:contentType"
        component={withTracker(Content)}
      />
      <PrivateRoute
        exact
        pageTitle="Site Reports"
        path="/admin/reports"
        component={withTracker(Members)}
      />
      <PrivateRoute
        exact
        pageTitle="Manage Event Attendance"
        path="/admin/events"
        component={withTracker(Events)} page404
      />
      <PrivateRoute
        exact
        path="/admin/manageAttendance/:eventId"
        component={withTracker(ManageAttendance)}
      />
      <PrivateRoute
        exact
        path="/admin/content/album/:id"
        component={withTracker(Album)}
      />
      <PrivateRoute
        exact
        path="/admin/manageMembers/payment/:userId"
        component={withTracker(MembershipPayment)}
      />
      <PrivateRoute
        exact
        path="/admin/manageMembers/:memberId"
        component={withTracker(MemberProfile)}
      />
      <PrivateRoute
        exact
        pageTitle="Site Reports"
        path="/admin/report"
        component={withTracker(Report)}
      />
      <PrivateRoute
        exact
        path="/admin/manageMembers/attendance/:memberId"
        component={withTracker(MemberAttendance)}
      />
      <Route
        exact
        component={withTracker(PageNotFound)}
      />
    </Switch>
  </Router>
);

export default AppRouting;
