import React from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Grid, Button, Icon, Modal, Dropdown, Radio } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import history from "../../../history";
import { InputGroup, InputGroupAddon } from "reactstrap";
import { getMembershipTypes, getAllMembers } from "../../../store/actions";
import { LocationSearch } from "../../../components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

class MemberForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchValues: {},
      memberDetails: {},
      loading: false,
      membershipType: props.match.params.id,
      errorState: "",
      errorRetired: "",
      deceasedStatus:
        props.admin && props.type === "edit" ? props.editUser.deceasedStatus : null,
      errorDate: "",
      DOB:
        props.admin && props.type === "edit"
          ? moment(props.editUser.DOB).format("YYYY-MM-DD")
          : null
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { getMembershipTypes } = this.props;
    getMembershipTypes();
  }

  handleValidSubmit(event, values) {
    if (
      values.state &&
      values.state.trim().toLowerCase() === "victoria" &&
      values.membershipType === "3"
    ) {
      this.setState({
        errorState: "This state is invalid for the selected membership type",
        errorRetired:
          "This membership type cannot be selected if you are in victoria"
      });
      toast.warn("Please correct form issues!");
    } else if (!this.state.DOB) {
      this.setState({
        errorDate: this.state.errorDate
          ? this.state.errorDate
          : "DOB is required!"
      });
    } else {
      const {
        register,
        updateMember,
        type,
        editUser,
        getAllMembers,
        page
      } = this.props;
      const { deceasedStatus, DOB } = this.state;
      values.deceasedStatus = deceasedStatus;
      values.DOB = DOB;
      this.setState({ memberDetails: values }, async () => {
        try {
          switch (type) {
            case "edit":
              values.userId = editUser.id;
              delete values.email;
              await updateMember(values);
              break;
            default:
              await register(values);
              console.log("TCL: MemberForm -> handleValidSubmit -> this.props.userRegister", this.props.userRegister)

          }
          if (!this.props.admin) {
            history.push(`/membership/payment/${values.membershipType}/renew/false/${this.props.userRegister.id}`); //if filled from landing page
          } else {
            this.props.toggle();
            await getAllMembers(page);
            // await getMember(user.id);
          }
        } catch (error) {
          console.log(">: Register -> handleValidSubmit -> error", error);
        }
      });
    }
  }

  renderBatches() {
    const { DOB } = this.state
    const currentYear = moment().year();
    const birthYear = DOB? moment(DOB).year() : (currentYear - 50) //fifty past batches from current year or from current year to birth year
    const batches = _.range(birthYear, currentYear); 
    return (
      <>
        {batches.map((item, i) => {
          return (
            <option key={i} value={item}>
              {item}
            </option>
          );
        })}
      </>
    );
  }

  toggle() {
    this.setState({
      deceasedStatus: !this.state.deceasedStatus
    });
  }

  formRef(c) {
    this.form = c;
  }
  renderMembershipTypes() {
    const { membership } = this.props;
    return (
      <>
        {membership.map((item, i) => {
          return (
            <option key={i} value={item.id}>
              {item.title}
            </option>
          );
        })}
      </>
    );
  }

  onDayChange(day) {
    if (moment(day).diff(moment().subtract(14, "y"), "years") <= 0) {
      this.setState({
        DOB: moment(day).format("YYYY-MM-DD"),
        errorDate: ""
      });
    } else {
      this.setState({
        DOB: "",
        errorDate: "Date cannot be in this range"
      });
    }
  }
  onDateBlur(data){
    if(!data.target.value){
      this.setState({
        errorDate: 'DOB is required!'
      })
    }
  }

  setLocations(data) {
    let searchValues = {
      name: data.name
        ? data.name
        : data.formatted_address
        ? data.formatted_address.split(",")[0] || ""
        : "",
      streetNumber: data.streetNumber ? data.streetNumber : "",
      streetAddress: data.streetAddress ? data.streetAddress : "",
      state: data.state ? data.state : "",
      suburb: data.suburb ? data.suburb : "",
      postcode: data.postcode ? data.postcode : ""
    };

    this.setState({ searchValues });
  }

  handleInvalidSubmit(event, errors, values){
    const { DOB } = this.state;
    if(!DOB){
      this.setState({
        errorDate: 'Date is required'
      })
    }
  }

  render() {
    const {
      membershipType,
      errorState,
      errorRetired,
      deceasedStatus,
      errorDate,
      DOB,
      searchValues
    } = this.state;
    let {
      authRegisterLoading,
      admin,
      type,
      editUser,
      putMembersLoading,
      toggle
    } = this.props;
    return (
      <>
        {type !== "edit" && admin ? (
          <div className="modal-header-div">
            Add New Member{" "}
            <Icon
              className="close-icon"
              name="close"
              onClick={() => toggle()}
            />
          </div>
        ) : null}
        <AvForm
          onInvalidSubmit = {(event, errors, values) => this.handleInvalidSubmit(event, errors, values)}
          onValidSubmit={this.handleValidSubmit}
          model={type === "edit" ? editUser : { membershipType }}
          className="register-form"
          ref={c => this.formRef(c)}
        >
          <Grid columns={2} stackable>
            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="firstName"
                  label="First Name"
                  errorMessage="First name is required"
                  validate={{
                    required: { value: true }
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <AvField
                  name="lastName"
                  label="Last Name"
                  errorMessage="Last name is required"
                  validate={{
                    required: { value: true }
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {" "}
                <label>Date of Birth</label>
                <div onBlur={ (data) => this.onDateBlur(data)}>
                <DayPickerInput
                  value={DOB ? DOB : null}
                  placeholder={" "}
                  onDayChange={day => this.onDayChange(day)}
                  pagedNavigation={true}
                  dayPickerProps={{
                    initialMonth: new Date(moment().subtract(14, 'y').format('YYYY')),
                    disabledDays : [{
                      after: new Date(moment().subtract(14, 'y').format('YYYY')),
                    }]
                  }}
                />
                </div>
                {errorDate ? (
                  <div className="display-err text-danger form-group">
                    {errorDate}
                  </div>
                ) : null}
              </Grid.Column>
              <Grid.Column>
                {admin && type === "edit" ? (
                  <>
                    <label>Deceased Status</label>
                    <br />
                    <Radio
                      toggle
                      checked={deceasedStatus}
                      onChange={() => this.toggle()}
                    />
                  </>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <AvField name="spouse" label="Spouse/Partner Name (optional)" />
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <LocationSearch getLocation={data => this.setLocations(data)} />
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="streetName"
                  label="Street Number/Name"
                  errorMessage="Street Number/Name name is required"
                  validate={{
                    required: { value: true }
                  }}
                  value={searchValues["name"]}
                />
              </Grid.Column>
              <Grid.Column>
                <AvField
                  name="suburb"
                  label="Suburb"
                  errorMessage="Suburb is required"
                  validate={{
                    required: { value: true }
                  }}
                  value={searchValues["suburb"]}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="state"
                  label="State"
                  errorMessage="State is required"
                  validate={{
                    required: { value: true }
                  }}
                  value={searchValues["state"]}
                />
                <div className="display-err text-danger form-group">
                  {errorState ? errorState : null}
                </div>
              </Grid.Column>
              <Grid.Column>
                <AvField
                  name="postCode"
                  label="Postcode"
                  errorMessage="Postcode is required"
                  validate={{
                    required: { value: true }
                  }}
                  value={searchValues["postcode"]}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="email"
                  label="Email Address"
                  disabled={type === "edit" ? true : false}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Email address is required"
                    },
                    email: true
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <label>Phone Number</label>
                <InputGroup className="form-group">
                  <InputGroupAddon addonType="prepend">+61</InputGroupAddon>
                  <AvField
                    name="phoneNumber"
                    type="number"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[0-9]*$",
                        errorMessage: "There are invalid characters"
                      },
                      minLength: {
                        value: 10,
                        errorMessage: "Minimum 10 digits"
                      },
                      maxLength: {
                        value: 10,
                        errorMessage: "Maximum 10 digits"
                      }
                    }}
                  />
                </InputGroup>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="batch"
                  label="Royal Batch"
                  type="select"
                  errorMessage="Royal batch is required"
                  validate={{
                    required: { value: true }
                  }}
                >
                  <option disabled value="">
                    Select Royal Batch{" "}
                  </option>
                  {this.renderBatches()}
                </AvField>
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <AvField
                  name="membershipType"
                  label="Membership Type"
                  type="select"
                  errorMessage="Membership type is required"
                  validate={{
                    required: { value: true }
                  }}
                >
                  <option disabled value="">
                    Select Membership Type{" "}
                  </option>
                  {this.renderMembershipTypes()}
                </AvField>
                <div className="display-err text-danger form-group">
                  {errorRetired ? errorRetired : null}
                </div>
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row className="btn-row">
              <div>
                <Button
                  loading={
                    type === "edit" ? putMembersLoading : authRegisterLoading
                  }
                >
                  {type === "edit"
                    ? "UPDATE MEMBER"
                    : admin
                    ? "ADD MEMBER"
                    : "JOIN RCOBAWA"}
                </Button>
              </div>
              <br />
              {admin ? null : (
                <p>
                  By joining RCOBAWA you agree to the
                  <a href="/termsAndConditions"> Terms & Conditions</a> that
                  apply
                </p>
              )}
            </Grid.Row>
          </Grid>
        </AvForm>
      </>
    );
  }
}

class MemberEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    const { modal } = this.state;
    const { item } = this.props;
    return (
      <Modal
        centered
        trigger={<Dropdown.Item text="View/Edit Member" />}
        open={modal}
        onOpen={() => this.toggle()}
      >
        <Modal.Content>
          <div className="modal-header-div">
            Edit Member
            <Icon
              className="close-icon"
              name="close"
              onClick={() => this.toggle()}
            />
          </div>
          <MemberForm
            toggle={() => this.toggle()}
            item={item}
            isNew={false}
            {...this.props}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { members } = state;
  return {
    ...members
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMembershipTypes,
      getAllMembers
    },
    dispatch
  );
};

MemberForm = connect(mapStateToProps, mapDispatchToProps)(MemberForm);

export { MemberForm, MemberEdit };
