import React from 'react';
import { Button, Modal, Icon } from 'semantic-ui-react';
import './deleteModal.scss';

export class DeleteModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false
    };
  }

  async handleDelete() {
    try {
      const {
        item,
        deleteGroup,
        type,
        deleteMemberFromGroup,
        user,
        deleteMagazineItem,
        deletePostItem,
        archiveAlbum,
        deleteMember,
        deleteAlbumImageById,
        albumId,
        deleteItem,
        getAll,
        deleteAttendee,
        match,
        params
      } = this.props;
      this.setState({
        loading: true
      });
      switch (type) {
        case 'deleteGroup':
          await deleteGroup(item.id);
          break;
        case 'deleteMemberFromGroup':
          await deleteMemberFromGroup({ groupId: item.id, userId: user.id });
          break;
        case 'magazine':
          await deleteMagazineItem(item.id, params);
          break;
        case 'gallery':
          await archiveAlbum({ albumId: item.id, active: params });
          break;
        case 'removeMember':
          await deleteMember(item.id);
          break;
        case 'image':
          await deleteAlbumImageById(albumId, item.id);
          break;
        case 'shopEvent':
          await deleteItem(item.id, getAll);
          break;
        case 'deleteAttendee':
          await deleteAttendee({
            ticketNo: item.ticketNo,
            eventId: match.params.eventId
          });
          break;
        default:
          // TODO: add `deletePostItem` a type and clear default. Do not put any thing on default.
          await deletePostItem(item.id, params);
          break;
      }
      this.setState({
        loading: false
      });
      this.modal();
    } catch (error) {
      console.log('TCL: DeleteModal -> handleDelete -> error', error);
      this.setState({
        loading: false
      });
      this.modal();
    }
  }

  modal() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  renderContent() {
    const { item, type, user, params } = this.props;
    switch (type) {
      case 'removeMember':
        return (
          <>
            <p className="delete-modal-title">
              <Icon size="large" name="warning circle" />
              Delete Member
            </p>
            <p className="call-to-action">
              Are you sure you want to permanently delete{' '}
              <b>{item.firstName + ' ' + item.lastName}</b> from RCOBAWA?
            </p>
            <p className="sub-text">
              ( Once you delete a member, all information related to the member
              will be permanently deleted from the system. )
            </p>
          </>
        );
      case 'deleteMemberFromGroup':
        return (
          <>
            <p className="delete-modal-title">
              <Icon size="large" name="warning circle" />
              Remove Member
            </p>
            <p className="call-to-action">
              Are you sure you want to remove{' '}
              <b>{user.firstName + ' ' + user.lastName}</b> from{' '}
              <b>{item.name}</b>?
            </p>
            <p className="sub-text">
              ( Once you remove a member, all privileges related to this group
              will no longer be valid. )
            </p>
          </>
        );
      case 'deleteGroup':
        return (
          <>
            <p className="delete-modal-title">
              <Icon size="large" name="warning circle" />
              Delete Group
            </p>
            <p className="call-to-action">
              Are you sure you want to permanently delete <b>{item.name}</b>{' '}
              from RCOBAWA?
            </p>
            <p className="sub-text">
              ( Once you delete a group, all information related to the group
              will be permanently deleted from the system. )
            </p>
          </>
        );
      case 'magazine':
        return (
          <>
            <p className="delete-modal-title">
              <Icon size="large" name="warning circle" />
              {!params ? 'Archive Magazine' : 'Publish Magazine'}
            </p>
            <p className="call-to-action">
              Are you sure you want to {!params ? 'archive' : 'publish'}{' '}
              <b>{item.title}</b> ?
            </p>
            <p className="sub-text">
              {!params
                ? '( Once you archive this magazine it will not be displayed in the media section of the landing page. )'
                : '( Once you publish this magazine it will be displayed in the media section of the landing page. )'}
            </p>
          </>
        );
      case 'gallery':
        return (
          <>
            <p className="delete-modal-title">
              <Icon size="large" name="warning circle" />
              {!params ? 'Archive Album' : 'Publish Album'}
            </p>
            <p className="call-to-action">
              Are you sure you want to {!params ? 'archive' : 'publish'}{' '}
              <b>{item.title}</b> ?
            </p>
            <p className="sub-text">
              {!params
                ? '( Once you archive this album it will not be displayed in the media section of the landing page. )'
                : '( Once you publish this album it will be displayed in the media section of the landing page. )'}
            </p>
          </>
        );
      case 'post':
        return (
          <>
            <p className="delete-modal-title">
              <Icon size="large" name="warning circle" />
              {!params ? 'Archive Post' : 'Publish Post'}
            </p>
            <p className="call-to-action">
              Are you sure you want to {!params ? 'archive' : 'publish'}{' '}
              <b>{item.title}</b> ?
            </p>
            <p className="sub-text">
              {!params
                ? '( Once you archive this post it will not be displayed in the media section of the landing page. )'
                : '( Once you publish this post it will be displayed in the media section of the landing page. )'}
            </p>
          </>
        );
      case 'shopEvent':
        return (
          <>
            <p className="delete-modal-title">
              <Icon size="large" name="warning circle" />
              Delete Event
            </p>
            <p className="call-to-action">
              Are you sure you want to delete <b>{item.title}</b> ?
            </p>
            <p className="sub-text">
              ( Once you delete this event it will not be displayed in the
              events section of the landing page. )
            </p>
          </>
        );
      case 'deleteAttendee':
        return (
          <>
            <p className="delete-modal-title">
              <Icon size="large" name="warning circle" />
              Delete Attendee
            </p>
            <p className="call-to-action">
              Are you sure you want to delete attendee{' '}
              <b>
                {item.User
                  ? (item.User.firstName ? item.User.firstName : '-') +
                    ' ' +
                    (item.User.lastName ? item.User.lastName : '-')
                  : (item.guestFirstName ? item.guestFirstName : '-') +
                    ' ' +
                    (item.guestLastName ? item.guestLastName : '-')}
              </b>{' '}
              for the ticket number <b>{item.ticketNo}</b>?
            </p>
            <p className="sub-text">
              ( Once you delete this attendee, all associated data to this
              attendee will be deleted. )
            </p>
          </>
        );
      default:
        return <p className="delete-modal-title"></p>;
    }
  }

  renderDeleteButton() {
    const { type, params } = this.props;
    switch (type) {
      case 'deleteMemberFromGroup':
        return 'Remove';
      case 'magazine':
      case 'gallery':
      case 'post':
        return !params ? 'Archive' : 'Publish';
      default:
        return 'Delete';
    }
  }

  render() {
    const { trigger } = this.props;
    const { modal, loading } = this.state;
    return (
      <Modal
        open={modal}
        onOpen={() => this.modal()}
        onClose={() => this.modal()}
        size="small"
        centered
        trigger={trigger}
      >
        <Modal.Content>
          <Icon
            className="close-icon"
            name="close"
            onClick={() => this.modal()}
          />
          <div className="delete-modal">
            {this.renderContent()}
            <hr />
            <div className="button-container">
              <Button className="first-button" onClick={() => this.modal()}>
                No
              </Button>
              <Button
                loading={loading}
                className="second-button"
                onClick={() => {
                  this.handleDelete();
                }}
              >
                {this.renderDeleteButton()}
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
