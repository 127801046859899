import axios from 'axios';
import { toast } from 'react-toastify';
import { showError } from '../../utils';

const INQUIRY_LOADING = 'SEND_INQUIRY_LOADING';

const initialState = {
  inquiryLoading: false,  
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INQUIRY_LOADING:
      return {
        ...state,
        inquiryLoading: action.payload
      };
    default:
      return state;
  }
}



export function addInquiry(data) {
  return async dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: INQUIRY_LOADING, payload: true });
        await axios.post('/inquiry', data);
       
        dispatch({ type: INQUIRY_LOADING, payload: false });
        toast.success('Inquiry submitted successfully');
        resolve();
      } catch (error) {
        dispatch({ type: INQUIRY_LOADING, payload: false });
        showError(error);
        reject();
      }
    });
  };
}
