import React from "react";
import { Grid, Icon, Image, Card } from "semantic-ui-react";
import "./infoCards.scss";
import AliceCarousel from "react-alice-carousel";
import moment from "moment";
import "react-alice-carousel/lib/alice-carousel.css";
import history from "../../history";
import $ from "jquery";

export class InfoCards extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      responsive: {
        0: {
          items: 1
        },
        1024: {
          items: 3
        },
        750: {
          items: 2
        }
      },
      galleryItems: this.galleryItems(props.data),
    };
  }

   componentDidUpdate(prevProps) {
      if (prevProps.data !== this.props.data) {
        this.setState({
          galleryItems: this.galleryItems(this.props.data)
        });
      }
    
  }

  slideTo = i => this.setState({ currentIndex: i });

  onSlideChanged = e => this.setState({ currentIndex: e.item });

  slideNext = () =>
    this.setState({ currentIndex: this.state.currentIndex + 1 });

  slidePrev = () =>
    this.setState({ currentIndex: this.state.currentIndex - 1 });

  thumbItem = (item, i) => <span onClick={() => this.slideTo(i)}>* </span>;

  galleryItems(data) {
    const { type } = this.props;
    return data.map((item, i) => {
      const imageURL = item.image ? item.image || "" : item.thumbnail || "";
      return (
        <Card
          key={i}
          onClick={() => {
            type === "gallery"
              ? history.push(`/gallery/${item.id}`)
              : history.push(`/news/newsArticle/${item.id}`);
          }}
        >
          <div className="image-container">
            <Image
              className={type === "gallery" ? "cover-img" : null}
              src={imageURL}
              alt="carousel"
            />
            {type === "gallery" ? (
              <div className="image-title">{item.title}</div>
            ) : null}
          </div>
          {type === "news" ? (
            <span className="content-span">
              <Card.Header>{item.title}</Card.Header>
              <Card.Meta>
                <span className="date">
                  {moment(item.createdAt).format("DD MMMM YYYY")}
                </span>
              </Card.Meta>
              <Card.Description>
                {this.renderNewsContent(item)}
              </Card.Description>
            </span>
          ) : null}
        </Card>
      );
    });
  }

  renderNewsContent(news) {
    if (news.content) {
      if ($(news.content).text().length > 200) {
        return $(news.content)
          .text()
          .slice(0, 200);
      } else {
        return $(news.content).text();
      }
    } else {
      return <i>No content</i>;
    }
  }

  render() {
    const { galleryItems, responsive, currentIndex } = this.state;
    const { heading } = this.props;
    return (
      <Grid className="info-card-grid" columns={2}>
        <Grid.Row>
          <Grid.Column className="heading-col">
            <p>{heading}</p>
          </Grid.Column>
          <Grid.Column className="read-more-col">
            {/* <p>
            Read More <Icon name="arrow right" />
          </p> */}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="content-row">
          <AliceCarousel
            dotsDisabled={true}
            buttonsDisabled={true}
            items={galleryItems}
            responsive={responsive}
            slideToIndex={currentIndex}
            onSlideChanged={this.onSlideChanged}
          />

          <button className="prev-btn" onClick={() => this.slidePrev()}>
            <div>
              <Icon size="huge" name="chevron left" />
            </div>
          </button>
          <button className="next-btn" onClick={() => this.slideNext()}>
            <div>
              <Icon size="huge" name="chevron right" />
            </div>
          </button>
        </Grid.Row>
      </Grid>
    );
  }
}
