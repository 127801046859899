import axios from "axios";
import { showError } from "../../utils";
import { toast } from "react-toastify";
import { LIMIT } from "../../constant";

const REPORT = "REPORT";
const REPORT_PAYMENTS = "REPORT_PAYMENTS";
const REPORT_PAYMENTS_LOADING = "REPORT_PAYMENTS_LOADING";
const TRANSACTIONS_RECONCILE_LOADING = "TRANSACTIONS_RECONCILE_LOADING";
const REPORT_LOADING = "REPORT_LOADING";
const REPORT_CSV = "REPORT_CSV";

const initialState = {
  report: {},
  reportLoading: false,
  reportPayments: {},
  reportPaymentsLoading: false,
  reconcileLoading: false,
  reportCsv: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REPORT:
      return {
        ...state,
        report: action.payload
      };
    case REPORT_PAYMENTS:
      return {
        ...state,
        reportPayments: action.payload
      };
    case REPORT_PAYMENTS_LOADING:
      return {
        ...state,
        reportPaymentsLoading: action.payload
      };
    case TRANSACTIONS_RECONCILE_LOADING:
      return {
        ...state,
        reconcileLoading: action.payload
      };
    case REPORT_LOADING:
      return {
        ...state,
        reportLoading: action.payload
      };
    case REPORT_CSV:
      return {
        ...state,
        reportCsv: action.payload
      };
    default:
      return state;
  }
}

export function getReport(data) {
  return async dispatch => {
    try {
      dispatch({ type: REPORT_LOADING, payload: true });

      const params = {
        offset: data.offset ? data.offset : 1,
        limit: data.limit ? data.limit : LIMIT,
        startDate: data.startDate,
        endDate: data.endDate,
        filter: data.filter,
        type: data.paymentType
      };
      const response = await axios.get(`/report`, { params });
      dispatch({ type: REPORT, payload: response.data });
      dispatch({ type: REPORT_LOADING, payload: false });
    } catch (error) {
      showError(error);
      dispatch({ type: REPORT_LOADING, payload: false });
    }
  };
}

export function uploadBankStatement(data) {
  return async dispatch => {
    return new Promise(async  (resolve, reject) => {
      try {
        dispatch({ type: REPORT_PAYMENTS_LOADING, payload: true });
        const response = await axios.post(`/report/import`, data);
        dispatch({ type: REPORT_PAYMENTS, payload: response.data });
        dispatch({ type: REPORT_PAYMENTS_LOADING, payload: false });
        resolve()
      } catch (error) {
        showError(error);
        dispatch({ type: REPORT_PAYMENTS_LOADING, payload: false });
        reject(error);
      }
    })
    
  };
}

export function reconcileTransactions(data) {
  return async dispatch => {
    try {
      const params = {
        body: {
          transactionRecords: data.transactionRecords,
          userId: data.userId,
          csvRecords: data.csvRecords
        }
        
      };
      
      dispatch({ type: TRANSACTIONS_RECONCILE_LOADING, payload: true });
      const response = await axios.put(`/report/reconcile`, params);
      dispatch(
        getReport({
          startDate: data.startDate,
          endDate: data.endDate,
          filter: data.filter
        })
      );
      dispatch({ type: TRANSACTIONS_RECONCILE_LOADING, payload: false });
      toast.success(response.data.message)
    } catch (error) {
      showError(error);
      dispatch({ type: TRANSACTIONS_RECONCILE_LOADING, payload: false });
    }
  };
}

export function exportReportCsv(data) {
  return async dispatch => {
    try {
      const params = {
        startDate: data.startDate,
        endDate: data.endDate
      };
      // dispatch({ type: TRANSACTIONS_RECONCILE_LOADING, payload: true });
      const response = await axios.get(`/report/csv/export`, {params});
      console.log("TCL: exportReportCsv -> response", response)
      dispatch({ type: REPORT_CSV, payload: response.data });
      // toast.success(response.data.message)
    } catch (error) {
      showError(error);
      // dispatch({ type: TRANSACTIONS_RECONCILE_LOADING, payload: false });
    }
  };
}
