import React from 'react';
import { Grid, Image, Button } from 'semantic-ui-react';
import history from '../../history';
import './footer.scss';

export class Footer extends React.PureComponent {
  render() {
    return (
      <Grid padded={true} className="footer-grid">
        <Grid.Row>
          <Grid.Column className="details-col" mobile={'16'} computer={'10'}>
            <Grid padded={true}>
              <Grid.Row>
                <Grid.Column computer={'6'}>
                  <Image
                    src={require('../../assets/logo.png')}
                    size={'tiny'}
                    centered
                  />
                </Grid.Column>
                <Grid.Column computer={'5'}>
                  <a href="/about#about">Committee</a>
                  <a href="/events#events">Events</a>
                  <a href="/membership#membership">Membership</a>
                  <a href="/contact#contact">Contact Us</a>
                  <a href="/termsAndConditions">Terms and Conditions</a>
                </Grid.Column>
                <Grid.Column computer={'5'}>
                  <p>
                    P. O. Box 796
                    <br />
                    Mt Waverley <br />
                    Victoria - 3149
                    <br />
                    Australia
                    <br />
                  </p>
                </Grid.Column>
                <div className="copyright-div">
                  <p className="copyright-statement">
                    Copyright © 2008-2013 Royal College Old Boys in Australia
                    Association (RCOBAWA). All Rights Reserved.
                  </p>
                </div>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column className="memeber-div" mobile={'16'} computer={'6'}>
            <Grid padded={true}>
              <Grid.Row>
                <Grid.Column>
                  <p>Join RCOBAWA</p>
                  <Button
                    onClick={() => {
                      history.push('/register');
                    }}
                    content="BECOME A MEMBER"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
