import React from "react";
import DropIn from "braintree-web-drop-in-react";
import { membershipPayment, getMembershipTypes } from "../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Button } from "semantic-ui-react";
import history from "../../history";
import { DROP_IN_AUTH_KEY } from '../../constant';

class Store extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      membership: {},
      open: false
    };
  }

  async componentWillMount() {
    try {
      const { getMembershipTypes, match } = this.props;
      await getMembershipTypes();
      const membershipType = await _.find(this.props.membership, o => {
        return o.id === parseInt(match.params.id, 10); //get value fron props.match
      });
      this.setState({
        membership: membershipType
      });
      setTimeout(
        () =>
          this.setState({
            open: true
          }),
        1000
      );
    } catch (error) {
      console.log(">: Payment -> componentDidMount -> error", error);
    }
  }

  async buy() {
    try {
      // Send the nonce to your server
      const { nonce } = await this.instance.requestPaymentMethod();
      if(nonce){
        this.setState({
          open: false
        });
      }
      const { membershipPayment, userRegister, modal, errorResponse,match } = this.props;
      const membershipType = this.state.membership;
      await membershipPayment({
        paymentType: "MEMBERSHIP",
        amount: membershipType.amount,
        userId: match.params.renew?match.params.memberId: userRegister ? userRegister.id  : errorResponse.user.id,match,
        paymentMethodNonce: nonce,
        membershipTypeId: membershipType.id,
        paymentMode: "CARD"
      });
      modal(false);
      history.push(`/membership/payment/success/${membershipType.id}`);
    } catch (error) {
      this.props.modal(false);
      console.log(">: Store -> buy -> error", error);
    }
  }

  render() {
    const { open } = this.state;
    return (
      <div className="dropin-ui" style={{ textAlign: "center" }}>
        <DropIn
          options={{
            authorization: DROP_IN_AUTH_KEY,
            card: {
              overrides: {
                fields: {
                  number: {
                    placeholder: "1111 1111 1111 1111" // Update the number field placeholder
                  }
                },
                styles: {
                  input: {
                    "font-size": "18px" // Change the font size for all inputs
                  },
                  ":focus": {
                    color: "black" // Change the focus color to red for all inputs
                  }
                }
              }
            }
          }}
          onInstance={instance => (this.instance = instance)}
        />
        {open ? <Button onClick={this.buy.bind(this)}>Pay</Button> : null}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { members, auth } = state;
  return {
    membership: members.membership,
    userRegister: auth.userRegister,
    errorResponse: auth.errorResponse
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      membershipPayment,
      getMembershipTypes
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Store);
