import React from "react";
import "./shop.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getTicketsGenerated } from "../../store/actions";
import ButtonControl from "../layout/buttonControls";
import history from "../../history";
import { Segment } from "semantic-ui-react";
import { CustomTable } from "../../components";
import _ from 'lodash';
import moment from 'moment';

class GeneratedTickets extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: "PAYEE NAME",
                    render: data => {
                        const user = _.get(data, 'Payment.User', null);
                        const firstName = _.get(data, 'Payment.User.firstName', '-');
                        const lastName = _.get(data, 'Payment.User.lastName', null);
                        return (
                            <div>
                                {user
                                    ? `${firstName} ${lastName ? lastName : ''}`
                                    : _.get(data, 'Payment.guestName', '-')}
                            </div>
                        );
                    }
                },
                {
                    title: "AMOUNT",
                    render: data => {
                        const amount = _.get(data, 'Payment.amount', 0);
                        return <div>{amount ? (amount > 0) ? amount : 'Free' : "-"}</div>;
                    }
                },
                {
                    title: 'PAYMENT MODE',
                    render: data => {
                        console.log("🚀 ~ file: shopItem.js ~ line 21 ~ GeneratedTickets ~ constructor ~ data", data)
                        return <div>{_.get(data, 'Payment.paymentMode', '-')}</div>;
                    }
                },
                {
                    title: 'PAYMENT DATE',
                    render: data => {
                        return (
                            <div>
                                {moment(data.Payment.receivedDate).format('DD MMMM YYYY')}
                            </div>
                        );
                    }
                },
                {
                    title: 'REFERENCE # ',
                    render: data => {
                        const reference = _.get(data, 'Payment.reference', null);
                        return reference? reference : '-';
                    }
                },
                {
                    title: '',
                    render: data => {
                        return <div className="options-row">
                            <div
                                className="share"
                                onClick={() =>
                                    window.open(data.ticketPdf)
                                }
                            >
                                Download PDF
                            </div>
                        </div>;
                    }
                }
            ],
            page: 1
        };
    }

    async handlePaginationChange(activePage) {
        try {
            const { getTicketsGenerated, shopId } = this.props;
            let reqObj = { page: activePage };
            await getTicketsGenerated(shopId, reqObj);
            this.setState({
                page: activePage
            });
            window.scrollTo(0, 0);
        } catch (error) {
            console.log('>:Members-> handlePaginationChange -> error', error);
        }
    }

    render() {
        const { ticketsGeneratedLoading, ticketsGenerated } = this.props;
        return (
            <Segment loading={ticketsGeneratedLoading}>
                <CustomTable
                    header
                    columns={this.state.columns}
                    data={ticketsGenerated.docs || []}
                    pagination
                    noOfPages={ticketsGenerated.pages || 0}
                    page={this.state.page}
                    handlePaginationChange={activePage =>
                        this.handlePaginationChange(activePage)
                    }
                />
            </Segment>
        );
    }
}

class ShopItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            shopId: this.props.match.params.shopId,
        };
    }

    async componentDidMount() {
        try {
            window.scrollTo(0, 0);
            const { getTicketsGenerated } = this.props;
            const { shopId } = this.state;
            await getTicketsGenerated(shopId);
        } catch (error) {
            console.log("TCL: MemberProfile -> componentDidMount -> error", error);
        }
    }

    goBack() {
        history.push("/admin/shop");
    }

    render() {
        const { ticketsGeneratedLoading } = this.props;
        const { shopId } = this.state;
        return (
            <ButtonControl
                backArrow={"Back to Shop"}
                goBack={() => this.goBack()}
                loading={ticketsGeneratedLoading}
            >
                <GeneratedTickets {...this.props} shopId={shopId} />
            </ButtonControl>
        );
    }
}

const mapStateToProps = state => {
    const { items } = state;
    return {
        ticketsGenerated: items.ticketsGenerated,
        ticketsGeneratedLoading: items.ticketsGeneratedLoading
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getTicketsGenerated
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopItem);
