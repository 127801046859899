import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Image } from "semantic-ui-react";
import { CustomTable, DeleteModal } from "../../components";
import {
  getAllContent,
  getAllPosts,
  getAllAlbums,
  deleteMagazineItem,
  deletePostItem,
  archiveAlbum
} from "../../store/actions";
import ButtonControls from "../layout/buttonControls";
import { ContentForm, ContentEdit } from "./contentForm";
import "./content.scss";
import history from "../../history";
import { toast } from "react-toastify";
import _ from "lodash";
import $ from "jquery";

class Content extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          render: data => {
            return (
              <div>
                {
                  <Image
                    className="table-img  hoverable-div"
                    onClick={() => window.open(data.file)}
                    src={
                      data.thumbnail
                        ? data.thumbnail
                        : require("../../assets/image-placeholder.png")
                    }
                  />
                }
              </div>
            );
          }
        },
        {
          render: data => {
            return (
              <div>
                <div
                  onClick={() => window.open(data.file)}
                  className="cell-title  hoverable-div"
                >
                  {data.title ? data.title : "-"}
                </div>
                <div className="cell-desc">
                  {data.createdAt
                    ? moment(data.createdAt).format("DD MMMM YYYY")
                    : "-"}
                </div>
              </div>
            );
          }
        },
        {
          render: data => {
            return <div>{data.ISBN ? "ISBN: " + data.ISBN : "-"}</div>;
          }
        },
        {
          render: data => {
            const { toggle } = this.state;
            return (
              <div className="options-row">
                <ContentEdit item={data} itemType="magazine" {...this.props} />
                <DeleteModal
                  trigger={
                    <div className="unpublish">
                      {toggle ? "Publish" : "Archive"}
                    </div>
                  }
                  item={data}
                  {...this.props}
                  type="magazine"
                  params={toggle}
                />
                <div
                  className="share"
                  onClick={() => {
                    this.copyToClipBoard("magazine", data.file);
                  }}
                >
                  Copy Share Link
                </div>
              </div>
            );
          }
        }
      ],
      postColumns: [
        {
          title: " ",
          render: data => {
            return (
              <div>
                {
                  <Image
                    className="table-img"
                    src={
                      data.image
                        ? data.image
                        : require("../../assets/image-placeholder.png")
                    }
                  />
                }
              </div>
            );
          }
        },
        {
          title: "",
          render: data => {
            return (
              <div>
                <div className="cell-title">
                  {data.title ? data.title : "-"}
                </div>
                <div>{this.renderNewsContent(data)}</div>
              </div>
            );
          }
        },
        {
          title: "",
          width: 5,
          render: data => {
            const { toggle } = this.state;
            return (
              <div className="options-row">
                <ContentEdit item={data} itemType="post" {...this.props} />
                {data.title === "PAST PRESIDENTS" ? null : (
                  <DeleteModal
                    trigger={
                      <div className="unpublish">
                        {toggle ? "Publish" : "Archive"}
                      </div>
                    }
                    item={data}
                    {...this.props}
                    type="post"
                    params={toggle}
                  />
                )}
                <div
                  className="share"
                  onClick={() => {
                    this.copyToClipBoard("post", data.id);
                  }}
                >
                  Copy Share Link
                </div>
              </div>
            );
          }
        }
      ],
      albumColumns: [
        {
          title: " ",
          render: data => {
            return (
              <div
                className="album-img"
                onClick={() => history.push(`/admin/content/album/${data.id}`)}
              >
                {
                  <Image
                    src={
                      data.thumbnail
                        ? data.thumbnail
                        : require("../../assets/image-placeholder.png")
                    }
                  />
                }
              </div>
            );
          }
        },
        {
          title: "",
          render: data => {
            return (
              <div>
                <div
                  className="cell-title hoverable-div"
                  onClick={() =>
                    history.push(`/admin/content/album/${data.id}`)
                  }
                >
                  {data.title ? data.title : "-"}
                </div>
                <div className="cell-desc">
                  {data.createdAt
                    ? moment(data.createdAt).format("MMMM YYYY")
                    : "-"}
                </div>
              </div>
            );
          }
        },
        {
          title: "",
          render: data => {
            const { toggle } = this.state;
            return (
              <div className="options-row">
                <ContentEdit item={data} itemType="gallery" {...this.props} />
                <DeleteModal
                  trigger={
                    <div className="unpublish">
                      {toggle ? "Publish" : "Archive"}
                    </div>
                  }
                  item={data}
                  {...this.props}
                  type="gallery"
                  params={toggle}
                />
                <div
                  className="share"
                  onClick={() => {
                    this.copyToClipBoard("gallery", data.id);
                  }}
                >
                  Copy Share Link
                </div>
              </div>
            );
          }
        }
      ],
      contentData: [],
      imageLoading: false,
      page: 1,
      type: props.match.params.contentType,
      deleteGroup: false,
      addModal: false,
      toggle: false
    };
  }

  copyToClipBoard(media, link) {
    switch (media) {
      case "post":
        navigator.clipboard.writeText(
          `${window.location.origin}/news/newsArticle/${link}#newsArticle`
        );
        break;
      case "magazine":
        navigator.clipboard.writeText(link);
        break;
      case "gallery":
        navigator.clipboard.writeText(
          `${window.location.origin}/gallery/${link}#albumImages`
        );
        break;
      default:
        break;
    }
    toast.info("Link copied to clipboard");
  }

  deleteItem(id) {
    const { archiveAlbum } = this.props;
    archiveAlbum({ albumId: id });
    this.onOpen("deleteGroup", false);
  }

  async componentDidMount() {
    try {
      window.scrollTo(0, 0);
      const { getAllContent, getAllAlbums, getAllPosts } = this.props;
      await getAllContent();
      await getAllAlbums();
      await getAllPosts();
      this.setState({
        contentData: this.props.content,
        albums: this.props.albums,
        posts: this.props.posts
      });
    } catch (error) {
      console.log(">: Content -> componentDidMount -> error", error);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    try {
      if (!_.isEqual(this.props.content, prevProps.content)) {
        this.setState({
          contentData: this.props.content
        });
      }
      if (!_.isEqual(this.props.posts, prevProps.posts)) {
        this.setState({
          posts: this.props.posts
        });
      }
      if (!_.isEqual(this.props.albums, prevProps.albums)) {
        this.setState({
          albums: this.props.albums
        });
      }
    } catch (error) {
      console.log("TCL: Content -> componentDidUpdate -> error", error);
    }
  }

  renderNewsContent(news) {
    if (news.content) {
      if ($(news.content).text().length > 200) {
        return $(news.content)
          .text()
          .slice(0, 200);
      } else {
        return $(news.content).text();
      }
    } else {
      return <i>No content</i>;
    }
  }

  async handlePaginationChange(activePage) {
    try {
      const { getAllContent } = this.props;
      await getAllContent({ page: activePage });
      await this.setState({
        page: activePage,
        contentData: this.props.content
      });
    } catch (error) {
      console.log(">:content-> handlePaginationChange -> error", error);
    }
  }

  dropDownChange(event, data, type) {
    switch (type) {
      case "type":
        this.setState(
          {
            toggle: data.value
          },
          () => {
            const {
              getAllContent,
              getAllPosts,
              getAllAlbums,
              match
            } = this.props;
            const { toggle } = this.state;
            switch (match.params.contentType) {
              case "Gallery":
                getAllAlbums({ active: !toggle });
                this.setState({
                  albums: this.props.albums
                });
                break;
              case "Magazines":
                getAllContent({ active: !toggle });
                this.setState({
                  contentData: this.props.content
                });
                break;
              case "News":
                getAllPosts({ active: !toggle });
                this.setState({
                  posts: this.props.posts
                });
                break;
              default:
                break;
            }
          }
        );
        break;

      default:
        const { getAllContent, getAllPosts, getAllAlbums } = this.props;
        const { toggle } = this.state;
        this.setState({
          type: data.value
        });
        history.push(`/admin/content/${data.value}`);
        switch (data.value) {
          case "Magazines":
            getAllContent({ active: !toggle });
            break;
          case "News":
            getAllPosts({ active: !toggle });
            this.setState({
              posts: this.props.posts
            });
            break;
          case "Gallery":
            getAllAlbums({ active: !toggle });
            this.setState({
              albums: this.props.albums
            });
            break;
          default:
            getAllContent({ active: !toggle });
            break;
        }
        break;
    }
  }

  renderTable() {
    const { type } = this.state;
    const { content } = this.props;
    switch (type) {
      case "Magazines":
        return (
          <CustomTable
            columns={this.state.columns}
            data={content}
            pagination
            noOfPages={this.props.pages}
            page={this.state.page}
            handlePaginationChange={activePage =>
              this.handlePaginationChange(activePage)
            }
          />
        );
      case "News":
        return (
          <CustomTable
            columns={this.state.postColumns}
            data={this.state.posts}
            pagination
            noOfPages={this.props.postPages}
            page={this.state.page}
            handlePaginationChange={activePage =>
              this.handlePaginationChange(activePage)
            }
          />
        );
      case "Gallery":
        return (
          <CustomTable
            columns={this.state.albumColumns}
            data={this.state.albums}
            pagination
            noOfPages={this.props.albumPages}
            page={this.state.albumPage}
            handlePaginationChange={activePage =>
              this.handlePaginationChange(activePage)
            }
          />
        );
      default:
        return (
          <CustomTable
            columns={this.state.columns}
            data={this.state.contentData}
            pagination
            noOfPages={this.props.pages}
            page={this.state.page}
            handlePaginationChange={activePage =>
              this.handlePaginationChange(activePage)
            }
          />
        );
    }
  }

  onOpen(modal, open) {
    this.setState({ [modal]: open });
  }

  render() {
    const { getContentLoading, getAlbumsLoading, getPostsLoading } = this.props;
    const { type, toggle } = this.state;
    return (
      <ButtonControls
        loading={
          type === "Magazines"
            ? getContentLoading
            : type === "News"
            ? getPostsLoading
            : getAlbumsLoading
        }
        controlObj={{
          btnArr: [
            {
              disabled: toggle ? true : false,
              icon: "add circle",
              name: "ADD NEW ITEM",
              className: "second-button",
              open: this.state.addModal,
              onOpen: () => this.onOpen("addModal", true),
              // onClose: () => this.onOpen("addModal", false),
              size: "small",
              modalContent: (
                <ContentForm
                  trigger={open => this.onOpen("addModal", open)}
                  isNew={true}
                  itemType={
                    type === "Magazines"
                      ? "magazine"
                      : type === "Gallery"
                      ? "gallery"
                      : type === "News"
                      ? "post"
                      : null
                  }
                />
              )
            }
          ],
          filter: [
            {
              defaultValue: this.props.match.params.contentType,
              label: "View By: ",
              items: [
                { text: "Magazines", value: "Magazines" },
                { text: "Gallery", value: "Gallery" },
                { text: "Post", value: "News" }
              ],
              dropDownChange: (event, data) =>
                this.dropDownChange(event, data, "view")
            },
            {
              defaultValue: false,
              label: "Type: ",
              items: [
                { text: "Published", value: false },
                { text: "Archived", value: true }
              ],
              dropDownChange: (event, data) =>
                this.dropDownChange(event, data, "type")
            }
          ]
        }}
      >
        {this.renderTable()}
      </ButtonControls>
    );
  }
}

const mapStateToProps = state => {
  const { content } = state;
  return {
    getContentLoading: content.getContentLoading,
    content: content.content,
    pages: content.pages,
    count: content.count,
    posts: content.posts,
    postPages: content.postPages,
    postCount: content.postCount,
    albums: content.albums,
    albumPages: content.albumPages,
    albumCount: content.albumCount,
    getPostsLoading: content.getPostsLoading,
    getAlbumsLoading: content.getAlbumsLoading,
    contentLoading: content.contentLoading
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllContent,
      getAllPosts,
      getAllAlbums,
      deleteMagazineItem,
      deletePostItem,
      archiveAlbum
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
