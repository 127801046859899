import { toast } from 'react-toastify';

export function showError(error) {
  // console.log('>: ----------------------------');
  // console.log('>: showError -> error', error.response);
  // console.log('>: ----------------------------');
  if (error.response) {
    if (error.response.data.message) {
      toast.error(error.response.data.message);
    } else if (error.response.statusText || error.response.request.statusText) {
      toast.error(
        error.response.statusText || error.response.request.statusText
      );
    } else {
      toast.error('Something went wrong...');
    }
  } else {
    toast.error('Something went wrong...');
  }
}

export function limitString(content, length) {
  const contentString = content.replace(/(<([^>]+)>)/gi, '')
  if (contentString.length > length) {
    return `${contentString.slice(0, length)}...`
  }
  return contentString
}