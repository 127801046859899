import React from "react";
import { PaymentHolder } from "../../components/";
import { Header, Grid, Button } from "semantic-ui-react";
import history from "../../history";
import "./paymentSuccess.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";

class PaymentSuccess extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      membershipType: {}
    };
  }

  async componentDidMount() {
    try {
      const { membership, match } = this.props;
      const membershipType = await _.find(membership, o => {
        return o.id === parseInt(match.params.membershipTypeId, 10); //get value fron props.match
      });
      this.setState({
        membershipType: membershipType
      });
    } catch (error) {
      console.log(">: PaymentSuccess -> componentDidMount -> error", error);
    }
  }

  render() {
    const { membershipType } = this.state;
    const { payment } = this.props;

    return (
      <PaymentHolder>
        <Grid className="success-grid">
          <Header>YOU ARE A RCOBAWA MEMBER!</Header>
          <p className="para">
            It is a pleasure to have you onboard. Your membership has been
            successful and if any dispute occurs we will get in touch.
            <br />
            <br />
            Login using the password we have sent to your mail.
          </p>
          <div className="member-details">
            <p className="para">Membership Type</p>
            <h1>{membershipType.title}</h1>
            <p className="para">Expires on</p>
            <h1>{moment(payment.expireDate).format("dddd DD MMMM YYYY ")}</h1>
          </div>
          <Button
            onClick={() => {
              history.push("/login");
            }}
          >
            Login
          </Button>
        </Grid>
      </PaymentHolder>
    );
  }
}

const mapStateToProps = state => {
  const { members, auth } = state;
  return {
    membership: members.membership,
    userRegister: auth.userRegister,
    payment: members.payment
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSuccess);
